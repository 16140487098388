import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import DeviceCommandForm from '../../components/DeviceCommandForm';

const DeviceCommandCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const { t } = useTranslation();

  const saveDeviceCommand = (async (deviceCommand) => {
    onShowSpinner();
    const clone = {
      ...deviceCommand,
    };
    const body = JSON.stringify(clone);
    const path = '/api/device-commands';
    try {
      await axios.post(path, body);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/device-commands');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveDeviceCommand)}>
      <DeviceCommandForm
        form="create"
      />
    </form>
  );
};

DeviceCommandCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'device_command_create_form' })(DeviceCommandCreateForm);
