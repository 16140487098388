import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import { getRoleOptions } from '../../../shared/utility/RoleHelper';

const AccountForm = ({ form, path }) => {
  const isEdit = form === 'edit';
  const { t } = useTranslation();

  const disabledProps = {};
  if (isEdit) {
    disabledProps.disabled = true;
  }

  const enabledPart = () => {
    if (isEdit) {
      return (
        <Col md={3}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('account:enabled')}</span>
            <div className="form__form-group-field">
              <Field
                name="enabled"
                component={renderToggleButtonField}
              />
            </div>
          </div>
        </Col>
      );
    }
    return '';
  };

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={3}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:email')}</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:email')}
                  {...disabledProps}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('account:role')}</span>
              <div className="form__form-group-field">
                <Field
                  name="roles"
                  component={RenderSelectField}
                  options={getRoleOptions()}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:firstName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="first_name"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:firstName')}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:lastName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="last_name"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:lastName')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={3}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:phoneNumber')}</span>
              <div className="form__form-group-field">
                <Field
                  name="phone_number"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:phoneNumber')}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('account:birthDate')}</span>
              <div className="form__form-group-field">
                <Field
                  name="birth_date"
                  component={renderDatePickerField}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
          {enabledPart()}
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

AccountForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

AccountForm.defaultProps = {
  form: '',
  path: '/accounts',
};

export default AccountForm;
