import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import MenuItemMain from './components/MenuItemMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';
import { fetchMenuEntry } from '../../../redux/actions/cmsMenuActions';
import ContentManager from '../MenuItemEdit/components/ContentManager';
import setUrlQuery from '../../../shared/utility/urlModify';
import { fetchContents } from '../../../redux/actions/cmsContentActions';

const MenuItemPage = () => {
  const { t } = useTranslation();
  const { menuId, id } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useAuthCheck();

  useEffect(() => {
    if (isAuthenticated === true) {
      const path = `/api/menus/${menuId}`;
      dispatch(fetchMenuEntry(path));
      setUrlQuery('menuItemId', id);
      dispatch(fetchContents(`/api/contents${window.location.search}`));
    }
  }, [dispatch, menuId, isAuthenticated, id]);

  const menuItem = useSelector((state) => {
    const menu = state.cms.menu.menupoints.find((element) => element.id === +menuId);
    const menuItemObject = menu && menu.menuItems?.find((element) => element.id === +id);
    return menuItemObject || {};
  });

  const contents = useSelector((state) => state.cms.content.contents || []);

  const statusCode = useSelector((state) => (
    state.status.statusCode || null
  ));

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div>
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('cmsMenuItem:view')}</h3>
            </Col>
          </Row>
          <Row>
            <MenuItemMain data={menuItem} />
          </Row>
          <Row>
            <Col>
              <ContentManager readOnly contents={contents} />
            </Col>
          </Row>
        </div>
        <BackButton path="/menus" />
      </Container>
    </PageHandler>
  );
};

export default MenuItemPage;
