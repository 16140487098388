import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ContentEditCard from './components/ContentEditCard';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { fetchLanguageList, fetchSingleContent } from '../../../redux/actions/cmsContentActions';
import { fetchMenuItemList } from '../../../redux/actions/cmsMenuActions';

const ContentEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated === true) {
      const path = `/api/contents/${id}`;
      dispatch(fetchSingleContent(path));
      dispatch(fetchLanguageList('/api/languages'));
      dispatch(fetchMenuItemList('/api/menu-items'));
    }
  }, [dispatch, id, isAuthenticated]);

  const content = useSelector((state) => {
    const contentObject = state.cms.content.contents.find((element) => element.id === +id);
    return contentObject || {};
  });

  return (
    <Container>
      <div className="content-edit">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('content:edit')}</h3>
          </Col>
        </Row>
        <Row>
          <ContentEditCard data={content} />
        </Row>
      </div>
    </Container>
  );
};

export default ContentEdit;
