import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AtIcon from 'mdi-react/AtIcon';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { forgotPassword } from '../../../../redux/actions/authActions';
import RenderField from '../../../../shared/components/form/RenderField';

class ForgotForm extends PureComponent {
  render() {
    const {
      handleSubmit, isAuthenticated, forgotten, t,
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit(forgotten)}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('email')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <Field
              name="email"
              component={RenderField}
              type="text"
              placeholder={t('email')}
            />
          </div>
        </div>
        <div className="account__btns">
          <button className="btn btn-success account__btn" type="submit">{t('submit')}</button>
          <Link className="btn btn-outline-success account__btn" to="/login">{t('back')}</Link>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ForgotForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  forgotten: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { forgotten: forgotPassword })(reduxForm({
  form: 'forgot_form',
})(withTranslation('forgot')(ForgotForm)));
