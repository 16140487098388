import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import { arrangeMenuItemCards } from '../../../redux/actions/cmsMenuActions';
import itemType from '../../../shared/utility/dndItemTypeEnum';

const MenuItem = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef();

  const { menuId, deleteItem, menuItem: { name, id } } = props;

  const { MENU_ITEM } = itemType;

  const handleDeleteClick = () => {
    deleteItem(`api/menu-items/${id}`, name);
  };

  const onDrop = (item) => {
    const ownId = id;
    const hoveringId = item.id;
    dispatch(arrangeMenuItemCards(hoveringId, ownId, menuId));
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: MENU_ITEM,
    item: {
      id,
      menuId,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ dropHover }, drop] = useDrop(() => ({
    accept: MENU_ITEM,
    drop: onDrop,
    canDrop: (item) => item.menuId === menuId && item.id !== id,
    collect: (monitor) => ({ dropHover: monitor.isOver() && monitor.canDrop() }),
  }));

  drag(drop(ref));

  return (
    <div className="menu-item" id={dropHover ? 'drop-hover' : undefined} ref={ref} style={{ opacity: isDragging && 0.2 }}>
      <div className="menu-item__title">{name}</div>
      <div className="menu-item__icon-bar">
        <Tooltip className="menu-card__button" title={t('common:actions:view')} placement="bottom" arrow>
          <Link to={`${window.location.pathname}/view-menu-item/${menuId}/${id}`}><EyeIcon size={20} /></Link>
        </Tooltip>
        <Tooltip className="menu-card__button" title={t('common:actions:edit')} placement="bottom" arrow>
          <Link to={`${window.location.pathname}/edit-menu-item/${menuId}/${id}`}><EditIcon size={20} /></Link>
        </Tooltip>
        <Tooltip className="menu-card__button" title={t('common:actions:delete')} placement="bottom" arrow>
          <Link to={`${window.location.pathname}`} onClick={handleDeleteClick}><DeleteIcon size={20} /></Link>
        </Tooltip>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  menuId: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  menuItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default MenuItem;
