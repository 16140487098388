import { useSelector } from 'react-redux';

const useAvailableMenuLangs = (menuId) => {
  const result = useSelector((state) => {
    const { languages = [] } = state.cms.content;
    const parentMenu = state.cms.menu.menupoints.find(
      (menu) => menu.id === +menuId,
    );
    const siblingMenuLangs = parentMenu?.menu_langs;
    const usedLangs = siblingMenuLangs?.map((menuLang) => menuLang.lang);
    const availableLangs = languages.filter((lang) => {
      const hasMatch = usedLangs?.find((usedLang) => usedLang.id === lang.id);
      return !hasMatch;
    });
    return availableLangs;
  });
  return result;
};

export default useAvailableMenuLangs;
