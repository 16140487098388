import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import throwError from '../../../shared/utility/errorHandler';
import { setAlert } from '../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import AppSettingsForm from '../components/AppSettingsForm';

const AppSettingsCreateForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const saveSetting = (async (setting) => {
    onShowSpinner();
    const clone = { ...setting };
    clone.margin = clone.margin === '' ? null : parseInt(clone.margin, 10);

    const body = JSON.stringify(clone);
    const path = '/api/app-settings';
    try {
      await axios.post(path, body);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/app-settings');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveSetting)}>
      <AppSettingsForm form="create" />
    </form>
  );
};

AppSettingsCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'settings_create_form',
  enableReinitialize: true,
})(AppSettingsCreateForm);

export default FormDecoratedComponent;
