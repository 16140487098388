import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Nav, NavItem, NavLink,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import MenuLangEditForm from './MenuLangEditForm';
import useAvailableMenuLangs from '../../../../shared/utility/useAvailableMenuLangs';
import ConfirmPopup from '../../../../shared/components/ConfirmPopup';
import { deleteMenuEntry, fetchMenuEntry } from '../../../../redux/actions/cmsMenuActions';

const MenuLangEditContainer = ({ data, id }) => {
  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  const [deletePath, setDeletePath] = useState('');
  const availableLangs = useAvailableMenuLangs(id);
  const hasAvailableLangs = availableLangs?.length > 0;

  const getActiveMenuLang = () => {
    const activeMenuLang = data?.length && data.find((menuLang) => menuLang.id === activeTab);
    return activeMenuLang;
  };

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    setItemToDelete(item.name);
    setDeletePath(`/api/menu-langs/${item.id}`);
    setIsModalOpen(true);
  };

  const onDeleteCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteConfirm = () => {
    dispatch(deleteMenuEntry(deletePath));
    dispatch(fetchMenuEntry(`/api/menus/${id}`));
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!activeTab && data?.length) setActiveTab(data[0].id);
  }, [activeTab, data]);

  const menuLangLinks = data?.length > 0 && data.map((menuLang) => (
    <NavItem key={menuLang.id}>
      <NavLink
        onClick={() => { setActiveTab(menuLang.id); }}
        className={`menu-edit-page__menu-link ${activeTab === menuLang.id ? 'active' : null}`}
      >
        {menuLang.lang.name}
        <Tooltip title={t('common:actions:delete')} placement="bottom" arrow>
          <IconButton onClick={(e) => { handleDeleteClick(e, menuLang); }}>
            <DeleteIcon size={20} />
          </IconButton>
        </Tooltip>
      </NavLink>
    </NavItem>
  ));

  const createNewMenuLang = (
    <NavItem>
      <Tooltip title={t('common:actions:addNew')} placement="bottom" arrow>
        <NavLink
          tag={Link}
          to={`/menus/create-menu-lang/${id}`}
          className="menu-edit-page__menu-link menu-edit-page__menu-link--create"
        >
          <PlusCircleOutlineIcon size={20} />
        </NavLink>
      </Tooltip>
    </NavItem>
  );

  return (
    <div className="menu-edit-page__edit-container">
      <ConfirmPopup
        isOpen={isModalOpen}
        item={itemToDelete}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
      <Nav tabs>
        {menuLangLinks}
        {hasAvailableLangs && createNewMenuLang}
      </Nav>
      {data?.length > 0 && (
      <MenuLangEditForm
        enableReinitialize
        initialValues={getActiveMenuLang()}
        onSubmit
      />
      )}
    </div>
  );
};

MenuLangEditContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  id: PropTypes.number,
};

MenuLangEditContainer.defaultProps = {
  id: null,
};

export default MenuLangEditContainer;
