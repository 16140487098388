import React, { useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form';
import { Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import RenderCleaveField from '../../../shared/components/form/RenderCleaveField';
import RenderField from '../../../shared/components/form/RenderField';
import throwError from '../../../shared/utility/errorHandler';
import FieldArrayIndexHelper from '../../../shared/utility/FieldArrayIndexHelper';

const ProfileForm = (props) => {
  const {
    handleSubmit,
    formType,
    listPath,
    isLeader,
  } = props;
  const haveToSend = (images) => images && images.some((image) => Object.prototype.hasOwnProperty.call(image, 'path'));
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const editProfile = (async (profile) => {
    onShowSpinner();

    const { id } = profile;
    const companyId = profile.company.id;

    const clone = {
      ...profile,
    };

    delete clone.id;
    delete clone.roles;
    delete clone.company.id;
    delete clone.leader_of;

    if (profile.images.length === 0) {
      delete clone.images;
    }

    const path = `/api/users/${id}`;
    const body = JSON.stringify(clone);

    try {
      await axios.put(path, body);

      if (haveToSend(clone.images)) {
        const formData = new FormData();
        const { images } = clone;
        images.map((image) => formData.append('images[]', image));

        const referencePath = `/api/companies/${companyId}/references`;
        await axios.post(referencePath, formData, { headers: { 'Content-type': 'multipart/form-data' } });
      }

      dispatch(setAlert('Sikeres módosítás!', 'success'));
      window.location.replace('/');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert('Sikertelen módosítás!', 'danger'));
          break;
      }

      err.response.data = FieldArrayIndexHelper(err.response.data, clone);

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  const removeReference = (async (referenceId) => {
    const path = `/api/companies/references/${referenceId}`;
    await axios.delete(path);
  });

  const actionCallbacks = {
    remove: removeReference,
  };

  return (
    <form className="form" onSubmit={handleSubmit(editProfile)}>
      <div className="form__full">
        <div className="card__title">
          <h4 className="bold-text">Profil adatok</h4>
        </div>
        <div className="form-row">
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Teljes név</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Telefonszám</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  +36
                </div>
                <Field
                  name="phone_number"
                  component={RenderCleaveField}
                  type="text"
                  placeholder="Telefonszám"
                  options={{
                    blocks: [9],
                    numericOnly: true,
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">Kapcsolattartó email címe</span>
              <div className="form__form-group-field">
                <Field
                  name="contact_email"
                  component={RenderField}
                  type="text"
                  placeholder="Kapcsolattartó email címe"
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      {
        isLeader && (
          <div className="form__full">
            <div className="form__half">
              <div className="card__title">
                <h4 className="bold-text">Céges adatok</h4>
              </div>
              <FormSection name="company" className="form__form-group">
                <div className="form-row">
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Cégnév</span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderField}
                          type="text"
                          placeholder="Cégnév"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Adószám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="tax_number"
                          component={RenderCleaveField}
                          type="text"
                          placeholder="xxxxxxxx-x-xx"
                          options={{
                            blocks: [8, 1, 2],
                            delimiter: '-',
                            numericOnly: true,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Irányítószám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="zip_code"
                          component={RenderField}
                          type="text"
                          placeholder="Irányítószám"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Város</span>
                      <div className="form__form-group-field">
                        <Field
                          name="city"
                          component={RenderField}
                          type="text"
                          placeholder="Város"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Utca, házszám</span>
                      <div className="form__form-group-field">
                        <Field
                          name="street"
                          component={RenderField}
                          type="text"
                          placeholder="Utca, házszám"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </FormSection>
            </div>
            <div className="form__half">
              <div className="form-row">
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Céges logó</span>
                    <div className="form__form-group-field">
                      <Field
                        name="images"
                        component={renderDropZoneMultipleField}
                        type="file"
                        accept="image/jpeg, image/png"
                        number={1}
                        actions={actionCallbacks}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        )
      }
      <FormButtonToolbar form={formType} path={listPath} />
    </form>
  );
};

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formType: PropTypes.string,
  listPath: PropTypes.string,
  isLeader: PropTypes.bool.isRequired,
};

ProfileForm.defaultProps = {
  formType: 'edit',
  listPath: '/',
};

const FormDecoratedComponent = reduxForm({
  form: 'profile_edit_form',
  enableReinitialize: true,
})(ProfileForm);

export default FormDecoratedComponent;
