import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PageHandler from '../../../shared/components/PageHandler';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import MaterialTable from '../../../shared/components/table/MaterialTable';

const StatusInfoCard = ({ id }) => {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const isAuthenticated = useAuthCheck();
  const [statusCode, setStatusCode] = useState(null);
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadStatusInfo = useCallback(async () => {
    onShowSpinner();
    const path = `/api/devices/status-info/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          created_at: format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
          last_sign_of_life: (row.last_sign_of_life === null) ? '' : format(new Date(row.last_sign_of_life), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
        onHideSpinner();
      }).catch((err) => {
        setStatusCode(err.response.status);
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true && Number.isInteger(id)) {
      loadStatusInfo();
    }
  }, [id, isAuthenticated, loadStatusInfo]);

  const columns = [
    {
      id: 'last_sign_of_life', label: t('live:lastSignOfLife'), disableSort: true,
    },
    {
      id: 'created_at', label: t('common:createdAt'), disableSort: true,
    },
    {
      id: 'name', label: t('live:deviceStatusName'), disableSort: true,
    },
    {
      id: 'severity', label: t('live:severity'), disableSort: true,
    },
    {
      id: 'note', label: t('live:note'), disableSort: true,
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <MaterialTable
          rows={rows}
          columns={columns}
          total={total}
          callback={loadStatusInfo}
        />
      </Container>
    </PageHandler>
  );
};

StatusInfoCard.propTypes = {
  id: PropTypes.number.isRequired,
};

export default StatusInfoCard;
