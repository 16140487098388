import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import PageHandler from '../../../shared/components/PageHandler';
import TransactionDetailsCard from './components/TransactionDetailsCard';
import TransactionReceiptCard from './components/TransactionReceiptCard';

const Transaction = ({ id, activeModal }) => {
  const isAuthenticated = useAuthCheck();
  const [statusCode, setStatusCode] = useState(null);
  const [transaction, setTransaction] = useState({});
  const [receiptData, setReceiptData] = useState(null);
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadTransaction = useCallback(async () => {
    onShowSpinner();
    const path = `/api/transactions/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setTransaction(res.data.transaction);
        setTickets(res.data.tickets);
        setReceiptData(res.data.ej_data);
        onHideSpinner();
      }).catch((err) => {
        setStatusCode(err.response.status);
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true && Number.isInteger(id)) {
      loadTransaction();
    }
  }, [id, isAuthenticated, loadTransaction]);

  return (
    <PageHandler statusCode={statusCode}>
      {(activeModal === 'details') ? (
        <TransactionDetailsCard transaction={transaction} tickets={tickets} />
      ) : (
        <TransactionReceiptCard data={receiptData} />
      )}
    </PageHandler>
  );
};

Transaction.propTypes = {
  id: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
};

Transaction.defaultProps = {
  id: null,
};

export default Transaction;
