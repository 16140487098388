import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import EmailIcon from 'mdi-react/EmailIcon';
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon';

const EmailConfirmationCard = () => {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="email-confirmation">
            <div className="email-confirmation__icon">
              <EmailIcon className="email-confirmation__mail" />
              <CheckboxMarkedCircleIcon className="email-confirmation__check" />
            </div>
            <h3 className="email-confirmation__title">{t('common:registrationConfirmed')}</h3>
            <p className="email-confirmation__sub" />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EmailConfirmationCard;
