import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useParams } from 'react-router-dom';
import ContentLangForm from '../../components/ContentLangForm';
import { createContent } from '../../../../redux/actions/cmsContentActions';
import throwError from '../../../../shared/utility/errorHandler';

const ContentLangCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { contentId } = useParams();

  const saveContentLang = (contentLang) => {
    const { images = [] } = contentLang;
    const { documents = [] } = contentLang;
    const { videos = [] } = contentLang;

    const path = '/api/content-langs';
    const clone = { ...contentLang };
    clone.contentId = contentId;
    const body = JSON.stringify(clone);
    const formData = new FormData();
    formData.append('jsonObject', body);

    if (images.length) {
      images.forEach((image) => formData.append('images[]', image));
    }
    if (documents.length) {
      documents.forEach((document) => formData.append('documents[]', document));
    }
    if (videos.length) {
      videos.forEach((video) => formData.append('videos[]', video));
    }

    return dispatch(createContent(path, formData, { headers: { 'Content-type': 'multipart/form-data' } }, true)).catch((error) => {
      throwError(error.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveContentLang)}>
      <ContentLangForm form="create" />
    </form>
  );
};

ContentLangCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'content_lang_create_form' })(ContentLangCreateForm);
