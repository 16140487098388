import React from 'react';
import PropTypes from 'prop-types';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AddMenuItem = ({ menuId }) => {
  const { t } = useTranslation();

  return (
    <div className="add-menu-item">
      <Tooltip className="add-menu-item__button" title={t('common:actions:addNew')} placement="bottom" arrow>
        <Link to={`${window.location.pathname}/create-menu-item/${menuId}`}><PlusCircleOutlineIcon size={20} /></Link>
      </Tooltip>
    </div>
  );
};

AddMenuItem.propTypes = {
  menuId: PropTypes.number.isRequired,
};

export default AddMenuItem;
