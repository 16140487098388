import React from 'react';
import ForgotForm from './components/ForgotForm';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const Forgot = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card" style={{ padding: '50px 40px' }}>
        <div className="account__head">
          <div className="account__logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <ForgotForm onSubmit />
      </div>
    </div>
  </div>
);

export default Forgot;
