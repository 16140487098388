import axios from 'axios';
import queryString from 'query-string';
import i18next from 'i18next';
import { reset } from 'redux-form';
import { setAuthToken } from '../../shared/utility/APIUtils';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';

export const AUTH_LOADED = 'AUTH_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const LOGOUT = 'LOGOUT';

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  window.location.replace('/login');
};

export const checkAuthTimeout = (expirationTime) => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime);
};

export const loadAuth = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get('/api/auth');
      const { data } = res;

      if (!data.roles.includes('ROLE_ADMIN')) {
        dispatch({
          type: AUTH_ERROR,
        });
        return;
      }

      dispatch({
        type: AUTH_LOADED,
        payload: data,
      });

      const expiredIn = (data.exp - data.iat) * 1000;
      dispatch(checkAuthTimeout(expiredIn));
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (values) => async (dispatch) => {
  const body = JSON.stringify(values);

  try {
    const res = await axios.post('/api/login_check', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadAuth());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });

    switch (err.response.status) {
      case 401:
        dispatch(setAlert(err.response.data.detail, 'danger'));
        break;
      default:
        dispatch(setAlert(i18next.t('common:error'), 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const register = (values) => async (dispatch) => {
  const body = JSON.stringify(values);

  try {
    await axios.post('/api/signup', body);

    dispatch({
      type: REGISTER_SUCCESS,
    });

    dispatch(reset('register_form'));
    dispatch(setAlert(i18next.t('common:success'), 'success'));
    dispatch(setAlert(i18next.t('common:checkYourMail'), 'info', 5000));

    setTimeout(() => {
      window.location.replace('/');
    }, 3000);
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
    });

    switch (err.response.status) {
      default:
        dispatch(setAlert(i18next.t('common:error'), 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const forgotPassword = (values) => async (dispatch) => {
  const body = JSON.stringify(values);

  try {
    const res = await axios.post('/api/auth/forgot', body);

    dispatch({
      type: FORGOT_SUCCESS,
    });
    dispatch(reset('forgot_form'));
    dispatch(setAlert(res.data.message, 'info'));
  } catch (err) {
    dispatch({
      type: FORGOT_FAIL,
    });

    switch (err.response.status) {
      default:
        dispatch(setAlert(i18next.t('common:error'), 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const resetPassword = (values) => async (dispatch) => {
  const query = queryString.parse(window.location.search);
  const path = `/api/auth/reset?reset_token=${query.reset_token}`;
  const body = JSON.stringify(values);

  try {
    const res = await axios.post(path, body);

    dispatch({
      type: RESET_SUCCESS,
    });
    dispatch(reset('reset_form'));
    dispatch(setAlert(res.data.message, 'success'));
  } catch (err) {
    dispatch({
      type: RESET_FAIL,
    });
    dispatch(reset('reset_form'));

    switch (err.response.status) {
      default:
        dispatch(setAlert(i18next.t('common:error'), 'danger'));
        break;
    }

    if (err.response.data.type === 'Link Error') {
      dispatch(setAlert(i18next.t('common:invalidLinkError'), 'danger'));
    }

    throwError(err.response.data);
  }
};
