import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AccountMain from './components/AccountMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { getFrontendRole } from '../../../shared/utility/RoleHelper';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const AccountPage = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const { t } = useTranslation();
  const [account, setAccount] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const loadAccount = useCallback(async () => {
    const path = `/api/users/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setAccount({
          ...res.data,
          role: getFrontendRole(res.data.roles[0]),
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadAccount();
    }
  }, [isAuthenticated, loadAccount]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="account-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('account:view')}</h3>
            </Col>
          </Row>
          <Row>
            <AccountMain data={account} />
          </Row>
        </div>
        <BackButton path="/accounts" />
      </Container>
    </PageHandler>
  );
};

export default AccountPage;
