import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import CancellationFilterCard from './Filter/CancellationFilterCard';
import PageHandler from '../../shared/components/PageHandler';

const CancellationList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const operations = ['show'];
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/cancellations${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          created_at: row.created_at && format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
          updated_at: row.updated_at && format(new Date(row.updated_at), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'bookingReference', label: t('cancellation:bookingReference'),
    },
    {
      id: 'status', label: t('cancellation:status'),
    },
    {
      id: 'created_at', label: t('common:createdAt'),
    },
    {
      id: 'updated_at', label: t('common:updatedAt'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('cancellation:list')}</h3>
          </Col>
        </Row>
        <Row><CancellationFilterCard refreshCallback={fetchData} /></Row>
        <Row>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default CancellationList;
