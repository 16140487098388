import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import ReactJson from 'react-json-view';

const BookingJsonPanel = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="booking__json_panel">
            <div className="card__title">
              <h5 className="bold-text">{t('common:jsonPanel')}</h5>
            </div>
            <hr />
            <div className="">
              {data.viator_response && (
                <>
                  <Row>
                    <Col md={12}>
                      <p>{t('common:viatorResponse')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ReactJson
                        src={data.viator_response}
                        name={false}
                        collapseStringsAfterLength={100}
                        displayDataTypes={false}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col md={12}>
                  <p>{t('common:viatorRequest')}</p>
                </Col>
              </Row>
              {data.viator_request && (
                <Row>
                  <Col md={12}>
                    <ReactJson
                      src={data.viator_request}
                      name={false}
                      collapseStringsAfterLength={100}
                      displayDataTypes={false}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

BookingJsonPanel.propTypes = {
  data: PropTypes.shape({
    viator_request: PropTypes.shape(),
    viator_response: PropTypes.shape(),
  }).isRequired,
};

export default BookingJsonPanel;
