import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import AutocompleteSelect from '../../../shared/components/form/AutocompleteSelect';
import RenderField from '../../../shared/components/form/RenderField';
import { setAlert } from '../../../redux/actions/alertActions';

const DeviceCommandForm = (props) => {
  const {
    form,
    path,
  } = props;

  const isAuthenticated = useAuthCheck();
  const [deviceList, setDeviceList] = useState([]);
  const [commandList, setCommandList] = useState([]);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    await axios.get('/api/devices/options')
      .then((res) => {
        setDeviceList(res.data.map(((device) => ({
          id: device.id,
          name: device.name,
        }))));
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      }).finally(() => {
        onHideSpinner();
      });
    await axios.get('/api/commands')
      .then((res) => {
        setCommandList(res.data.map(((command) => ({
          id: command.id,
          name: command.name,
        }))));
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner, dispatch, t]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('deviceCommand:device')}</span>
              <div className="form__form-group-field">
                <Field
                  name="device"
                  component={AutocompleteSelect}
                  options={deviceList}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('deviceCommand:command')}</span>
              <div className="form__form-group-field">
                <Field
                  name="command"
                  component={AutocompleteSelect}
                  options={commandList}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('deviceCommand:parameter')}</span>
              <div className="form__form-group-field">
                <Field
                  name="parameter"
                  component={RenderField}
                  type="text"
                  placeholder={t('deviceCommand:parameter')}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

DeviceCommandForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

DeviceCommandForm.defaultProps = {
  form: '',
  path: '/device-commands',
};

export default DeviceCommandForm;
