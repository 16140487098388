import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { useTranslation } from 'react-i18next';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function difference(a, b) {
  return a.filter(({ id: id1 }) => !b.some(({ id: id2 }) => id2 === id1));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const RenderTransferList = ({
  options, onSetValue, input, meta: { form },
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(difference(options, (input.value || [])));
  const [right, setRight] = useState(input.value || []);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    onSetValue({ form, name: input.name, value: right });
  }, [right, form, input.name, onSetValue]);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        avatar={
          (
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
              numberOfChecked(items) !== items.length
              && numberOfChecked(items) !== 0
            }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': t('transferList:allSelected') }}
            />
        )
       }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length}`.concat(' ').concat(t('transferList:selected'))}
      />
      <Divider />
      <List dense component="div" role="list">
        {items.map((item) => (
          <ListItem
            key={item.id}
            role="listitem"
            button
            onClick={handleToggle(item)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(item) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': item.id }}
              />
            </ListItemIcon>
            <ListItemText id={item.id} primary={item.name} />
          </ListItem>
        ))}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifycontent="center" alignItems="center">
      <Grid item>{customList(t('transferList:choices'), left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(t('transferList:chosen'), right)}</Grid>
    </Grid>
  );
};

RenderTransferList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  input: PropTypes.shape().isRequired,
  onSetValue: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSetValue: (arg) => dispatch(change(arg.form, arg.name, arg.value)),
});

export default connect(null, mapDispatchToProps)(RenderTransferList);
