import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ConfirmPopup = (props) => {
  const {
    item,
    isOpen,
    onConfirm,
    onCancel,
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {t('common:deletePrompt')}
          {' '}
          {item}
        </ModalHeader>
        <ModalBody>
          <Button color="danger" onClick={onConfirm}>{t('common:actions:delete')}</Button>
          {' '}
          <Button color="secondary" onClick={onCancel}>{t('common:buttons:cancel')}</Button>
        </ModalBody>
      </Modal>
    </div>
  );
};

ConfirmPopup.defaultProps = {
  item: '',
};

ConfirmPopup.propTypes = {
  item: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmPopup;
