import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import LocationEditForm from './LocationEditForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    clone.images = clone.location_references
    && clone.location_references.map((ref) => ({ ...ref, preview: ref.src }));
    delete clone.id;
    delete clone.location_references;
    delete clone.productions;
    return clone;
  }
  return object;
};

const LocationEditCard = ({ data }) => {
  const optimizeData = optimize(data);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <LocationEditForm initialValues={optimizeData} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

LocationEditCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default LocationEditCard;
