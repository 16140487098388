import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Row, Col, Badge,
} from 'reactstrap';
import { format } from 'date-fns';

const AccountMain = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="account__main">
            <Row>
              <Col md={3}>
                <p className="account__info">{t('account:email')}</p>
                <span>{data.email}</span>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:role')}</p>
                <span>{data.role}</span>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:firstName')}</p>
                <span>{data.first_name}</span>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:lastName')}</p>
                <span>{data.last_name}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <p className="account__info">{t('account:phoneNumber')}</p>
                <span>{data.phone_number}</span>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:birthDate')}</p>
                <span>{data.birth_date ? format(new Date(data.birth_date), 'yyyy-MM-dd') : '-'}</span>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:enabled')}</p>
                <p className="account__dependency">
                  <Badge color={`${data.enabled}`}>
                    {data.enabled ? t('account:enabled') : t('account:notEnabled')}
                  </Badge>
                </p>
              </Col>
              <Col md={3}>
                <p className="account__info">{t('account:confirmed')}</p>
                <p className="account__dependency">
                  <Badge color={`${data.confirmed}`}>
                    {data.confirmed ? t('account:confirmed') : t('account:notConfirmed')}
                  </Badge>
                </p>
              </Col>
            </Row>
            <Row>
              {data.created_at && (
              <Col md={3}>
                <p className="account__info">{t('common:createdAt')}</p>
                <span>{format(new Date(data.created_at), 'yyyy-MM-dd HH:mm:ss')}</span>
              </Col>
              )}
              {data.updated_at && (
              <Col md={3}>
                <p className="account__info">{t('common:updatedAt')}</p>
                <span>{format(new Date(data.updated_at), 'yyyy-MM-dd HH:mm:ss')}</span>
              </Col>
              )}
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

AccountMain.propTypes = {
  data: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    birth_date: PropTypes.string,
    confirmed: PropTypes.bool,
    enabled: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default AccountMain;
