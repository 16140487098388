import { useSelector } from 'react-redux';

const useAvailableMenuItemLangs = (menuItemId) => {
  const result = useSelector((state) => {
    const { languages = [] } = state.cms.content;
    const parentMenuItem = state.cms.menu.menuItemList.find(
      (menuItem) => menuItem.id === +menuItemId,
    );
    const siblingMenuItemLangs = parentMenuItem?.menu_item_langs;
    const usedLangs = siblingMenuItemLangs?.map((menuItemLang) => menuItemLang.language);
    const availableLangs = languages.filter((lang) => {
      const hasMatch = usedLangs?.find((usedLang) => usedLang.id === lang.id);
      return !hasMatch;
    });
    return availableLangs;
  });
  return result;
};

export default useAvailableMenuItemLangs;
