import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import TicketTypeCreateForm from './TicketTypeCreateForm';

const TicketTypeCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <TicketTypeCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default TicketTypeCreateCard;
