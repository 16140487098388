import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import ContentLangForm from '../../components/ContentLangForm';
import {
  addContentLangReference,
  removeContentLangReference,
  reorderContentLangReference,
  updateContent,
} from '../../../../redux/actions/cmsContentActions';

const ContentLangEditForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const {
    images: initialImages = [],
    documents: initialDocuments = [],
    videos: initialVideos = [],
  } = useSelector((state) => (
    state.form.content_lang_edit_form.initial
  ));
  const {
    images: currentImages = [],
    documents: currentDocuments = [],
    videos: currentVideos = [],
  } = useSelector((state) => (
    state.form.content_lang_edit_form.values
  ));

  const getImagesToDelete = () => {
    const imagesToDelete = initialImages.filter((initialImage) => !currentImages.some((image) => (
      image.id === initialImage.id
    )));
    return imagesToDelete;
  };

  const getImagesToUpload = () => {
    const imagesToUpload = currentImages.filter((image) => ('path' in image));
    return imagesToUpload;
  };

  const getImagesToReorder = (images) => {
    const imagesToReorder = currentImages.filter((image) => images.some(
      (initialImage) => (
        image.id === initialImage.id && image.order_number !== initialImage.order_number
      ),
    )).map((change) => ({ id: change.id, order_number: change.order_number }));

    return imagesToReorder;
  };

  const getDocumentsToDelete = () => {
    const documentsToDelete = initialDocuments.filter((initialDocument) => (
      !currentDocuments.some((document) => (
        document.id === initialDocument.id
      ))));
    return documentsToDelete;
  };

  const getDocumentsToUpload = () => {
    const documentsToUpload = currentDocuments.filter((document) => ('path' in document));
    return documentsToUpload;
  };

  const getDocumentsToReorder = (documents) => {
    const documentsToReorder = currentDocuments.filter((document) => documents.some(
      (initialDocument) => (
        document.id === initialDocument.id && document.order_number !== initialDocument.order_number
      ),
    )).map((change) => ({ id: change.id, order_number: change.order_number }));

    return documentsToReorder;
  };

  const getVideosToDelete = () => {
    const videosToDelete = initialVideos.filter((initialVideo) => (
      !currentVideos.some((video) => (
        video.id === initialVideo.id
      ))));
    return videosToDelete;
  };

  const getVideosToUpload = () => {
    const videosToUpload = currentVideos.filter((video) => ('path' in video));
    return videosToUpload;
  };

  const getVideosToReorder = (videos) => {
    const videosToReorder = currentVideos.filter((video) => videos.some(
      (initialVideo) => (
        video.id === initialVideo.id && video.order_number !== initialVideo.order_number
      ),
    )).map((change) => ({ id: change.id, order_number: change.order_number }));

    return videosToReorder;
  };

  const optimizeData = (cl) => {
    const clone = { ...cl };
    delete clone.id;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    delete clone.language;
    return clone;
  };

  const editContentLang = async (contentLang) => {
    const imagesToDelete = getImagesToDelete();
    const imagesToUpload = getImagesToUpload();
    const imagesToReorder = getImagesToReorder(contentLang.images);
    const documentsToDelete = getDocumentsToDelete();
    const documentsToUpload = getDocumentsToUpload();
    const documentsToReorder = getDocumentsToReorder(contentLang.documents);
    const videosToDelete = getVideosToDelete();
    const videosToUpload = getVideosToUpload();
    const videosToReorder = getVideosToReorder(contentLang.videos);

    const path = `/api/content-langs/${contentLang.id}`;
    const body = JSON.stringify(optimizeData(contentLang));

    if (imagesToUpload.length) {
      const imageUploadForm = new FormData();
      imagesToUpload.forEach((image) => {
        imageUploadForm.append('images[]', image);
      });
      dispatch(addContentLangReference(
        `/api/content-langs/${contentLang.id}/img-references`,
        imageUploadForm,
        { headers: { 'Content-type': 'multipart/form-data' } },
      ));
    }

    if (documentsToUpload.length) {
      const documentUploadForm = new FormData();
      documentsToUpload.forEach((document) => {
        documentUploadForm.append('documents[]', document);
      });
      dispatch(addContentLangReference(
        `/api/content-langs/${contentLang.id}/doc-references`,
        documentUploadForm,
        { headers: { 'Content-type': 'multipart/form-data' } },
      ));
    }

    if (videosToUpload.length) {
      const videoUploadForm = new FormData();
      videosToUpload.forEach((video) => {
        videoUploadForm.append('videos[]', video);
      });
      dispatch(addContentLangReference(
        `/api/content-langs/${contentLang.id}/vid-references`,
        videoUploadForm,
        { headers: { 'Content-type': 'multipart/form-data' } },
      ));
    }

    if (imagesToDelete.length) {
      await Promise.all(
        imagesToDelete.map((image) => (
          dispatch(removeContentLangReference(`/api/content-langs/img-references/${image.id}`))
        )),
      );
    }

    if (documentsToDelete.length) {
      await Promise.all(
        documentsToDelete.map((document) => (
          dispatch(removeContentLangReference(`/api/content-langs/doc-references/${document.id}`))
        )),
      );
    }

    if (videosToDelete.length) {
      await Promise.all(
        videosToDelete.map((video) => (
          dispatch(removeContentLangReference(`/api/content-langs/vid-references/${video.id}`))
        )),
      );
    }

    if (imagesToReorder.length) {
      dispatch(reorderContentLangReference(
        `/api/content-langs/${contentLang.id}/img-references/reorder`,
        imagesToReorder,
      ));
    }

    if (documentsToReorder.length) {
      dispatch(reorderContentLangReference(
        `/api/content-langs/${contentLang.id}/doc-references/reorder`,
        documentsToReorder,
      ));
    }

    if (videosToReorder.length) {
      dispatch(reorderContentLangReference(
        `/api/content-langs/${contentLang.id}/vid-references/reorder`,
        videosToReorder,
      ));
    }

    return dispatch(updateContent(path, body, true)).catch((err) => {
      throwError(err.response?.data);
    });
  };

  return (
    <form className="form content-edit-page__form" onSubmit={handleSubmit(editContentLang)}>
      <ContentLangForm form="edit" />
    </form>
  );
};

ContentLangEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'content_lang_edit_form',
  enableReinitialize: true,
})(ContentLangEditForm);

export default FormDecoratedComponent;
