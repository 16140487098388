import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { format } from 'date-fns';

const PlaylistCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    (data !== null) && (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="playlist-card">
              <Row>
                <Col md={12} lg={12}>
                  <div className="playlist-card__info">
                    <Row>
                      <Col md={2}>
                        <p>{t('playlist:name')}</p>
                        <span>{data.playlist.name}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <p>{t('playlist:validFrom')}</p>
                        <span>{format(new Date(data.playlist.valid_from), 'yyyy-MM-dd HH:mm')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <p>{t('playlist:validTo')}</p>
                        <span>{format(new Date(data.playlist.valid_to), 'yyyy-MM-dd HH:mm')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <List>
                          <p>{t('playlist:devices')}</p>
                          {data.devicePlaylists.map((item) => (
                            <ListItem key={item.id}>{item.name}</ListItem>
                          ))}
                        </List>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <List>
                          <p>{t('playlist:contents')}</p>
                          {data.playlistContents.map((item) => (
                            <ListItem key={item.content_lang_video_reference}>{item.name}</ListItem>
                          ))}
                        </List>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  );
};

PlaylistCard.propTypes = {
  data: PropTypes.shape({
    playlist: PropTypes.shape({
      name: PropTypes.string,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string,
    }),
    devicePlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    playlistContents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
};

PlaylistCard.defaultProps = {
  data: null,
};

export default PlaylistCard;
