import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarNavLink from './topbar_nav/TopbarNavLink';
import Can from '../../shared/authorization/Can';
import { logout } from '../../redux/actions/authActions';
import { getFrontendRole } from '../../shared/utility/RoleHelper';

const OneTestIcon = `${process.env.PUBLIC_URL}/img/OneTestIcon.svg`;

const TopbarProfile = ({
  currentAccount, logoutClick, defaultOpen, t,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const userCircle = currentAccount?.icon
    ? (<img className="topbar__avatar-img" src={currentAccount?.icon} alt="avatar" />)
    : (
      <div className="topbar__avatar-user-circle">
        {currentAccount?.firstName.match(/[A-Z]/g)?.slice(0, 1).join('')}
        {currentAccount?.lastName.match(/[A-Z]/g)?.slice(0, 1).join('')}
      </div>
    );

  return (
    <Dropdown
      className="topbar__nav-dropdown"
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle className="topbar__nav-dropdown-toggle">
        {userCircle}
        <div className="topbar__avatar-group">
          <p className="topbar__avatar-name">{currentAccount !== null ? `${currentAccount.firstName} ${currentAccount.lastName}` : ''}</p>
          <p className="topbar__avatar-role">{currentAccount !== null ? getFrontendRole(currentAccount.roles[0]) : ''}</p>
        </div>
        <DownIcon />
      </DropdownToggle>
      <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
        <Can
          perform="accounts"
          yes={() => (
            <DropdownItem>
              <TopbarNavLink title={t('menu:profile:approveUsers')} icon={OneTestIcon} route="/accounts/approve-list" />
            </DropdownItem>
          )}
        />
        <DropdownItem>
          <TopbarNavLink title={t('menu:profile:logout')} icon={OneTestIcon} onClick={(e) => logoutClick(e)} activeClassName="" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  currentAccount: state.auth.account,
});

const mapDispatchToProps = (dispatch) => ({
  logoutClick: (e) => {
    e.preventDefault();
    dispatch(logout());
  },
});

TopbarProfile.propTypes = {
  currentAccount: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    roles: PropTypes.instanceOf(Array),
    icon: PropTypes.string,
  }),
  defaultOpen: PropTypes.bool,
  logoutClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TopbarProfile.defaultProps = {
  currentAccount: null,
  defaultOpen: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopbarProfile));
