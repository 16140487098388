import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import { withTranslation } from 'react-i18next';

const errorTranslate = (msg, t) => {
  const msgTranslated = t(msg);
  return msgTranslated;
};

const disableCharacters = (e, type) => {
  if (type === 'number' && (e.key === 'e' || e.keyCode === 69 || e.keyCode === 190)) {
    e.preventDefault();
  }
};

const RenderCleaveField = ({
  t, input, options, placeholder, disabled, type, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <Cleave
      {...input}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      onKeyDown={(e) => disableCharacters(e, type)}
    />
    {touched && error && <span className="form__form-group-error">{errorTranslate(error, t)}</span>}
  </div>
);

RenderCleaveField.propTypes = {
  t: PropTypes.func.isRequired,
  input: PropTypes.shape().isRequired,
  options: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderCleaveField.defaultProps = {
  placeholder: '',
  disabled: false,
  meta: null,
  type: 'text',
};

export default withTranslation('common')(RenderCleaveField);
