import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import MenuItemEditForm from './MenuItemEditForm';
import MenuItemLangEditContainer from './MenuItemLangEditContainer';

const MenuItemEditCard = ({ data }) => {
  const optimizeData = (menuItem) => {
    const clone = { ...menuItem };
    clone.contentId = menuItem.contents && menuItem.contents[0]?.id;
    delete clone.id;
    delete clone.menu_item_langs;
    delete clone.contents;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    return clone;
  };

  const { menu_item_langs: menuItemLangs = [] } = data || {};

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <MenuItemEditForm
            enableReinitialize
            keepDirtyOnReinitialize
            initialValues={optimizeData(data)}
            onSubmit
          />
          <MenuItemLangEditContainer
            data={menuItemLangs}
            id={+data.id}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

MenuItemEditCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default MenuItemEditCard;
