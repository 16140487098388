import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import LinkVariantPlusIcon from 'mdi-react/LinkVariantPlusIcon';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const AddContentWidget = ({ toggleContentSelector }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <div className="add-content-widget">
      <Tooltip title={t('common:actions:createNew')} placement="bottom" arrow>
        <Link to={`/contents/create/${id}`}>
          <PlusCircleOutlineIcon size={25} />
        </Link>
      </Tooltip>
      <div className="separator" />
      <Tooltip title={t('common:actions:linkExisting')} placement="bottom" arrow>
        <Link to={`${window.location.pathname}${window.location.search}`} onClick={toggleContentSelector}>
          <LinkVariantPlusIcon size={25} />
        </Link>
      </Tooltip>
    </div>
  );
};

AddContentWidget.propTypes = {
  toggleContentSelector: PropTypes.func.isRequired,
};

export default AddContentWidget;
