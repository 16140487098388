import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import RenderField from '../../../shared/components/form/RenderField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';

const MenuItemForm = ({ form, path }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('cmsMenuItem:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('cmsMenuItem:name')}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

MenuItemForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

MenuItemForm.defaultProps = {
  form: '',
  path: '/menus',
};

export default MenuItemForm;
