import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import RenderField from '../../../shared/components/form/RenderField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import RenderTransferList from '../../../shared/components/form/RenderTransferList';
import { setAlert } from '../../../redux/actions/alertActions';

const PlaylistForm = (props) => {
  const {
    form,
    path,
  } = props;

  const { t } = useTranslation();

  const [deviceList, setDeviceList] = useState(null);
  const [contentList, setContentList] = useState(null);
  const dispatch = useDispatch();

  const fetchOptions = useCallback(async () => {
    await axios.get('/api/devices/options')
      .then((res) => {
        const list = res.data.map(((option) => ({
          id: option.id,
          name: option.name,
        })));
        setDeviceList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });

    await axios.get('/api/contents/options')
      .then((res) => {
        const list = res.data.map(((option) => ({
          id: option.id,
          name: option.name,
        })));
        setContentList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });
  }, [dispatch, t]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('playlist:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('playlist:name')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={2}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('playlist:validFrom')}</span>
              <div className="form__form-group-field">
                <Field
                  name="valid_from"
                  component={renderDatePickerField}
                  showTimeSelect
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={2}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('playlist:validTo')}</span>
              <div className="form__form-group-field">
                <Field
                  name="valid_to"
                  component={renderDatePickerField}
                  showTimeSelect
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('playlist:devices')}</span>
              <div className="form__form-group-field">
                {deviceList
                && (
                  <Field
                    name="devices"
                    component={RenderTransferList}
                    type="text"
                    options={deviceList}
                  />
                )}
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('playlist:contents')}</span>
              <div className="form__form-group-field">
                {contentList
                && (
                  <Field
                    name="contents"
                    component={RenderTransferList}
                    type="text"
                    options={contentList}
                  />
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

PlaylistForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

PlaylistForm.defaultProps = {
  form: '',
  path: '/playlists',
};

export default PlaylistForm;
