import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row,
} from 'reactstrap';

const TransactionReceiptCard = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [receiptData, setReceiptData] = useState(null);

  const initData = useCallback((dataForReceipt) => {
    setReceiptData(dataForReceipt);
  }, []);

  useEffect(() => {
    initData(data);
    setLoading(false);
  }, [data, initData]);

  return (
    <Container>
      {!loading && receiptData !== null && (
        <Row>
          <Col md={12}>
            <div align="center" dangerouslySetInnerHTML={{ __html: receiptData }} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

TransactionReceiptCard.propTypes = {
  data: PropTypes.string.isRequired,
};

export default TransactionReceiptCard;
