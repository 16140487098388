import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import ContentCreateForm from './ContentCreateForm';

const ContentCreateCard = () => {
  const { menuItemId = null } = useParams();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ContentCreateForm initialValues={{ menu_item_id: +menuItemId }} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ContentCreateCard;
