import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import DeviceCard from './components/DeviceCard';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const DevicePage = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [device, setDevice] = useState({});
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadDevice = useCallback(async () => {
    onShowSpinner();
    const path = `/api/devices/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setDevice(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadDevice();
    }
  }, [isAuthenticated, loadDevice]);

  return (
    <PageHandler statusCode={statusCode}>
      <form className="form">
        <Container>
          <div className="device-page">
            <Row>
              <Col md={12}>
                <h3 className="page-title">{t('device:view')}</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <DeviceCard data={device} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <BackButton path="/devices" />
        </Container>
      </form>
    </PageHandler>
  );
};

const devicePageForm = reduxForm({ form: 'device_page_form' })(DevicePage);
export default devicePageForm;
