import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import MenuLangForm from '../../components/MenuLangForm';
import { editMenuEntry } from '../../../../redux/actions/cmsMenuActions';

const MenuLangEditForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const optimizeData = (cl) => {
    const clone = { ...cl };
    delete clone.id;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    delete clone.lang;
    return clone;
  };

  const editMenuLang = async (menuLang) => {
    const path = `/api/menu-langs/${menuLang.id}`;
    const body = JSON.stringify(optimizeData(menuLang));

    return dispatch(editMenuEntry(path, body, true)).catch((err) => {
      throwError(err.response?.data);
    });
  };

  return (
    <form className="form menu-edit-page__form" onSubmit={handleSubmit(editMenuLang)}>
      <MenuLangForm form="edit" />
    </form>
  );
};

MenuLangEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'menu_lang_edit_form',
  enableReinitialize: true,
})(MenuLangEditForm);

export default FormDecoratedComponent;
