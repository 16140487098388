import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { getFrontendRole } from '../../shared/utility/RoleHelper';
import Can from '../../shared/authorization/Can';
import AccountFilterCard from './Filter/AccountFilterCard';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';

const AccountList = ({ t }) => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const operations = [];
  const [filter, setFilter] = useState('');
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/users${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => {
          const newRow = { ...row };
          if (Array.isArray(row.roles) && row.roles.length > 0) {
            newRow.role = getFrontendRole(row.roles[0]);
          }

          return newRow;
        }));
        setTotal(res.data.totalCount);
        onHideSpinner();
      }).catch((err) => {
        setStatusCode(err.response.status);
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      setFilter(<Row><AccountFilterCard refreshCallback={fetchData} /></Row>);
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'first_name', label: t('account:firstName'),
    },
    {
      id: 'last_name', label: t('account:lastName'),
    },
    {
      id: 'email', label: t('account:email'),
    },
    {
      id: 'role', label: t('account:role'), disableSort: true,
    },
    {
      id: 'confirmed', label: t('account:confirmed'),
    },
    {
      id: 'enabled', label: t('account:enabled'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('account:list')}</h3>
          </Col>
          <Col md={4}>
            <Can
              perform="accounts:create"
              yes={() => (
                <Link
                  className="btn btn-success float-right"
                  to={`${window.location.pathname}/create`}
                >
                  {t('account:create')}
                </Link>
              )}
            />
          </Col>
        </Row>
        {filter}
        <Row>
          <Can
            perform="accounts:edit"
            permissionPerform="accounts:edit"
            yes={() => { operations.push('edit'); return null; }}
          />
          <Can
            perform="accounts:show"
            permissionPerform="accounts:show"
            yes={() => { operations.push('show'); return null; }}
          />
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

AccountList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AccountList);
