const getPermissionSlugList = (permissionGroups) => permissionGroups
  .map((permissionGroup) => permissionGroup.permission
    .map((permission) => ({ id: permission.id, name: permission.slug })))
  .flat();

const getGroupByPermission = (permissionName, permissionGroups) => permissionGroups
  .find((permissionGroup) => permissionGroup.permission
    .map((permission) => permission.slug)
    .includes(permissionName));

const getPermissionGroupSlugList = (userPermissions, permissionGroups) => userPermissions
  .map((userPermission) => getGroupByPermission(userPermission.slug, permissionGroups).slug);

export {
  getPermissionSlugList,
  getPermissionGroupSlugList,
  getGroupByPermission,
};
