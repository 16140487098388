import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const MenuItemLangTab = ({ menuItemLang }) => {
  const {
    id,
    name,
  } = menuItemLang;

  const { t } = useTranslation();

  return (
    <TabPane tabId={id}>
      <Row>
        <Col md={3}>
          <div className="menu-item-page__menu-field">
            <p>
              {t('menuLang:name')}
            </p>
            <span>{name || '-'}</span>
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

MenuItemLangTab.propTypes = {
  menuItemLang: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default MenuItemLangTab;
