import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import ContentForm from '../../components/ContentForm';
import { createContent } from '../../../../redux/actions/cmsContentActions';
import throwError from '../../../../shared/utility/errorHandler';

const ContentCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const saveContent = (content) => {
    const path = '/api/contents';
    const body = JSON.stringify(content);
    return dispatch(createContent(path, body, {}, true)).catch((error) => {
      throwError(error.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveContent)}>
      <ContentForm form="create" />
    </form>
  );
};

ContentCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'content_create_form' })(ContentCreateForm);
