import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import CancellationMain from './components/CancellationMain';
import CancellationJsonPanel from './components/CancellationJsonPanel';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const CancellationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [cancellation, setCancellation] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const loadCancellation = useCallback(async () => {
    const path = `/api/cancellations/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setCancellation({
          ...res.data,
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadCancellation();
    }
  }, [isAuthenticated, loadCancellation]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="cancellation-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('cancellation:view')}</h3>
            </Col>
          </Row>
          <Row>
            <CancellationMain data={cancellation} />
            <CancellationJsonPanel data={cancellation} />
          </Row>
        </div>
        <BackButton path="/cancellations" />
      </Container>
    </PageHandler>
  );
};

export default CancellationPage;
