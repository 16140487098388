import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, CardBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddContentWidget from './AddContentWidget';
import MaterialTable from '../../../../shared/components/table/MaterialTable';
import { deleteContent, fetchContents, updateContent } from '../../../../redux/actions/cmsContentActions';
import ConfirmPopup from '../../../../shared/components/ConfirmPopup';
import ContentFilterCard from '../../../Content/Filter/ContentFilterCard';
import ContentSelectorModal from './ContentSelectorModal';

const ContentManager = ({ contents, readOnly }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [titleToDelete, setTitleToDelete] = useState();
  const [contentSelectorOpen, setContentSelectorOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.cms.content.contentCount || 0);
  const operations = readOnly ? ['show'] : ['show', 'edit', 'delete', 'unlink'];

  const showDeleteConfirmation = (id) => {
    setIsModalOpen(true);
    setIdToDelete(id);
    const contentToDelete = contents.find((content) => content.id === +id);
    setTitleToDelete(contentToDelete?.title);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
    setIdToDelete(null);
    setTitleToDelete('');
  };

  const onConfirmDelete = () => {
    dispatch(deleteContent(`/api/contents/${idToDelete}`));
    setIsModalOpen(false);
    setIdToDelete(null);
    setTitleToDelete('');
  };

  const unlinkContent = (id) => {
    dispatch(updateContent(`/api/contents/${id}`, { menu_item_id: null }));
    dispatch(fetchContents(`/api/contents${window.location.search}`));
  };

  const columns = [
    {
      id: 'title', label: t('content:title'),
    },
    {
      id: 'created_at', label: t('common:createdAt'),
    },
    {
      id: 'updated_at', label: t('common:updatedAt'),
    },
  ];

  const actionCallbacks = {
    delete: showDeleteConfirmation,
    unlink: unlinkContent,
  };

  const actionLinks = {
    show: (rowId) => `/contents/show/${rowId}`,
    edit: (rowId) => `/contents/edit/${rowId}`,
  };

  const toggleContentSelector = () => {
    setContentSelectorOpen(!contentSelectorOpen);
  };

  return (
    <>
      <ConfirmPopup
        isOpen={isModalOpen}
        item={titleToDelete}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
      <ContentSelectorModal isOpen={contentSelectorOpen} toggle={toggleContentSelector} />
      <Col>
        <Row>
          <Card>
            <CardBody>
              <div className="content-manager">
                <div className="content-manager__header">
                  <ContentFilterCard />
                  {!readOnly && <AddContentWidget toggleContentSelector={toggleContentSelector} />}
                </div>
                <MaterialTable
                  rows={contents || []}
                  columns={columns}
                  total={totalCount}
                  callback={() => { dispatch(fetchContents(`/api/contents${window.location.search}`)); }}
                  operations={operations}
                  actionCallbacks={actionCallbacks}
                  actionLinks={actionLinks}
                />
              </div>
            </CardBody>
          </Card>
        </Row>
      </Col>
    </>
  );
};

ContentManager.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool,
};

ContentManager.defaultProps = {
  readOnly: false,
};

export default ContentManager;
