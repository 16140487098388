import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RenderField from '../../../shared/components/form/RenderField';
import convertToURL from '../../../shared/utility/filter';

const BookingFilterForm = (props) => {
  const {
    handleSubmit,
    refreshCallback,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const sendFilter = (async (filter) => {
    const copy = { ...filter };

    const filterString = convertToURL(copy);
    history.push(`${window.location.pathname}?${filterString}`);
    refreshCallback();
  });

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={12}>
        <Row>
          <Col lg={3} md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('booking:reference')}</span>
              <div className="form__form-group-field">
                <Field
                  name="reference"
                  component={RenderField}
                  type="text"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="line-end">
        <button className="btn btn-success" type="submit">{t('common:filter')}</button>
      </Col>
    </form>
  );
};

BookingFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'booking_filter',
  enableReinitialize: true,
})(BookingFilterForm);

export default FormDecoratedComponent;
