import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Nav, NavLink, NavItem, TabContent,
} from 'reactstrap';
import MenuItemLangTab from './MenuItemLangTab';

const MenuItemMain = ({ data }) => {
  const { menu_item_langs: menuItemLangs = [] } = data;
  const [activeTab, setActiveTab] = useState();

  const menuItemLangLinks = () => menuItemLangs.map((menuItemLang) => (
    <NavItem key={menuItemLang.id}>
      <NavLink onClick={() => { setActiveTab(menuItemLang.id); }} className={activeTab === menuItemLang.id ? 'active' : null}>{menuItemLang.language.name}</NavLink>
    </NavItem>
  ));

  const menuItemLangTabs = () => menuItemLangs.map(
    (menuItemLang) => <MenuItemLangTab key={menuItemLang.id} menuItemLang={menuItemLang} />,
  );
  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody className="menu-item-page__main">
            <div className="menu-item-page__menu-item-container">
              <h4>{data.name}</h4>
            </div>
            { menuItemLangs.length > 0 && (
            <div className="menu-item-page__nav-container">
              <Nav tabs>
                {menuItemLangLinks()}
              </Nav>
              <TabContent activeTab={activeTab}>
                {menuItemLangTabs()}
              </TabContent>
            </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

MenuItemMain.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    menu_item_langs: PropTypes.array,
  }).isRequired,
};

export default MenuItemMain;
