import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNav from './topbar_nav/TopbarNav';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const TopbarWithNavigation = ({ changeMobileSidebarVisibility }) => (
  <div className="topbar topbar--navigation">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
        <Link className="topbar__logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <TopbarNav />
      <div className="topbar__right">
        <TopbarProfile />
      </div>
    </div>
  </div>
);

TopbarWithNavigation.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default TopbarWithNavigation;
