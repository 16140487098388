import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import MenuCreateForm from './MenuCreateForm';

const MenuCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <MenuCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default MenuCreateCard;
