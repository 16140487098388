import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const DeviceStatusCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="device-status-card">
            <Row>
              <Col md={12} lg={12}>
                <div className="device-status-card__info">
                  <Row>
                    <Col md={2}>
                      <p>{t('deviceStatus:statusName')}</p>
                      <span>{data.name}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('deviceStatus:severityName')}</p>
                      <span>{data.severity}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

DeviceStatusCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    severity: PropTypes.string,
  }).isRequired,
};

export default DeviceStatusCard;
