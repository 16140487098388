import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { Col, Row, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import Gallery from '../../../../shared/components/Gallery';
import VideoPlayIcon from '../../../../shared/components/VideoPlayIcon';

const ContentLangTab = ({ contentLang }) => {
  const {
    id,
    title,
    subtitle,
    content_lang_image_references: images = [],
    content_lang_document_references: documents = [],
    content_lang_video_references: videos = [],
    writer_name: writerName,
    writer_email: writerEmail,
    html_content: htmlContent,
    html_title: htmlTitle,
    html_keywords: htmlKeywords,
    html_description: htmlDescription,
    jump_to_url: jumpToUrl,
    friendly_url: friendlyUrl,
    open_in_new_window: openInNewWindow,
  } = contentLang;

  const { t } = useTranslation();
  const processedImages = images.map((image) => ({
    ...image,
    preview: image.src,
  }));

  return (
    <TabPane tabId={id}>
      <Row>
        <Col md={3}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:title')}
            </p>
            <span>{title || '-'}</span>
          </div>
        </Col>
        <Col md={3}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:subtitle')}
            </p>
            <span>{subtitle || '-'}</span>
          </div>
        </Col>
        <Col md={3}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:writerName')}
            </p>
            <span>{writerName || '-'}</span>
          </div>
        </Col>
        <Col md={3}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:writerEmail')}
            </p>
            <span>{writerEmail || '-'}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="content-page__content-html">
            <p>
              {t('contentLang:htmlContent')}
            </p>
            <Editor disabled inline value={htmlContent} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:htmlTitle')}
            </p>
            <span>{htmlTitle || '-'}</span>
          </div>
        </Col>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:htmlKeywords')}
            </p>
            <span>{htmlKeywords || '-'}</span>
          </div>
        </Col>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:htmlDescription')}
            </p>
            <span>{htmlDescription || '-'}</span>
          </div>
        </Col>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:jumpToUrl')}
            </p>
            <span>{jumpToUrl || '-'}</span>
          </div>
        </Col>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:friendlyUrl')}
            </p>
            <span>{friendlyUrl || '-'}</span>
          </div>
        </Col>
        <Col md={2}>
          <div className="content-page__content-field">
            <p>
              {t('contentLang:openInNewWindow')}
            </p>
            <span>{openInNewWindow ? 'Yes' : 'No'}</span>
          </div>
        </Col>
      </Row>
      <Row>
        {images.length > 0 && (
        <Col md={6}>
          <div className="content-page__file-container">
            <p>
              {t('contentLang:pictures')}
            </p>
            <Gallery images={processedImages} />
          </div>
        </Col>
        )}
        {documents.length > 0 && (
        <Col md={6}>
          <div className="content-page__file-container">
            <p>{t('contentLang:documents')}</p>
            {documents.map((document) => (
              <p><a rel="noopener noreferrer" target="_blank" href={document.src}>{document.original_filename}</a></p>
            ))}
          </div>
        </Col>
        )}
      </Row>
      {videos.length > 0 && (
      <Row>
        <Col md={12}>
          <div className="content-page__file-container">
            <p>{t('contentLang:videos')}</p>
            <div className="content-page__video-container">
              {videos.map((video) => (
                <ReactPlayer
                  className="react-player"
                  key={video.id}
                  url={video.src}
                  playIcon={<VideoPlayIcon title={video.original_filename} />}
                  playing
                  controls
                  light
                />
              ))}
            </div>
          </div>
        </Col>
      </Row>
      )}
    </TabPane>
  );
};

ContentLangTab.propTypes = {
  contentLang: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content_lang_image_references: PropTypes.array,
    content_lang_document_references: PropTypes.array,
    content_lang_video_references: PropTypes.array,
    writer_name: PropTypes.string,
    writer_email: PropTypes.string,
    html_content: PropTypes.string,
    html_title: PropTypes.string,
    html_keywords: PropTypes.string,
    html_description: PropTypes.string,
    jump_to_url: PropTypes.string,
    friendly_url: PropTypes.string,
    open_in_new_window: PropTypes.bool,
  }).isRequired,
};

export default ContentLangTab;
