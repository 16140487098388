import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import ContentForm from '../../components/ContentForm';
import { updateContent } from '../../../../redux/actions/cmsContentActions';
import { fetchMenuItemEntry } from '../../../../redux/actions/cmsMenuActions';

const ContentEditForm = ({ handleSubmit, id, menuItemId }) => {
  const dispatch = useDispatch();

  const editContent = (content) => {
    const path = `/api/contents/${id}`;
    const body = JSON.stringify(content);
    return dispatch(updateContent(path, body, true)).then(() => {
      if (menuItemId) {
        dispatch(fetchMenuItemEntry(`/api/menu-items/${menuItemId}`));
      }
    }).catch((err) => {
      throwError(err.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(editContent)}>
      <ContentForm form="edit" />
    </form>
  );
};

ContentEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.number,
  menuItemId: PropTypes.number,
};

ContentEditForm.defaultProps = {
  menuItemId: null,
  id: null,
};

const FormDecoratedComponent = reduxForm({
  form: 'content_edit_form',
  enableReinitialize: true,
})(ContentEditForm);

export default FormDecoratedComponent;
