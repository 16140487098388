export const fieldArrayIndexHelper = (data, clone) => {
  const dataClone = { ...data };

  if (!data.violations) {
    return dataClone;
  }

  for (let i = 0; i < data.violations.length; i += 1) {
    const violation = data.violations[i];
    if (violation.propertyPath.match(/\[[0-9]+\]/g)) {
      const n = violation.propertyPath.indexOf('.');
      const formSection = violation.propertyPath.slice(0, violation.propertyPath.indexOf('['));
      const property = violation.propertyPath.slice(n + 1, violation.propertyPath.length);

      const from = violation.propertyPath.indexOf('[') + 1;
      const to = violation.propertyPath.indexOf(']');
      const index = +violation.propertyPath.slice(from, to) - clone[formSection].length;

      dataClone.violations[i].propertyPath = `${formSection}[${index}].${property}`;
    }
  }

  return dataClone;
};

export default fieldArrayIndexHelper;
