import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TicketTypeEditCard from './components/TicketTypeEditCard';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';

const TicketTypeEdit = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [ticketType, setTicketType] = useState({});
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadTicketType = useCallback(async () => {
    onShowSpinner();
    const path = `/api/ticket-types/${id}`;
    await axios.get(path)
      .then((res) => {
        setTicketType({
          ...res.data,
        });
      }).catch(() => {
        setError(true);
      }).finally(() => {
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadTicketType();
    }
  }, [isAuthenticated, loadTicketType]);

  return (
    <Container>
      <div>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('ticketType:edit')}</h3>
          </Col>
        </Row>
        <Row>
          <TicketTypeEditCard data={ticketType} />
          {error ? <div>{t('common:error')}</div> : ''}
        </Row>
      </div>
    </Container>
  );
};

export default TicketTypeEdit;
