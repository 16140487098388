import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';

const AutocompleteSelect = (props) => {
  const {
    input,
    meta: { form, touched, error },
    disabled,
    options,
    onSetValue,
  } = props;
  const [name, setName] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (input.value === '') {
      setName('');
    }

    if (options !== undefined && options.length > 0) {
      const selectedOption = options
        .filter((option) => option.id.toString() === input.value.toString());
      if (selectedOption.length > 0) {
        setName(selectedOption[0].name);
      }
    }
  }, [input.value, options]);

  const selectOption = (optionName) => {
    const found = options.find((option) => option.name === optionName);
    setName(found.name);
    setShow(false);
    onSetValue({ form, name: input.name, value: found.id });
  };

  const list = options.map((option) => {
    let element = '';
    if (option.name.toLowerCase().includes(name.toLowerCase())) {
      element = (
        <li
          key={option.id}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => selectOption(option.name)}
          aria-hidden="true"
          className="autocomplete__dropdown-item"
        >
          {option.name}
        </li>
      );
    }

    return element;
  });

  const optionList = () => {
    let element = '';

    element = list;

    if (element !== '') {
      element = <ul style={{ display: show ? 'block' : 'none' }} className="autocomplete__dropdown">{element}</ul>;
    }

    return element;
  };

  const setEnteredFilter = (value) => {
    setName(value);
    setShow(true);
    options.forEach((option) => {
      if (option.name === value) {
        onSetValue({ form, name: input.name, value: option.id });
      }
    });
  };

  const press = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    const value = e.target.value.trim();

    if (value === '') {
      e.preventDefault();
      return;
    }

    const found = options.find((option) => {
      const o = option.name.toString().toLowerCase();
      e.preventDefault();
      return o === value.toLowerCase();
    });

    if (typeof found !== 'undefined') {
      onSetValue({ form, name: input.name, value: found.id });
      onSetValue({ form, name: `${input.name}_new`, value: null });
      setShow(false);
      e.preventDefault();
      return;
    }

    onSetValue({ form, name: `${input.name}_new`, value });
    onSetValue({ form, name: input.name, value: null });
    setShow(false);
    e.preventDefault();
  };

  const leaveFocus = () => {
    setShow(false);

    const found = options.find((option) => {
      const o = option.name.toString().toLowerCase();
      return o === name.toLowerCase();
    });

    if (typeof found !== 'undefined') {
      onSetValue({ form, name: input.name, value: found.id });
      onSetValue({ form, name: `${input.name}_new`, value: null });
      setShow(false);
      return;
    }

    onSetValue({ form, name: `${input.name}_new`, value: name });
    onSetValue({ form, name: input.name, value: null });
  };

  return (
    <div className="form__form-group-input-wrap">
      <div
        className="autocomplete"
        aria-hidden="true"
      >
        <div className="autocomplete__input-container">
          <input
            className="autocomplete__input"
            value={name}
            onFocus={() => setShow(true)}
            onBlur={() => leaveFocus()}
            onChange={(event) => setEnteredFilter(event.target.value.trimStart())}
            onKeyDown={(e) => press(e)}
            disabled={disabled}
          />
        </div>
        {optionList()}
      </div>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

AutocompleteSelect.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  onSetValue: PropTypes.func.isRequired,
};

AutocompleteSelect.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = (dispatch) => ({
  onSetValue: (arg) => dispatch(change(arg.form, arg.name, arg.value)),
});

export default connect(null, mapDispatchToProps)(AutocompleteSelect);
