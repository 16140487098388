import React from 'react';
import PropTypes from 'prop-types';

const RenderSelectField = ({
  input, options, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <select
      {...input}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

RenderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
};

RenderSelectField.defaultProps = {
  disabled: false,
  meta: null,
  options: [],
};

export default RenderSelectField;
