import React, { useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getGroupByPermission } from './permissionHelper';
import { setAlert } from '../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import renderToggleButtonField from '../../../shared/components/form/ToggleButton';
import BackButton from '../../../shared/components/form/BackButton';

const PermissionForm = ({ handleSubmit, permissions, permissionGroups }) => {
  const { id } = useParams();
  const form = useSelector((state) => state.form.permission_settings_form);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const getSaveFormat = (slug) => ({
    user: id,
    permission: permissions.find((permission) => permission.name === slug).id,
  });

  const editPermissions = (async (formData) => {
    onShowSpinner();

    const clone = Object.entries(formData)
      .filter((item) => {
        const [slug, value] = item;

        const isGroup = permissionGroups.find((permissionGroup) => permissionGroup.slug === slug);
        const isGroupEnabled = !isGroup
          ? formData[getGroupByPermission(slug, permissionGroups).slug]
          : false;

        return !isGroup && value === true && isGroupEnabled;
      })
      .map((item) => getSaveFormat(item[0]));

    const path = `/api/users/permission/${id}`;
    const body = JSON.stringify({ permissions: clone });

    await axios.put(path, body)
      .then(() => {
        dispatch(setAlert(t('common:saveSuccess'), 'success'));
        window.location.replace('/accounts');
      }).catch(() => {
        dispatch(setAlert(t('common:saveError'), 'danger'));
      });

    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(editPermissions)}>
      <div className="form__full">
        <div className="card__title">
          <h4 className="bold-text">{t('permission:title')}</h4>
        </div>
        <div className="form-row">
          {permissionGroups.map((permissionGroup) => (
            <Col md={4} key={permissionGroup.id}>
              <div className="permission__section">
                <div className="permission__section-row">
                  <span className="permission__label">{t(`permission:group:${permissionGroup.slug}`)}</span>
                  <Field
                    name={permissionGroup.slug}
                    defaultChecked={Object.keys(form.initial).includes(permissionGroup.slug)}
                    component={renderToggleButtonField}
                  />
                </div>
                {form?.values[permissionGroup.slug] && permissionGroup.permission
                  .map((permission) => (
                    <div className="permission__section-row permission__section-row--sub" key={permission.id}>
                      <span className="permission__label">{t(`permission:item:${permission.slug}`)}</span>
                      <Field
                        name={permission.slug}
                        defaultChecked={Object.keys(form.initial).includes(permission.slug)}
                        component={renderToggleButtonField}
                      />
                    </div>
                  ))}
              </div>
            </Col>
          ))}
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <button className="btn btn-success" type="submit">{t('common:buttons:save')}</button>
        <BackButton isCancel path="/accounts" />
      </ButtonToolbar>
    </form>
  );
};

PermissionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  permissionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      permission: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
      ),
    }),
  ),
};

PermissionForm.defaultProps = {
  permissions: [],
  permissionGroups: [],
};

const FormDecoratedComponent = reduxForm({
  form: 'permission_settings_form',
  enableReinitialize: true,
})(PermissionForm);

export default FormDecoratedComponent;
