const getFrontendRole = (roleKey) => {
  switch (roleKey) {
    case 'ROLE_ADMIN': return 'Admin';
    case 'ROLE_CUSTOMER': return 'Customer';
    default: return '';
  }
};

const getHomePage = (roleKey) => {
  switch (roleKey) {
    case 'ROLE_ADMIN': return 'bookings';
    default: return '';
  }
};

const getRoleOptions = (except = []) => {
  const options = [
    { value: '', label: 'Please select ...' },
    { value: 'ROLE_ADMIN', label: 'Admin' },
    { value: 'ROLE_CUSTOMER', label: 'Customer' },
  ];

  return options.filter((option) => !except.includes(option.value));
};

export {
  getFrontendRole, getHomePage, getRoleOptions,
};
