import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import MenuItemForm from '../../components/MenuItemForm';
import { createMenuEntry } from '../../../../redux/actions/cmsMenuActions';
import throwError from '../../../../shared/utility/errorHandler';

const MenuItemCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { id: menuId } = useParams();

  const saveMenuItem = (menuItem) => {
    const clone = { ...menuItem };
    const path = '/api/menu-items';
    clone.menuId = menuId;
    const body = JSON.stringify(clone);
    return dispatch(createMenuEntry(path, body, true)).catch((error) => {
      throwError(error.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveMenuItem)}>
      <MenuItemForm form="create" />
    </form>
  );
};

MenuItemCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'menu_item_create_form' })(MenuItemCreateForm);
