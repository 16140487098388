import i18next from 'i18next';
import { setAlert } from './alertActions';
import { fetchHelper } from '../helpers/cmsHelper';
import { setStatus } from './statusActions';

export const SET_STATUS_CODE = 'SET_STATUS_CODE';
export const SET_CONTENTS = 'SET_CONTENTS';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_SINGLE_CONTENT = 'SET_SINGLE_CONTENT';
export const SET_CONTENT_COUNT = 'SET_CONTENT_COUNT';

export const setStatusCode = (payload) => ({
  type: SET_STATUS_CODE,
  payload,
});

export const setContents = (payload) => ({
  type: SET_CONTENTS,
  payload,
});

export const setLanguages = (payload) => ({
  type: SET_LANGUAGES,
  payload,
});

export const setContentCount = (payload) => ({
  type: SET_CONTENT_COUNT,
  payload,
});

export const setSingleContent = (payload) => ({
  type: SET_SINGLE_CONTENT,
  payload,
});

export const fetchContents = (path, withSpinner = true) => (dispatch) => fetchHelper({
  dispatch,
  path,
  withSpinner,
}).then((res) => {
  dispatch(setStatusCode(res.status));
  dispatch(setContents(res.data.list));
  dispatch(setContentCount(res.data.totalCount));
}).catch((err) => {
  dispatch(setStatusCode(err.response.status));
});

export const fetchContentsDirectly = (
  path,
  options,
  withSpinner = true,
) => (dispatch) => fetchHelper({
  dispatch,
  path,
  options,
  withSpinner,
});

export const fetchSingleContent = (path) => (dispatch) => fetchHelper({
  dispatch,
  path,
  withSpinner: true,
}).then((res) => {
  dispatch(setSingleContent(res.data));
  dispatch(setStatus(res.status));
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:error'), 'danger'));
  dispatch(setStatus(err.response?.status));
});

export const fetchLanguageList = (path, withSpinner = true) => (dispatch) => fetchHelper({
  dispatch,
  path,
  withSpinner,
}).then((res) => {
  dispatch(setLanguages(res.data));
}).catch(() => {
  dispatch(setAlert(i18next.t('common:error'), 'danger'));
});

export const deleteContent = (path) => (dispatch) => fetchHelper({
  dispatch,
  path,
  method: 'DELETE',
  withSpinner: true,
}).then(() => {
  dispatch(fetchContents(`/api/contents${window.location.search}`));
});

export const deleteContentLang = (path) => (dispatch) => fetchHelper({
  dispatch,
  path,
  method: 'DELETE',
  withSpinner: true,
});

export const updateContent = (
  path, body, errorChaining = false,
) => (dispatch) => fetchHelper({
  dispatch,
  path,
  body,
  method: 'PUT',
  withSpinner: true,
}).then(() => {
  dispatch(setAlert(i18next.t('common:editSuccess'), 'success'));
  dispatch(fetchContents(`/api/contents${window.location.search}`));
  window.history.back();
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:editError'), 'danger'));
  if (errorChaining) throw err;
});

export const createContent = (
  path, body, options, errorChaining = false,
) => async (dispatch) => fetchHelper({
  dispatch,
  path,
  body,
  options,
  method: 'POST',
  withSpinner: true,
}).then(() => {
  dispatch(setAlert(i18next.t('common:saveSuccess'), 'success'));
  window.history.back();
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:saveError'), 'danger'));
  if (errorChaining) throw err;
});

export const addContentLangReference = (
  path, body, options, errorChaining = false,
) => (dispatch) => fetchHelper({
  dispatch,
  path,
  body,
  options,
  method: 'POST',
  withSpinner: false,
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:editError'), 'danger'));
  if (errorChaining) throw err;
});

export const removeContentLangReference = (
  path, errorChaining = false,
) => (dispatch) => fetchHelper({
  dispatch,
  path,
  method: 'DELETE',
  withSpinner: false,
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:editError'), 'danger'));
  if (errorChaining) throw err;
});

export const reorderContentLangReference = (
  path, body, errorChaining = false,
) => (dispatch) => fetchHelper({
  dispatch,
  path,
  body,
  method: 'POST',
  withSpinner: false,
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:editError'), 'danger'));
  if (errorChaining) throw err;
});
