import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import convertToURL from '../../../shared/utility/filter';
import renderDatePickerField from '../../../shared/components/form/DatePicker';

const PlaylistFilterForm = (props) => {
  const { handleSubmit, refreshCallback } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const sendFilter = async (filter) => {
    const copy = { ...filter };
    const filterString = convertToURL(copy);
    history.push(`${window.location.pathname}?${filterString}`);
    refreshCallback();
  };

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={12}>
        <Row>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('playlist:name')}</span>
              <div className="form__form-group-field">
                <Field name="name" component={RenderField} type="text" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('playlist:validFrom')}</span>
              <div className="form__form-group-field">
                <Field
                  name="valid_from"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('playlist:validTo')}</span>
              <div className="form__form-group-field">
                <Field
                  name="valid_to"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="line-end">
        <button className="btn btn-success" type="submit">
          {t('common:filter')}
        </button>
      </Col>
    </form>
  );
};

PlaylistFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'playlist_filter',
  enableReinitialize: true,
})(PlaylistFilterForm);

export default FormDecoratedComponent;
