import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import Can from '../../shared/authorization/Can';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import DeviceFilterCard from './Filter/DeviceFilterCard';
import PageHandler from '../../shared/components/PageHandler';

const DeviceList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/devices${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          last_sign_of_life: row.last_sign_of_life && format(new Date(row.last_sign_of_life), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'name', label: t('device:name'),
    },
    {
      id: 'description', label: t('device:description'),
    },
    {
      id: 'vpn_ip', label: t('device:vpnIp'),
    },
    {
      id: 'ap_number', label: t('device:apNumber'),
    },
    {
      id: 'last_sign_of_life', label: t('device:lastSignOfLife'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('device:list')}</h3>
          </Col>
          <Col md={4}>
            <Can
              perform="devices:create"
              yes={() => (
                <Link
                  className="btn btn-success float-right"
                  to={`${window.location.pathname}/create`}
                >
                  {t('device:create')}
                </Link>
              )}
            />
          </Col>
        </Row>
        <Row><DeviceFilterCard refreshCallback={fetchData} /></Row>
        <Row>
          <Can
            perform="devices:show"
            yes={() => { operations.push('show'); return null; }}
          />
          <Can
            perform="devices:edit"
            yes={() => {
              operations.push('edit');
              return null;
            }}
          />
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default DeviceList;
