import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ProductionEditCard from './components/ProductionEditCard';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import PageHandler from '../../../shared/components/PageHandler';

const ProductionEdit = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [production, setProduction] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadProduction = useCallback(async () => {
    onShowSpinner();
    const path = `/api/productions/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setProduction(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadProduction();
    }
  }, [isAuthenticated, loadProduction]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="production-edit">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('production:edit')}</h3>
            </Col>
          </Row>
          <Row>
            <ProductionEditCard data={production} />
          </Row>
        </div>
      </Container>
    </PageHandler>
  );
};

export default ProductionEdit;
