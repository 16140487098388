import React from 'react';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AddMenuCard = () => {
  const { t } = useTranslation();

  return (
    <div className="add-menu-card">
      <div className="add-menu-card__body">
        <Tooltip className="add-menu-card__button" title={t('common:actions:addNew')} placement="bottom" arrow>
          <Link to={`${window.location.pathname}/create-menu`}><PlusCircleOutlineIcon size={80} /></Link>
        </Tooltip>
      </div>
    </div>
  );
};

export default AddMenuCard;
