import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuCard from './MenuCard';
import AddMenuCard from './AddMenuCard';
import ConfirmPopup from '../../../shared/components/ConfirmPopup';
import { deleteMenuEntry, fetchMenupoints } from '../../../redux/actions/cmsMenuActions';

const OrganizationContainer = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  const [deletePath, setDeletePath] = useState('');
  const { menupoints: menus = [] } = useSelector((state) => state.cms.menu);

  const deleteItem = (path, item) => {
    setItemToDelete(item);
    setDeletePath(path);
    setIsModalOpen(true);
  };

  const onDeleteCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteConfirm = () => {
    dispatch(deleteMenuEntry(deletePath));
    dispatch(fetchMenupoints());
    setIsModalOpen(false);
  };

  const menuCards = menus.sort((a, b) => (
    a.display_order - b.display_order
  )).map((menu, index) => (
    <MenuCard
      index={index}
      menu={menu}
      deleteItem={deleteItem}
      key={menu.id}
    />
  ));

  return (
    <>
      <ConfirmPopup
        isOpen={isModalOpen}
        item={itemToDelete}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
      <div className="menu-org-container">
        {menus.length > 0 && (
        <>
          {menuCards}
          <AddMenuCard />
        </>
        )}
      </div>
    </>
  );
};

export default OrganizationContainer;
