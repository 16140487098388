import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import UploadIcon from 'mdi-react/UploadIcon';
import DropZoneFileList from './DropZoneFileList';

const getErrorMessage = (error) => {
  switch (error.code) {
    case 'file-too-large':
      return 'A fájl nagyobb, mint 20MB';
    case 'file-invalid-type':
      return 'A fájl típusa nem megfelelő';
    default:
      return error.message;
  }
};

const DropZoneMultipleField = (props) => {
  const [error, setError] = useState(null);
  const {
    name, value, accept, onChange, actions, number,
  } = props;
  const files = value;

  const onDrop = (datas) => {
    let acceptedFiles;
    if (name === 'images' && datas.length > number) {
      setError(`Maximálisan feltölthető képek száma: ${number}`);
      acceptedFiles = datas.slice(0, number);
    } else {
      setError(null);
      acceptedFiles = datas;
    }

    onChange(acceptedFiles.map((data, index) => Object.assign(data, {
      id: data.id ? data.id : `${uuidv4()}-file`,
      preview: data.id ? data.preview : URL.createObjectURL(data),
      order_number: data.order_number ? data.order_number : index,
    })));
  };

  const removeFile = (index, e, file) => {
    setError(null);
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
    if (typeof file.id !== 'undefined' && typeof actions.remove !== 'undefined') { actions.remove(file.id); }
  };

  const moveFile = (dragIndex, hoverIndex) => {
    const [removed] = value.splice(dragIndex, 1);
    value.splice(hoverIndex, 0, removed);
    onChange(value.map((file, index) => Object.assign(file, {
      order_number: index,
    })));
  };

  const actionCallbacks = {
    remove: removeFile,
    move: moveFile,
  };

  const dropzoneOptions = {
    className: 'dropzone__input',
    accept,
    name,
    onDrop: ((filesToUpload) => onDrop(value ? value.concat(filesToUpload) : filesToUpload)),
    maxSize: 20971520,
  };
  const {
    getRootProps, getInputProps, fileRejections,
  } = useDropzone(dropzoneOptions);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={uuidv4()} className="list-group-item list-group-item-danger">
      {file.path}
      <ul>
        {errors.map((err) => (
          <li key={uuidv4()}>{getErrorMessage(err)}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <div className="dropzone dropzone--multiple">
        <div {...getRootProps()} className="dropzone__input">
          {(!files || files.length === 0)
            && (
              <div className="dropzone__drop-here">
                <UploadIcon />
                {' '}
                Dobjon ide fájlokat vagy kattintson
              </div>
            )}
          <input {...getInputProps()} />
        </div>
        <DropZoneFileList files={files} actions={actionCallbacks} />
      </div>
      {fileRejections.length !== 0 && (
        <ul className="list-group">{fileRejectionItems}</ul>
      )}
      {error && <span className="form__form-group-error">{error}</span>}
    </>
  );
};

const renderDropZoneMultipleField = (props) => {
  const {
    input, accept, meta: { touched, error }, actions, number,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <DropZoneMultipleField
        {...input}
        accept={accept}
        actions={actions}
        number={number}
      />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

DropZoneMultipleField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  ]),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  number: PropTypes.number.isRequired,
};

DropZoneMultipleField.defaultProps = {
  value: null,
};

renderDropZoneMultipleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  accept: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  actions: PropTypes.objectOf(PropTypes.func),
  number: PropTypes.number,
};

renderDropZoneMultipleField.defaultProps = {
  actions: {},
  number: 5,
};

export default renderDropZoneMultipleField;
