import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const DatePickerField = ({
  value, name, dateFormat, onChange, showTimeSelect, showMonthDropdown, showYearDropdown, disabled,
  yearDropdownItemNumber, scrollableYearDropdown, timeFormat, timeIntervals, minDate, maxDate,
}) => {
  const [dateStringFormat, setDateStringFormat] = useState(dateFormat);

  const handleChange = (date) => {
    if (date !== null) {
      const dateString = format(new Date(date), dateStringFormat);
      onChange(dateString);
    } else {
      onChange(date);
    }
  };

  useEffect(() => {
    if (showTimeSelect === true) {
      setDateStringFormat(`${dateFormat} ${timeFormat}`);
    }
  }, []);

  return (
    <div className="date-picker">
      <DatePicker
        locale="en"
        name={name}
        className="form__form-group-datepicker"
        selected={(value === '' || value === '1970-01-01') ? null : new Date(value)}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown={scrollableYearDropdown}
        dateFormat={dateStringFormat}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        dropDownMode="select"
        isClearable={disabled !== true}
        autoComplete="off"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

const renderDatePickerField = ({
  input, meta, dateFormat, showTimeSelect, showMonthDropdown, showYearDropdown, disabled,
  yearDropdownItemNumber, scrollableYearDropdown, timeFormat, timeIntervals, minDate, maxDate,
}) => (
  <div className="form__form-group-input-wrap">
    <DatePickerField
      {...input}
      dateFormat={dateFormat}
      showTimeSelect={showTimeSelect}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      yearDropdownItemNumber={yearDropdownItemNumber}
      scrollableYearDropdown={scrollableYearDropdown}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
    />
    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
  </div>
);

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  showTimeSelect: PropTypes.bool.isRequired,
  showMonthDropdown: PropTypes.bool.isRequired,
  showYearDropdown: PropTypes.bool.isRequired,
  yearDropdownItemNumber: PropTypes.number.isRequired,
  scrollableYearDropdown: PropTypes.bool.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeIntervals: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

DatePickerField.defaultProps = {
  value: null,
  disabled: false,
  minDate: null,
  maxDate: null,
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  yearDropdownItemNumber: PropTypes.number,
  scrollableYearDropdown: PropTypes.bool,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

renderDatePickerField.defaultProps = {
  meta: null,
  dateFormat: 'yyyy-MM-dd',
  showTimeSelect: false,
  showMonthDropdown: false,
  showYearDropdown: false,
  yearDropdownItemNumber: 100,
  scrollableYearDropdown: true,
  timeFormat: 'HH:mm',
  timeIntervals: 60,
  disabled: false,
  minDate: null,
  maxDate: null,
};

export default renderDatePickerField;
