import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import AppSettingsEditForm from './AppSettingsEditForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    delete clone.id;
    delete clone.slug;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.created_by;
    return clone;
  }
  return object;
};

const AppSettingsEditCard = ({ data }) => {
  const { t } = useTranslation();
  const optimizeData = optimize(data);

  return (
    <>
      <Col md={12}>
        <h3 className="page-title">{t('settings:edit')}</h3>
      </Col>
      <Col md={6} lg={4}>
        <Card>
          <CardBody>
            <AppSettingsEditForm initialValues={optimizeData} onSubmit />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

AppSettingsEditCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default AppSettingsEditCard;
