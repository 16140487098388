import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const DeviceCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="device-card">
            <Row>
              <Col md={12} lg={12}>
                <div className="device-card__info">
                  <Row>
                    <Col md={2}>
                      <p>{t('device:name')}</p>
                      <span>{data.name}</span>
                    </Col>
                    <Col md={2}>
                      <p>{t('device:description')}</p>
                      <span>{data.description}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('device:vpnIp')}</p>
                      <span>{data.vpn_ip}</span>
                    </Col>
                    <Col md={2}>
                      <p>{t('device:apNumber')}</p>
                      <span>{data.ap_number}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('device:lastSignOfLife')}</p>
                      <span>{data.last_sign_of_life}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

DeviceCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    vpn_ip: PropTypes.string,
    ap_number: PropTypes.string,
    last_sign_of_life: PropTypes.string,
  }).isRequired,
};

export default DeviceCard;
