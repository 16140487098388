import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import RepeatIcon from 'mdi-react/RepeatIcon';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { resetPassword } from '../../../../redux/actions/authActions';
import RenderField from '../../../../shared/components/form/RenderField';

class ResetForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const {
      handleSubmit, isAuthenticated, resetting, t,
    } = this.props;
    const { showPassword } = this.state;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit(resetting)}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('newPassword')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={RenderField}
              type={showPassword ? 'text' : 'password'}
              placeholder={t('newPassword')}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={(e) => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('confirmPassword')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <RepeatIcon />
            </div>
            <Field
              name="retyped_password"
              component={RenderField}
              type="password"
              placeholder={t('confirmPassword')}
            />
          </div>
        </div>
        <div className="account__btns">
          <button className="btn btn-success account__btn" type="submit">{t('submit')}</button>
          <Link className="btn btn-outline-success account__btn" to="/login">{t('login')}</Link>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  resetting: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { resetting: resetPassword })(reduxForm({
  form: 'reset_form',
})(withTranslation('reset')(ResetForm)));
