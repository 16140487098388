import React from 'react';
import LogInForm from './components/LogInForm';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <div className="account__logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <LogInForm onSubmit />
      </div>
    </div>
  </div>
);

export default LogIn;
