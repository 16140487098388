import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { fetchLanguageList, fetchSingleContent } from '../../../../redux/actions/cmsContentActions';
import { useAuthCheck } from '../../../../shared/utility/APIUtils';
import useAvailableContentLangs from '../../../../shared/utility/useAvailableContentLangs';
import ContentLangCreateForm from './ContentLangCreateForm';

const ContentLangCreateCard = () => {
  const isAuthenticated = useAuthCheck();
  const { contentId } = useParams();
  const availableLangs = useAvailableContentLangs(contentId);
  const defaultLanguage = availableLangs?.length ? { language: availableLangs[0].id } : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated === true) {
      const path = `/api/contents/${contentId}`;
      dispatch(fetchSingleContent(path));
      dispatch(fetchLanguageList('/api/languages'));
    }
  }, [contentId, dispatch, isAuthenticated]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ContentLangCreateForm initialValues={defaultLanguage} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ContentLangCreateCard;
