import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';

const PaymentList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const operations = ['show', 'booking'];
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/payments${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list);
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'bookingReference', label: t('payment:bookingReference'),
    },
    {
      id: 'decision', label: t('payment:decision'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('payment:list')}</h3>
          </Col>
        </Row>
        <Row>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            dependencyCells={['bookingId']}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default PaymentList;
