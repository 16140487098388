import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import LocationMain from './components/LocationMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const LocationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [location, setLocation] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const loadLocation = useCallback(async () => {
    const path = `/api/locations/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setLocation({
          ...res.data,
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadLocation();
    }
  }, [isAuthenticated, loadLocation]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="location-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('view')}</h3>
            </Col>
          </Row>
          <Row>
            <LocationMain data={location} />
          </Row>
        </div>
        <BackButton path="/locations" />
      </Container>
    </PageHandler>
  );
};

export default LocationPage;
