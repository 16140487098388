import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import DeleteIcon from 'mdi-react/DeleteIcon';
import DocumentIcon from './DocumentIcon';

const type = 'File';

const File = ({ file, index, actions }) => {
  const ref = useRef(null);

  const onDrop = (item) => {
    if (!ref.current) return;

    const clone = { ...item };
    const dragIndex = clone.index;
    const hoverIndex = index;

    if (dragIndex === hoverIndex) return;

    actions.move(dragIndex, hoverIndex);
    clone.index = hoverIndex;
  };

  const [, drop] = useDrop({
    accept: type,
    drop: onDrop,
    canDrop: (item) => item.id !== file.id,
    collect: (monitor) => ({
      dropHover: monitor.isOver() && monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { id: file.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  drag(drop(ref));

  return (
    <div className="dropzone__img" ref={ref} key={uuidv4()} style={{ backgroundImage: `url(${file.preview})`, opacity: isDragging && 0.2 }}>
      <DocumentIcon file={file} className="dropzone__icon" />
      <p className="dropzone__img-name">{file.name || file.original_filename}</p>
      <button className="dropzone__img-delete" type="button" onClick={(e) => actions.remove(index, e, file)}>
        <DeleteIcon />
      </button>
    </div>
  );
};

const DropZoneFileList = ({ files, actions }) => {
  const renderFile = (file, index) => (file ? (
    <File
      file={file}
      key={`${uuidv4()}-file`}
      index={index}
      actions={actions}
    />
  ) : null);

  return <div className="dropzone__imgs-wrapper">{files !== null && files.map(renderFile)}</div>;
};

File.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    original_filename: PropTypes.string,
    preview: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

DropZoneFileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.objectOf(PropTypes.func),
};

DropZoneFileList.defaultProps = {
  files: null,
  actions: {},
};

export default DropZoneFileList;
