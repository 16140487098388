import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import ReactJson from 'react-json-view';

const PaymentMain = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="payment__main">
            <div className="card__title">
              <h5 className="bold-text">{t('common:details')}</h5>
            </div>
            <hr />
            <div className="payment__info">
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('payment:transactionId')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.transaction_id}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('payment:decision')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.decision}</p>
                </Col>
              </Row>
              {data.booking && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('payment:bookingReference')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <Link className="" to={`/bookings/show/${data.booking.id}`} target="_blank">
                    <h5 className="bold-text">{data.booking.bookingReference}</h5>
                  </Link>
                </Col>
              </Row>
              )}
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('payment:captureStatus')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.capture_status}</p>
                </Col>
              </Row>
              {data.cybersource_response && (
                <>
                  <Row>
                    <Col md={12}>
                      <p>{t('payment:cybersourceResponse')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="card__newline colored-text">{data.cybersource_response}</div>
                    </Col>
                  </Row>
                </>
              )}
              {data.cybersource_request && (
                <>
                  <Row>
                    <Col md={12}>
                      <p>{t('payment:cybersourceRequest')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ReactJson
                        src={data.cybersource_request}
                        name={false}
                        collapseStringsAfterLength={100}
                        displayDataTypes={false}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {data.cybersource_capture_response && (
                <>
                  <Row>
                    <Col md={12}>
                      <p>{t('payment:cybersourceCaptureResponse')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ReactJson
                        src={data.cybersource_capture_response}
                        name={false}
                        collapseStringsAfterLength={100}
                        displayDataTypes={false}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {data.cybersource_capture_request && (
                <>
                  <Row>
                    <Col md={12}>
                      <p>{t('payment:cybersourceCaptureRequest')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <ReactJson
                        src={data.cybersource_capture_request}
                        name={false}
                        collapseStringsAfterLength={100}
                        displayDataTypes={false}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

PaymentMain.propTypes = {
  data: PropTypes.shape({
    transaction_id: PropTypes.string,
    decision: PropTypes.string,
    cybersource_request: PropTypes.shape(),
    cybersource_response: PropTypes.string,
    cybersource_capture_request: PropTypes.shape(),
    cybersource_capture_response: PropTypes.shape(),
    capture_status: PropTypes.string,
    booking: PropTypes.shape(),
  }).isRequired,
};

export default PaymentMain;
