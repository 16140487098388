import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { fetchLanguageList } from '../../../../redux/actions/cmsContentActions';
import { useAuthCheck } from '../../../../shared/utility/APIUtils';
import MenuItemLangCreateForm from './MenuItemLangCreateForm';
import useAvailableMenuItemLangs from '../../../../shared/utility/useAvailableMenuItemLangs';
import { fetchMenuItemList } from '../../../../redux/actions/cmsMenuActions';

const MenuItemLangCreateCard = () => {
  const isAuthenticated = useAuthCheck();
  const { menuItemId } = useParams();
  const availableLangs = useAvailableMenuItemLangs(menuItemId);
  const defaultLanguage = availableLangs?.length ? { language: availableLangs[0].id } : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(fetchLanguageList('/api/languages'));
      dispatch(fetchMenuItemList('/api/menu-items'));
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <MenuItemLangCreateForm initialValues={defaultLanguage} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

export default MenuItemLangCreateCard;
