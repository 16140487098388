import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import ProductionForm from '../../components/ProductionForm';
import { getLocationList } from '../../../../redux/actions/productionActions';

const ProductionCreateForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  useEffect(() => {
    dispatch(getLocationList());
  }, [dispatch]);

  const saveProduction = async (production) => {
    onShowSpinner();
    const { images = [] } = production;
    const path = '/api/productions';
    const formData = new FormData();
    formData.append('jsonObject', JSON.stringify(production));
    if (images.length) {
      images.forEach((image) => formData.append('images[]', image));
    }

    try {
      await axios.post(path, formData, { headers: { 'Content-type': 'multipart/form-data' } });
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/productions');
    } catch (err) {
      onHideSpinner();
      dispatch(setAlert(t('common:saveError'), 'danger'));
      throwError(err.response.data);
    }
    onHideSpinner();
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveProduction)}>
      <ProductionForm form="create" />
    </form>
  );
};

ProductionCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'production_create_form' })(ProductionCreateForm);
