import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import ProductionEditForm from './ProductionEditForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    clone.images = clone.production_references
    && clone.production_references.map((ref) => ({ ...ref, preview: ref.src }));
    clone.location_id = clone.location && clone.location.id;
    delete clone.location;
    delete clone.id;
    delete clone.production_references;
    return clone;
  }
  return object;
};

const ProductionEditCard = ({ data }) => {
  const optimizeData = optimize(data);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ProductionEditForm initialValues={optimizeData} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

ProductionEditCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ProductionEditCard;
