import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { datetimeToString } from '../../../../shared/utility/formatter';

const BookingMain = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="booking__main">
            <div className="card__title">
              <h5 className="bold-text">{data.partner_booking_ref}</h5>
            </div>
            <hr />
            <div className="booking__info">
              <Row>
                <Col md={12}>
                  <p>
                    {data.product_title}
                    (
                    {data.product_code}
                    )
                  </p>
                </Col>
              </Row>
              {data.product_option_code && (
              <Row>
                <Col md={12}>
                  <p>
                    {data.product_option_title}
                    (
                    {data.product_option_code}
                    )
                  </p>
                </Col>
              </Row>
              )}
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('booking:travelDate')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.travel_date}</p>
                </Col>
              </Row>
              {data.start_time && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('booking:startTime')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.start_time}</p>
                </Col>
              </Row>
              )}
              <hr />
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('booking:status')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.status}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('booking:channel')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.channel}</p>
                </Col>
              </Row>
              {data.created_at && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('common:createdAt')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{datetimeToString(data.created_at)}</p>
                </Col>
              </Row>
              )}
              {data.updated_at && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('common:updatedAt')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{datetimeToString(data.updated_at)}</p>
                </Col>
              </Row>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

BookingMain.propTypes = {
  data: PropTypes.shape({
    partner_booking_ref: PropTypes.string,
    status: PropTypes.string,
    product_title: PropTypes.string,
    product_code: PropTypes.string,
    product_option_title: PropTypes.string,
    product_option_code: PropTypes.string,
    travel_date: PropTypes.string,
    start_time: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    channel: PropTypes.string,
  }).isRequired,
};

export default BookingMain;
