import React, { useRef } from 'react';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItemCard';
import AddMenuItem from './AddMenuItem';
import itemType from '../../../shared/utility/dndItemTypeEnum';
import { arrangeMenuCards, fetchMenupoints, syncMenuEntry } from '../../../redux/actions/cmsMenuActions';

const MenuCard = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { t } = useTranslation();
  const {
    deleteItem, menu: {
      name, id, menuItems = [],
    },
  } = props;
  const { MENU, MENU_ITEM } = itemType;

  const onDrop = (item, monitor) => {
    const type = monitor.getItemType();
    if (type === MENU) {
      const ownId = id;
      const hoveringId = item.id;
      dispatch(arrangeMenuCards(hoveringId, ownId));
    } else if (type === MENU_ITEM) {
      const path = `/api/menu-items/${item.id}`;
      const body = {
        menuId: id,
      };
      dispatch(syncMenuEntry(path, body)).then(() => {
        dispatch(fetchMenupoints());
      });
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: MENU,
    item: {
      id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ dropHover }, drop] = useDrop(() => ({
    accept: [MENU, MENU_ITEM],
    drop: onDrop,
    canDrop: (item, monitor) => {
      const type = monitor.getItemType();
      const externalMenuItem = type === MENU_ITEM && item.menuId !== id;
      const otherMenu = type === MENU && item.id !== id;
      return externalMenuItem || otherMenu;
    },
    collect: (monitor) => ({
      dropHover: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  const menuItemCards = menuItems.sort((a, b) => (
    a.display_order - b.display_order
  )).map((menuItem) => (
    <MenuItem menuId={id} deleteItem={deleteItem} menuItem={menuItem} key={menuItem.id} />
  ));

  const handleDeleteClick = () => {
    deleteItem(`api/menus/${id}`, name);
  };

  drag(drop(ref));

  return (
    <div className="menu-card" id={dropHover ? 'drop-hover' : undefined} style={{ opacity: isDragging && 0.2 }} ref={ref}>
      <div className="menu-card__header">
        <div className="menu-card__title">
          {name}
        </div>
        <div className="menu-card__icon-bar">
          <Tooltip className="menu-card__button" title={t('common:actions:view')} placement="bottom" arrow>
            <Link to={`${window.location.pathname}/view-menu/${id}`}><EyeIcon size={25} /></Link>
          </Tooltip>
          <Tooltip className="menu-card__button" title={t('common:actions:edit')} placement="bottom" arrow>
            <Link to={`${window.location.pathname}/edit-menu/${id}`}><EditIcon size={25} /></Link>
          </Tooltip>
          <Tooltip className="menu-card__button" title={t('common:actions:delete')} placement="bottom" arrow>
            <Link to={`${window.location.pathname}`} onClick={handleDeleteClick}><DeleteIcon size={25} /></Link>
          </Tooltip>
        </div>
      </div>
      <div className="menu-card__body">
        {menuItemCards}
        <AddMenuItem menuId={id} />
      </div>
    </div>
  );
};

MenuCard.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  menu: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    menuItems: PropTypes.array,
  }).isRequired,
};

export default MenuCard;
