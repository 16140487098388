import React from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import Gallery from '../../../../shared/components/Gallery';

const ProductionMain = ({ data }) => {
  const { t } = useTranslation('production');
  const { production_references: images = [] } = data;
  const processedImages = images.map((image) => ({
    ...image,
    preview: image.src,
  }));

  return (
    <>
      <Col md={12} lg={6} xl={6}>
        <Card>
          <CardBody className="production__main">
            <h4>{data.name}</h4>
            <div className="production__short-description">
              <Editor disabled inline value={data.short_description} />
            </div>
            <div className="production__long-description">
              <Editor disabled inline value={data.long_description} />
            </div>
            <p>
              {t('contributors')}
              {': '}
              {data.contributors}
            </p>
            <p>
              {t('category')}
              {': '}
              {data.category}
            </p>
            <p>
              {t('location')}
              {': '}
              {data.location ? data.location.name : ' - '}
            </p>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={6} xl={6}>
        <Gallery images={processedImages} />
      </Col>
    </>
  );
};

ProductionMain.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    short_description: PropTypes.string,
    long_description: PropTypes.string,
    contributors: PropTypes.string,
    category: PropTypes.string,
    production_references: PropTypes.array,
    location: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default ProductionMain;
