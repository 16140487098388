import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  Table, TableBody, TableCell, TableContainer, TableRow, TableHead,
} from '@material-ui/core';

const TransactionDetailsCard = ({ transaction, tickets }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [transactionData, setTransactionData] = useState(null);
  const [ticketRows, setTicketRows] = useState([]);

  const initData = useCallback((dataForTransaction, dataForTickets) => {
    setTransactionData(dataForTransaction);
    setTicketRows(dataForTickets);
  }, []);

  useEffect(() => {
    initData(transaction, tickets);
    setLoading(false);
  }, [transaction, tickets, initData]);

  const ticketColumns = [
    {
      id: 'ticket_type', label: t('transaction:ticketType'),
    },
    {
      id: 'discount', label: t('transaction:discount'),
    },
    {
      id: 'price', label: t('transaction:price'),
    },
    {
      id: 'quantity', label: t('transaction:quantity'),
    },
    {
      id: 'description', label: t('transaction:description'),
    },
  ];
  const paymentColumns = [
    {
      id: 'payment_type', label: t('transaction:paymentType'),
    },
    {
      id: 'value', label: t('transaction:value'),
    },
    {
      id: 'paid_amount', label: t('transaction:paidAmount'),
    },
    {
      id: 'changedue', label: t('transaction:changedue'),
    },
  ];
  return (
    <Container>
      {!loading && ticketRows.length > 0 && transactionData !== null && (
        <Row>
          <Col md={12}>
            <Row className="transaction__row">
              <Col>
                <p>{t('transaction:customerName')}</p>
                <span>{`${transactionData.first_name} ${transactionData.last_name}`}</span>
              </Col>
              <Col>
                <p>{t('transaction:customerEmail')}</p>
                <span>{transactionData.customer_email}</span>
              </Col>
              <Col>
                <p>{t('transaction:customerPhoneNumber')}</p>
                <span>{transactionData.customer_phone_number}</span>
              </Col>
            </Row>
            <TableContainer>
              <Table className="transaction-table">
                <TableHead>
                  <TableRow>
                    {ticketColumns.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketRows.map((row) => (
                    <TableRow key={row.id}>
                      {ticketColumns.map((cell) => (
                        <TableCell key={`${cell.id}-${row.id}`}>
                          {row[cell.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table className="transaction-table">
                <TableHead>
                  <TableRow>
                    {paymentColumns.map((cell) => (
                      <TableCell key={cell.id}>{cell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{transactionData.payment_type}</TableCell>
                    <TableCell>{transactionData.value}</TableCell>
                    <TableCell>{transactionData.paid_amount}</TableCell>
                    <TableCell>{transactionData.changedue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      )}
    </Container>
  );
};

TransactionDetailsCard.propTypes = {
  transaction: PropTypes.shape({
    paid_amount: PropTypes.number,
    payment_type: PropTypes.string,
    value: PropTypes.number,
    changedue: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    customer_email: PropTypes.string,
    customer_phone_number: PropTypes.string,
  }).isRequired,
  tickets: PropTypes.instanceOf(Array).isRequired,
};

export default TransactionDetailsCard;
