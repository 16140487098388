import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import MenuForm from '../../components/MenuForm';
import { createMenuEntry } from '../../../../redux/actions/cmsMenuActions';
import throwError from '../../../../shared/utility/errorHandler';

const MenuCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const saveMenu = (async (menu) => {
    const path = '/api/menus';
    const body = JSON.stringify(menu);
    return dispatch(createMenuEntry(path, body, true)).catch((err) => {
      throwError(err.response?.data);
    });
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveMenu)}>
      <MenuForm form="create" />
    </form>
  );
};

MenuCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'menu_create_form' })(MenuCreateForm);
