import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import AccountCreateForm from './AccountCreateForm';

const AccountCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <AccountCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default AccountCreateCard;
