const boolToString = (value, t) => {
  if (typeof value !== 'boolean') {
    return value;
  }
  return value ? t('yes') : t('no');
};

const datetimeToString = (value) => {
  const date = new Date(value);
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);
  const hour = new Intl.DateTimeFormat('en-US', { hour: '2-digit', hour12: false }).format(date);
  const minute = new Intl.DateTimeFormat('en-US', { minute: '2-digit' }).format(date);
  const second = new Intl.DateTimeFormat('en-US', { second: '2-digit' }).format(date);
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export { boolToString, datetimeToString };
