import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { datetimeToString } from '../../../../shared/utility/formatter';

const CancellationMain = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="cancellation__main">
            <div className="card__title">
              <h5 className="bold-text">{t('common:details')}</h5>
            </div>
            <hr />
            <div className="cancellation__info">
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('cancellation:status')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{data.status}</p>
                </Col>
              </Row>
              {data.booking && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('cancellation:bookingReference')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <Link className="" to={`/bookings/show/${data.booking.id}`} target="_blank">
                    <h5 className="bold-text">{data.booking.partner_booking_ref}</h5>
                  </Link>
                </Col>
              </Row>
              )}
              {data.created_at && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('common:createdAt')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{datetimeToString(data.created_at)}</p>
                </Col>
              </Row>
              )}
              {data.updated_at && (
              <Row>
                <Col sm={3} md={3}>
                  <p>
                    {t('common:updatedAt')}
                    :
                  </p>
                </Col>
                <Col sm={9} md={9}>
                  <p>{datetimeToString(data.updated_at)}</p>
                </Col>
              </Row>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

CancellationMain.propTypes = {
  data: PropTypes.shape({
    booking: PropTypes.shape(),
    status: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default CancellationMain;
