import React, { useState, useRef, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';

const RefreshForm = () => {
  const { t } = useTranslation();
  const countdownRef = useRef();
  const [interval, setInterval] = useState(30000);

  const intervals = [
    {
      label: '30 seconds',
      value: 30000,
    },
    {
      label: '1 minute',
      value: 60000,
    },
    {
      label: '5 minutes',
      value: 300000,
    },
    {
      label: 'no refresh',
      value: -1,
    },
  ];

  const handleChange = (e) => {
    if (e.target.value !== -1) {
      setInterval(Number(e.target.value));
    }
  };

  useEffect(() => {
    if (interval !== -1) {
      countdownRef.current.start();
    }
  }, [interval]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form">
            <div className="form__full">
              <div className="form-row">
                <Col md={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('live:reload')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="interval"
                        component={RenderSelectField}
                        options={intervals}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </form>
          {(interval !== -1) && (
            <Countdown
              ref={countdownRef}
              date={Date.now() + interval}
              onComplete={() => {
                if (interval === -1) {
                  countdownRef.current.stop();
                } else {
                  window.location.reload();
                }
              }}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({ form: 'refresh_form' })(RefreshForm);
