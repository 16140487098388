import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopbarNavLink from './TopbarNavLink';
import Can from '../../../shared/authorization/Can';

const OneTestIcon = `${process.env.PUBLIC_URL}/img/OneTestIcon.svg`;

const TopbarNavAllpay = ({ defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t } = useTranslation();

  return (
    <Can
      perform="allpayDropdown"
      yes={() => (
        <Dropdown
          className="topbar__nav-dropdown"
          isOpen={isOpen}
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          toggle={() => setIsOpen(!isOpen)}
        >
          <DropdownToggle className="topbar__nav-dropdown-toggle">
            {t('menu:allpay:title')}
            {' '}
            <DownIcon />
          </DropdownToggle>
          <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:devices')} icon={OneTestIcon} route="/devices" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:deviceStatuses')} icon={OneTestIcon} route="/device-statuses" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:deviceStatusLogs')} icon={OneTestIcon} route="/device-status-logs" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:transactions')} icon={OneTestIcon} route="/transactions" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:commands')} icon={OneTestIcon} route="/device-commands" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:products')} icon={OneTestIcon} route="/device-products" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:playlists')} icon={OneTestIcon} route="/playlists" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:allpay:live')} icon={OneTestIcon} route="/live" />
                </DropdownItem>
              )}
            />
          </DropdownMenu>
        </Dropdown>
      )}
    />
  );
};

TopbarNavAllpay.propTypes = {
  defaultOpen: PropTypes.bool,
};

TopbarNavAllpay.defaultProps = {
  defaultOpen: false,
};

export default TopbarNavAllpay;
