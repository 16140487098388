import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import CancellationFilterForm from './CancellationFilterForm';

const CancellationFilterCard = ({ refreshCallback }) => {
  const data = () => {
    const urlList = {};

    window.location.search
      .slice(1)
      .split('&')
      .forEach((item) => {
        const [key, val] = item.split('=');
        if (val !== '') {
          urlList[key] = decodeURIComponent(val);
        }
      });

    return urlList;
  };

  return (
    <Col md={12} lg={12}>
      <CancellationFilterForm
        refreshCallback={refreshCallback}
        initialValues={data()}
      />
    </Col>
  );
};

CancellationFilterCard.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
};

export default CancellationFilterCard;
