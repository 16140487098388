import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Card, CardBody, Col } from 'reactstrap';
import MenuEditForm from './MenuEditForm';
import MenuLangEditContainer from './MenuLangEditContainer';

const MenuEditCard = () => {
  const { id } = useParams();

  const menu = useSelector((state) => (
    state.cms.menu.menupoints.find((element) => element.id === +id)
  ));

  const { menu_langs: menuLangs = [] } = menu || {};

  const optimizeData = (data) => {
    const clone = { ...data };
    delete clone.id;
    delete clone.menu_langs;
    delete clone.menu_items;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    return clone;
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <MenuEditForm
            enableReinitialize
            keepDirtyOnReinitialize
            initialValues={optimizeData(menu)}
            onSubmit
          />
          <MenuLangEditContainer
            data={menuLangs}
            id={+id}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default MenuEditCard;
