import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DeviceStatusCreateCard from './components/DeviceStatusCreateCard';

const DeviceStatusCreate = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('deviceStatus:create')}</h3>
          </Col>
        </Row>
        <Row>
          <DeviceStatusCreateCard />
        </Row>
      </div>
    </Container>
  );
};

export default DeviceStatusCreate;
