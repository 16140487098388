import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import RenderField from '../../../shared/components/form/RenderField';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import PhotosForm from '../../../shared/components/form/PhotosForm';
import RenderRichTextareaField from '../../../shared/components/form/RenderRichTextareaField';

const ProductionForm = ({ form, path }) => {
  const locationList = useSelector((state) => state.production.locationList.map((location) => ({
    value: location.id,
    label: location.name,
  })));

  const { t } = useTranslation();

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('production:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('production:name')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('production:shortDescription')}</span>
              <div className="form__form-group-field">
                <Field
                  name="short_description"
                  component={RenderRichTextareaField}
                  type="text"
                  placeholder={t('production:shortDescription')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('production:longDescription')}</span>
              <div className="form__form-group-field">
                <Field
                  name="long_description"
                  component={RenderRichTextareaField}
                  type="text"
                  placeholder={t('production:longDescription')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('production:contributors')}</span>
              <div className="form__form-group-field">
                <Field
                  name="contributors"
                  component={RenderField}
                  type="text"
                  placeholder={t('production:contributors')}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('production:category')}</span>
              <div className="form__form-group-field">
                <Field
                  name="category"
                  component={RenderField}
                  type="text"
                  placeholder={t('production:category')}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('production:location')}</span>
              <div className="form__form-group-field">
                <Field
                  name="location_id"
                  component={RenderSelectField}
                  type="text"
                  options={locationList}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <PhotosForm label={`${t('production:self')} ${t('common:photos')}`} />
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

ProductionForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

ProductionForm.defaultProps = {
  form: '',
  path: '/productions',
};

export default ProductionForm;
