import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import LocationCreateForm from './LocationCreateForm';

const LocationCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <LocationCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default LocationCreateCard;
