import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import DeviceCommandFilterCard from './Filter/DeviceCommandFilterCard';
import Can from '../../shared/authorization/Can';
import { setAlert } from '../../redux/actions/alertActions';
import ConfirmPopup from '../../shared/components/ConfirmPopup';

const DeviceCommandList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/device-commands${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          created_at: format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
          executed_at: (row.executed_at === null) ? '' : format(new Date(row.executed_at), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const showDeleteConfirmation = (id) => {
    const item = rows.find((row) => row.id === id);
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onConfirmDelete = useCallback(async () => {
    setIsModalOpen(false);
    onShowSpinner();
    const path = `/api/device-commands/${itemToDelete.id}`;
    await axios.delete(path)
      .then(() => {
        fetchData();
      }).catch(() => {
        dispatch(setAlert(t('common:deleteError'), 'danger'));
        onHideSpinner();
      });
  }, [dispatch, fetchData, itemToDelete, onHideSpinner, onShowSpinner, t]);

  const actionCallbacks = {
    delete: showDeleteConfirmation,
  };

  const columns = [
    {
      id: 'device', label: t('deviceCommand:device'),
    },
    {
      id: 'command', label: t('deviceCommand:command'),
    },
    {
      id: 'parameter', label: t('deviceCommand:parameter'),
    },
    {
      id: 'created_at', label: t('common:createdAt'),
    },
    {
      id: 'executed_at', label: t('deviceCommand:executedAt'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <ConfirmPopup
          isOpen={isModalOpen}
          item={itemToDelete && itemToDelete.name}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('deviceCommand:list')}</h3>
          </Col>
          <Col md={4}>
            <Can
              perform="deviceCommand:create"
              yes={() => (
                <Link
                  className="btn btn-success float-right"
                  to={`${window.location.pathname}/create`}
                >
                  {t('deviceCommand:create')}
                </Link>
              )}
            />
            <Can
              perform="deviceCommands:delete"
              yes={() => {
                operations.push('delete');
                return null;
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col><DeviceCommandFilterCard refreshCallback={fetchData} /></Col>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default DeviceCommandList;
