import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Modal, ModalHeader, ModalFooter, ModalBody,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContentSelectorTable from './ContentSelectorTable';
import { fetchContentsDirectly, updateContent } from '../../../../redux/actions/cmsContentActions';

const ContentSelectorModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [contentList, setContentList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [totalContentNumber, setTotalContentNumber] = useState(0);

  const fetchData = useCallback((params = {}) => {
    dispatch(fetchContentsDirectly('/api/contents', { params })).then((res) => {
      setContentList(res.data.list);
      setTotalContentNumber(res.data.totalCount);
    });
  }, [dispatch]);

  const sendUpdate = () => {
    if (!selected.length) {
      toggle();
      return;
    }
    dispatch(updateContent('/api/contents/mass-assign', { idList: selected, menuItemId: +id })).then(() => {
      toggle();
      setSelected([]);
    });
  };

  useEffect(() => {
    if (isOpen) {
      fetchData({ unassignedOnly: true });
    }
  }, [fetchData, isOpen]);

  const columns = [
    {
      id: 'title', label: t('content:title'),
    },
    {
      id: 'created_at', label: t('common:createdAt'),
    },
    {
      id: 'updated_at', label: t('common:updatedAt'),
    },
  ];

  return (
    <Modal zIndex="100" id="content-selector" isOpen={isOpen} toggle={toggle} centered backdrop="static">
      <ModalHeader>
        {t('cmsMenuItem:addExistingContent')}
      </ModalHeader>
      <ModalBody>
        <ContentSelectorTable
          columns={columns}
          rows={contentList}
          total={totalContentNumber}
          callback={fetchData}
          selected={selected}
          setSelected={setSelected}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-success" type="button" onClick={sendUpdate}>{t('common:buttons:save')}</button>
        <button className="btn btn-secondary" type="button" onClick={() => { toggle(); setSelected([]); }}>{t('common:buttons:cancel')}</button>
      </ModalFooter>
    </Modal>
  );
};

ContentSelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ContentSelectorModal;
