import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';

import renderDropZoneMultipleField from './DropZoneMultiple';

const PhotosForm = (props) => {
  const { actionCallbacks, label } = props;

  return (
    <div className="form-row">
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">{label}</span>
          <div className="form__form-group-field">
            <Field
              name="images"
              component={renderDropZoneMultipleField}
              type="file"
              accept="image/jpeg, image/png"
              actions={actionCallbacks}
            />
          </div>
        </div>
      </Col>
    </div>
  );
};

PhotosForm.propTypes = {
  actionCallbacks: PropTypes.objectOf(PropTypes.func),
  label: PropTypes.string,
};

PhotosForm.defaultProps = {
  actionCallbacks: {},
  label: '',
};

export default PhotosForm;
