import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, IconButton, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';

const TableToolbar = ({
  selected, handleDeleteSelected, actions, t,
}) => (
  <div className="material-table__toolbar-wrap">
    <Toolbar className="material-table__toolbar">
      <div className="material-table__toolbar-selection">
        <div>
          <h5 className="material-table__toolbar-selected">
            {selected.length}
            {' '}
            <span>{t('selected')}</span>
          </h5>
        </div>
        <div>
          {selected.length > 0 && (
          <Tooltip title={t('common:actions:clearSelection')} placement="bottom" arrow>
            <IconButton
              onClick={handleDeleteSelected}
              className="material-table__toolbar-button"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          )}
        </div>
      </div>
      {selected.length > 0 && (
      <div className="material-table__toolbar-actions">
        {
          actions.map((action) => (
            <button
              key={action.label}
              className="btn btn-success action-button"
              type="button"
              onClick={() => { action.callback(selected); }}
              disabled={selected.length === 0}
            >
              {action.label}
            </button>
          ))
        }
      </div>
      )}
    </Toolbar>
  </div>
);

TableToolbar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TableToolbar);
