import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, NavLink, TabContent, Nav, NavItem,
} from 'reactstrap';
import ContentLangTab from './ContentLangTab';

const ContentMain = ({ data }) => {
  const { content_langs: contentLangs = [] } = data;
  const [activeTab, setActiveTab] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (!activeTab && contentLangs?.length) setActiveTab(contentLangs[0].id);
  }, [activeTab, contentLangs]);

  const contentLangLinks = () => contentLangs.map((cl) => (
    <NavItem key={cl.id}>
      <NavLink onClick={() => { setActiveTab(cl.id); }} className={activeTab === cl.id ? 'active' : null}>{cl.language.name}</NavLink>
    </NavItem>
  ));

  const contentLangTabs = () => contentLangs.map(
    (cl) => <ContentLangTab key={cl.id} contentLang={cl} />,
  );

  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="content-page__content-container">
              <h4>{data.title}</h4>
              {data.menu_item?.name && <h4>{`${t('content:menuItem')}: ${data.menu_item?.name}`}</h4>}
            </div>
            {contentLangs.length > 0 && (
              <div className="content-page__nav-container">
                <Nav tabs>
                  {contentLangLinks()}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {contentLangTabs()}
                </TabContent>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

ContentMain.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    menu_item: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.number]),
    content_langs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
};

export default ContentMain;
