import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useParams } from 'react-router-dom';
import MenuLangForm from '../../components/MenuLangForm';
import { createContent } from '../../../../redux/actions/cmsContentActions';
import throwError from '../../../../shared/utility/errorHandler';

const MenuLangCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { menuId } = useParams();

  const saveMenuLang = (menuLang) => {
    const path = '/api/menu-langs';
    const clone = { ...menuLang };
    clone.menuId = +menuId;
    const body = JSON.stringify(clone);

    return dispatch(createContent(path, body, {}, true)).catch((error) => {
      throwError(error.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveMenuLang)}>
      <MenuLangForm form="create" />
    </form>
  );
};

MenuLangCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'menu_lang_create_form' })(MenuLangCreateForm);
