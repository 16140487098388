import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import RenderField from '../../../shared/components/form/RenderField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';

const ContentForm = ({ form, path }) => {
  const { t } = useTranslation();
  const { menuItemList = [] } = useSelector((state) => state.cms.menu);

  const composeMenuItemOptions = (itemList) => {
    const optionList = itemList.map((menuItem) => ({
      label: menuItem.name,
      value: menuItem.id,
    }));
    optionList.unshift({
      label: '-',
      value: null,
    });
    return optionList;
  };

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('content:title')}</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component={RenderField}
                  type="text"
                  placeholder={t('content:title')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('content:menuItem')}</span>
              <div className="form__form-group-field">
                <Field
                  name="menu_item_id"
                  normalize={(id) => +id || null}
                  component={RenderSelectField}
                  type="text"
                  options={composeMenuItemOptions(menuItemList)}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

ContentForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

ContentForm.defaultProps = {
  form: '',
  path: '/contents',
};

export default ContentForm;
