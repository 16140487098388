import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderSelectField from '../../../../shared/components/form/RenderSelectField';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import fetchCancelReasons from '../fetchCancelReasons';

const WizardFormTwo = ({ data, handleSubmit, previousPage }) => {
  const { t } = useTranslation();
  const [reasons, setReasons] = useState([]);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchReasons = useCallback(() => {
    fetchCancelReasons({ dispatch, setReasons, t });
  }, [dispatch]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  const sendCancel = (async (cancel) => {
    onShowSpinner();
    const body = JSON.stringify(cancel);
    const path = `/booking-api/cancel/${data.partner_booking_ref}/cancel`;
    try {
      await axios.post(path, body);
      dispatch(setAlert(t('cancellation:cancelSuccess'), 'success'));
      window.location.replace('/bookings');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('cancellation:cancelError'), 'danger'));
          break;
      }
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form form--horizontal wizard__form" onSubmit={handleSubmit(sendCancel)}>
      <h3 className="wizard__title">{t('cancellation:why')}</h3>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('cancellation:reason')}</span>
        <div className="form__form-group-field">
          <Field
            name="reasonCode"
            component={RenderSelectField}
            type="text"
            options={[
              { value: '', label: t('common:selectPrompt') },
              ...reasons,
            ]}
          />
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" className="previous" onClick={previousPage}>{t('common:buttons:back')}</Button>
        <Button color="btn btn-success" type="submit" className="next">{t('common:buttons:cancel')}</Button>
      </ButtonToolbar>
    </form>
  );
};

WizardFormTwo.propTypes = {
  data: PropTypes.shape({
    partner_booking_ref: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(WizardFormTwo);
