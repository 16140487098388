import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const EventCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="event-card">
            <Row>
              <Col md={12} lg={12}>
                <div className="event-card__info">
                  <Row>
                    <Col md={2}>
                      <p>{t('event:description')}</p>
                      <span>{data.description}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('event:date')}</p>
                      <span>{data.event_date}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('event:recurrence')}</p>
                      <span>{data.recurrence}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

EventCard.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    event_date: PropTypes.string,
    recurrence: PropTypes.number,
  }).isRequired,
};

export default EventCard;
