import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import RenderField from '../../../shared/components/form/RenderField';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import { useAuthCheck } from '../../../shared/utility/APIUtils';

const DeviceStatusForm = (props) => {
  const {
    form,
    path,
  } = props;

  const isAuthenticated = useAuthCheck();
  const [error, setError] = useState(false);
  const [severityList, setSeverityList] = useState([]);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    await axios.get('/api/severities/options')
      .then((res) => {
        setSeverityList(res.data.map(((severity) => ({
          value: severity.id,
          label: severity.name,
        }))));
      }).catch(() => {
        setError(true);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('deviceStatus:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('deviceStatus:name')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('deviceStatus:severityName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="severity"
                  component={RenderSelectField}
                  type="text"
                  options={severityList}
                />
              </div>
            </div>
            {error ? <div>{t('common:error')}</div> : ''}
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

DeviceStatusForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

DeviceStatusForm.defaultProps = {
  form: '',
  path: '/device-statuses',
};

export default DeviceStatusForm;
