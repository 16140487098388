import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import ConfirmPopup from '../../shared/components/ConfirmPopup';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { deleteContent, fetchContents } from '../../redux/actions/cmsContentActions';
import ContentFilterCard from './Filter/ContentFilterCard';
import PageHandler from '../../shared/components/PageHandler';

const ContentList = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthCheck();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deletePath, setDeletePath] = useState('');
  const operations = ['show', 'edit', 'delete'];

  const dispatch = useDispatch();

  const { contents = [], totalCount = 0, statusCode = null } = useSelector((state) => ({
    contents: state.cms?.content?.contents,
    totalCount: state.cms?.content?.contentCount,
    statusCode: state.cms?.content?.statusCode,
  }));

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(fetchContents(`api/contents${window.location.search}`));
    }
  }, [dispatch, isAuthenticated]);

  const columns = [
    {
      id: 'title', label: t('content:title'),
    },
    {
      id: 'menuItemName', label: t('content:menuItem'),
    },
  ];

  const showDeleteConfirmation = (id) => {
    const item = contents.find((content) => content.id === id);
    setItemToDelete(item);
    setDeletePath(`/api/contents/${id}`);
    setIsModalOpen(true);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onConfirmDelete = () => {
    dispatch(deleteContent(deletePath));
    setIsModalOpen(false);
  };

  const actionCallbacks = {
    delete: showDeleteConfirmation,
  };

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <ConfirmPopup
          isOpen={isModalOpen}
          item={itemToDelete && itemToDelete.title}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('content:list')}</h3>
          </Col>
          <Col md={4}>
            <Link className="btn btn-success float-right" to={`${window.location.pathname}/create`}>{t('content:create')}</Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <ContentFilterCard />
          </Col>
          <MaterialTable
            rows={contents || []}
            columns={columns}
            total={totalCount}
            callback={() => { dispatch(fetchContents(`/api/contents${window.location.search}`)); }}
            operations={operations}
            dependencyCells={['enabled']}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default ContentList;
