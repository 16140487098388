import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Card, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';

const WizardForm = ({ data }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <div className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>{t('cancellation:cancelQuote')}</p></div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>{t('cancellation:cancel')}</p></div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && (
              <WizardFormOne
                data={data}
                onSubmit={nextPage}
              />
              )}
              {page === 2 && (
              <WizardFormTwo
                initialValues={{ reasonCode: '' }}
                data={data}
                previousPage={previousPage}
              />
              )}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

WizardForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default WizardForm;
