import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopbarNavLink from './TopbarNavLink';
import Can from '../../../shared/authorization/Can';

const OneTestIcon = `${process.env.PUBLIC_URL}/img/OneTestIcon.svg`;

const TopbarNavCms = ({ defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t } = useTranslation();

  return (
    <Can
      perform="allpayDropdown"
      yes={() => (
        <Dropdown
          className="topbar__nav-dropdown"
          isOpen={isOpen}
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          toggle={() => setIsOpen(!isOpen)}
        >
          <DropdownToggle className="topbar__nav-dropdown-toggle">
            {t('menu:cms:title')}
            {' '}
            <DownIcon />
          </DropdownToggle>
          <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
            <Can
              perform="cms"
              yes={() => (
                <>
                  <DropdownItem>
                    <TopbarNavLink title={t('menu:cms:menupoints')} icon={OneTestIcon} route="/menus" />
                  </DropdownItem>
                  <DropdownItem>
                    <TopbarNavLink title={t('menu:cms:contents')} icon={OneTestIcon} route="/contents" />
                  </DropdownItem>
                </>
              )}
            />
          </DropdownMenu>
        </Dropdown>
      )}
    />
  );
};

TopbarNavCms.propTypes = {
  defaultOpen: PropTypes.bool,
};

TopbarNavCms.defaultProps = {
  defaultOpen: false,
};

export default TopbarNavCms;
