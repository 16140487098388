import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import MenuForm from '../../components/MenuForm';
import { editMenuEntry } from '../../../../redux/actions/cmsMenuActions';
import throwError from '../../../../shared/utility/errorHandler';

const MenuEditForm = ({ handleSubmit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const saveMenu = (async (menu) => {
    const path = `/api/menus/${id}`;
    const body = JSON.stringify(menu);
    return dispatch(editMenuEntry(path, body, true)).catch((error) => {
      throwError(error.response?.data);
    });
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveMenu)}>
      <MenuForm form="edit" />
    </form>
  );
};

MenuEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'menu_edit_form' })(MenuEditForm);
