import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import convertToURL from '../../../shared/utility/filter';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import { setAlert } from '../../../redux/actions/alertActions';

const TransactionFilterForm = (props) => {
  const { handleSubmit, dispatch, refreshCallback } = props;
  const [transactionTypeList, setTransactionTypeList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const sendFilter = async (filter) => {
    const copy = { ...filter };
    const filterString = convertToURL(copy);
    history.push(`${window.location.pathname}?${filterString}`);
    refreshCallback();
  };

  const fetchSelects = useCallback(async () => {
    await axios.get('/api/transaction-types')
      .then((res) => {
        const list = res.data.map(((option) => ({
          value: option.id,
          label: option.name,
        })));
        list.unshift({
          value: '',
          label: t('common:selectPrompt'),
        });
        setTransactionTypeList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });

    await axios.get('/api/devices/options')
      .then((res) => {
        const list = res.data.map(((option) => ({
          value: option.id,
          label: option.name,
        })));
        list.unshift({
          value: '',
          label: t('common:selectPrompt'),
        });
        setDeviceList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });

    await axios.get('/api/payment-types')
      .then((res) => {
        const list = res.data.map(((option) => ({
          value: option.id,
          label: option.name,
        })));
        list.unshift({
          value: '',
          label: t('common:selectPrompt'),
        });
        setPaymentTypeList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });
  }, [dispatch, t]);

  useEffect(() => {
    fetchSelects();
  }, [fetchSelects]);

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={12}>
        <Row>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:transactionType')}</span>
              <div className="form__form-group-field">
                <Field
                  name="transactionType"
                  component={RenderSelectField}
                  options={transactionTypeList}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:device')}</span>
              <div className="form__form-group-field">
                <Field
                  name="device"
                  component={RenderSelectField}
                  options={deviceList}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:transactionId')}</span>
              <div className="form__form-group-field">
                <Field
                  name="transactionId"
                  component={RenderField}
                  type="number"
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:date')}</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:paidAmount')}</span>
              <div className="form__form-group-field">
                <Field
                  name="paidAmount"
                  component={RenderField}
                  type="number"
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:value')}</span>
              <div className="form__form-group-field">
                <Field
                  name="value"
                  component={RenderField}
                  type="number"
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:changedue')}</span>
              <div className="form__form-group-field">
                <Field
                  name="changedue"
                  component={RenderField}
                  type="number"
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('transaction:paymentType')}</span>
              <div className="form__form-group-field">
                <Field
                  name="paymentType"
                  component={RenderSelectField}
                  options={paymentTypeList}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="line-end">
        <button className="btn btn-success" type="submit">
          {t('common:filter')}
        </button>
      </Col>
    </form>
  );
};

TransactionFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'transaction_filter',
  enableReinitialize: true,
})(TransactionFilterForm);

export default FormDecoratedComponent;
