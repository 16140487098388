import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useParams } from 'react-router-dom';
import MenuItemLangForm from '../../components/MenuItemLangForm';
import { createContent } from '../../../../redux/actions/cmsContentActions';
import throwError from '../../../../shared/utility/errorHandler';

const MenuItemLangCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { menuItemId } = useParams();

  const saveMenuItemLang = (menuItemLang) => {
    const path = '/api/menu-item-langs';
    const clone = { ...menuItemLang };
    clone.menuItemId = +menuItemId;
    const body = JSON.stringify(clone);

    return dispatch(createContent(path, body, {}, true)).catch((error) => {
      throwError(error.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(saveMenuItemLang)}>
      <MenuItemLangForm form="create" />
    </form>
  );
};

MenuItemLangCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'menu_item_lang_create_form' })(MenuItemLangCreateForm);
