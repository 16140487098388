import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Checkbox,
} from '@material-ui/core';
import TablePaginationActions from '../../../../shared/components/table/TablePaginationActions';
import MaterialTableHead from '../../../../shared/components/table/MaterialTableHead';
import { boolToString } from '../../../../shared/utility/formatter';

const ContentSelectorTable = (props) => {
  const {
    rows,
    columns,
    total,
    checkboxSelection,
    callback,
    selected,
    setSelected,
  } = props;
  const { t } = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const bundleState = (directInput = {}) => ({
    order: directInput.order ?? order,
    orderBy: directInput.orderBy ?? orderBy,
    selected: directInput.selected ?? selected,
    page: directInput.page ?? page,
    rowsPerPage: directInput.rowsPerPage ?? rowsPerPage,
    unassignedOnly: true,
  });

  const headCells = columns.map((column) => {
    const newColumn = { ...column };
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'numeric')) {
      newColumn.numeric = false;
    }
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'disablePadding')) {
      newColumn.disablePadding = false;
    }
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'visibility')) {
      newColumn.visibility = true;
    }
    return newColumn;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    callback(bundleState({
      order: newOrder,
      orderBy: property,
    }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    switch (true) {
      case selectedIndex === 1:
        newSelected = newSelected.concat(selected, id);
        break;
      case selectedIndex === 0:
        newSelected = newSelected.concat(selected.slice(1));
        break;
      case selectedIndex === selected.length - 1:
        newSelected = newSelected.concat(selected.slice(0, -1));
        break;
      case selectedIndex > 0:
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
        break;
      default:
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    callback(bundleState({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    callback(bundleState({
      page: 0,
      rowsPerPage: newRowsPerPage,
    }));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <TableContainer className="material-table__wrap">
            <Table className="material-table">
              <MaterialTableHead
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={total}
                checkboxSelection={checkboxSelection}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `material-table-checkbox-${index}`;

                  return (
                    <TableRow
                      className="material-table__row"
                      tabIndex={-1}
                      key={row.id}
                      onClick={checkboxSelection ? (event) => handleClick(event, row.id) : null}
                      role={checkboxSelection ? 'checkbox' : 'row'}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      {checkboxSelection && (
                        <TableCell className="material-table__cell" padding="checkbox">
                          <Checkbox
                            className="material-table__checkbox"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {headCells.map((cell) => (
                        <TableCell key={`${cell.id}-${row.id}`} className="material-table__cell">
                          {cell.visibility === true && (boolToString(row[cell.id]))}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="material-table__pagination">
            <TablePagination
              component="div"
              classes={{ root: 'tablePagination' }}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              labelRowsPerPage={t('common:rowsPerPage')}
              labelDisplayedRows={({ from, to }) => `${t('common:rows')} ${from}-${to} / ${total}`}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

ContentSelectorTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  checkboxSelection: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelected: PropTypes.func.isRequired,
};

ContentSelectorTable.defaultProps = {
  checkboxSelection: true,
};

export default ContentSelectorTable;
