import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import ProductionForm from '../../components/ProductionForm';
import { getLocationList } from '../../../../redux/actions/productionActions';

const ProductionEditForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const { images: initialImages = [] } = useSelector((state) => (
    state.form.production_edit_form.initial
  ));
  const { images: currentImages = [] } = useSelector((state) => (
    state.form.production_edit_form.values
  ));

  useEffect(() => {
    dispatch(getLocationList());
  }, [dispatch]);

  const editProduction = (async (production) => {
    onShowSpinner();
    const path = `/api/productions/${id}`;
    const body = JSON.stringify(production);

    const getImagesToDelete = () => {
      const imagesToDelete = initialImages.filter((initialImage) => !currentImages.some((image) => (
        image.id === initialImage.id
      )));
      return imagesToDelete;
    };

    const getImagesToUpload = () => {
      const imagesToUpload = currentImages.filter((image) => ('path' in image));
      return imagesToUpload;
    };

    const imagesToDelete = getImagesToDelete();
    const imagesToUpload = getImagesToUpload();

    try {
      await axios.put(path, body);
      if (imagesToUpload.length) {
        const imageUploadForm = new FormData();
        imagesToUpload.forEach((image) => {
          imageUploadForm.append('images[]', image);
        });
        await axios.post(`/api/productions/${id}/references`, imageUploadForm, { headers: { 'Content-type': 'multipart/form-data' } });
      }
      if (imagesToDelete.length) {
        await Promise.all(
          imagesToDelete.map((image) => (
            axios.delete(`/api/productions/references/${image.id}`))),
        );
      }
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/productions');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(editProduction)}>
      <ProductionForm form="edit" />
    </form>
  );
};

ProductionEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'production_edit_form',
  enableReinitialize: true,
})(ProductionEditForm);

export default FormDecoratedComponent;
