import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import MenuItemLangForm from '../../components/MenuItemLangForm';
import throwError from '../../../../shared/utility/errorHandler';
import { editMenuEntry } from '../../../../redux/actions/cmsMenuActions';

const MenuItemLangEditForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const optimizeData = (cl) => {
    const clone = { ...cl };
    delete clone.id;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    delete clone.language;
    return clone;
  };

  const editMenuItemLang = async (menuItemLang) => {
    const path = `/api/menu-item-langs/${menuItemLang.id}`;
    const body = JSON.stringify(optimizeData(menuItemLang));

    return dispatch(editMenuEntry(path, body, true)).catch((err) => {
      throwError(err.response?.data);
    });
  };

  return (
    <form className="form menu-item-edit-page__form" onSubmit={handleSubmit(editMenuItemLang)}>
      <MenuItemLangForm form="edit" />
    </form>
  );
};

MenuItemLangEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'menu_lang_edit_form',
  enableReinitialize: true,
})(MenuItemLangEditForm);

export default FormDecoratedComponent;
