import {
  SET_STATUS_CODE,
  SET_CONTENTS,
  SET_SINGLE_CONTENT,
  SET_CONTENT_COUNT,
  SET_LANGUAGES,
} from '../actions/cmsContentActions';

const initialState = {
  statusCode: null,
  contents: [],
  contentCount: 0,
  languages: [],
};

const handleSingleContent = (state, { payload }) => {
  const clonedContentList = [...state.contents];
  const existingContentIndex = clonedContentList.findIndex((content) => (
    content.id === payload.id
  ));
  if (existingContentIndex < 0) {
    clonedContentList.push(payload);
  } else {
    clonedContentList.splice(existingContentIndex, 1, payload);
  }
  return {
    ...state,
    contents: clonedContentList,
  };
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_CONTENTS:
      return {
        ...state,
        contents: action.payload,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case SET_SINGLE_CONTENT:
      return handleSingleContent(state, action);
    case SET_CONTENT_COUNT:
      return {
        ...state,
        contentCount: action.payload,
      };
    case SET_STATUS_CODE:
      return {
        ...state,
        statusCode: action.payload,
      };
    default:
      return state;
  }
};
