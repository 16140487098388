import React from 'react';
import PropTypes from 'prop-types';

const RenderTextareaField = ({
  input, placeholder, disabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <textarea
      {...input}
      placeholder={placeholder}
      disabled={disabled}
    />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

RenderTextareaField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderTextareaField.defaultProps = {
  placeholder: '',
  disabled: false,
  meta: null,
};

export default RenderTextareaField;
