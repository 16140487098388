import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import LocationForm from '../../components/LocationForm';

const LocationCreateForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const saveLocation = (async (location) => {
    onShowSpinner();
    const { images = [] } = location;
    const path = '/api/locations';
    let body;
    let options = {};
    if (images.length) {
      body = new FormData();
      body.append('jsonObject', JSON.stringify(location));
      images.forEach((image) => body.append('images[]', image));
      options = { headers: { 'Content-type': 'multipart/form-data' } };
    } else {
      body = JSON.stringify(location);
    }

    try {
      await axios.post(path, body, options);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/locations');
    } catch (err) {
      onHideSpinner();
      dispatch(setAlert(t('common:saveError'), 'danger'));
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveLocation)}>
      <LocationForm form="create" />
    </form>
  );
};

LocationCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'location_create_form' })(LocationCreateForm);
