import React from 'react';
import PropTypes from 'prop-types';
import PlayCircleOutlineIcon from 'mdi-react/PlayCircleOutlineIcon';

const VideoPlayIcon = ({ title }) => (
  <div className="video-play-icon">
    <PlayCircleOutlineIcon size={80} />
    <span>{title}</span>
  </div>
);

VideoPlayIcon.propTypes = {
  title: PropTypes.string,
};

VideoPlayIcon.defaultProps = {
  title: '',
};

export default VideoPlayIcon;
