import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import EventForm from '../../components/EventForm';

const EventCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const { t } = useTranslation();

  const saveEvent = (async (event) => {
    onShowSpinner();
    const clone = {
      ...event,
    };

    const formData = new FormData();
    formData.append('jsonObject', JSON.stringify(clone));
    const path = '/api/events';

    try {
      await axios.post(path, formData, { headers: { 'Content-type': 'multipart/form-data' } });
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/events');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveEvent)}>
      <EventForm
        form="create"
      />
    </form>
  );
};

EventCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'event_create_form' })(EventCreateForm);
