import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TicketTypeCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="ticket-type-card">
            <Row>
              <Col md={12} lg={12}>
                <div className="ticket-type-card__info">
                  <Row>
                    <Col md={2}>
                      <p>{t('ticketType:name')}</p>
                      <span>{data.name}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('ticketType:description')}</p>
                      <span>{data.description}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('ticketType:discount')}</p>
                      <span>{data.discount}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('ticketType:age')}</p>
                      <span>
                        {data.from_age}
                        {' - '}
                        {data.to_age}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

TicketTypeCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    discount: PropTypes.number,
    from_age: PropTypes.number,
    to_age: PropTypes.number,
  }).isRequired,
};

export default TicketTypeCard;
