import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import Can from '../../shared/authorization/Can';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import ConfirmPopup from '../../shared/components/ConfirmPopup';
import { setAlert } from '../../redux/actions/alertActions';
import PlaylistFilterCard from './Filter/PlaylistFilterCard';

const PlaylistList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/playlists${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          valid_from: row.valid_from && format(new Date(row.valid_from), 'yyyy-MM-dd HH:mm'),
          valid_to: row.valid_to && format(new Date(row.valid_to), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'name', label: t('playlist:name'),
    },
    {
      id: 'valid_from', label: t('playlist:validFrom'),
    },
    {
      id: 'valid_to', label: t('playlist:validTo'),
    },
  ];

  const showDeleteConfirmation = (id) => {
    const item = rows.find((row) => row.id === id);
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onConfirmDelete = useCallback(async () => {
    setIsModalOpen(false);
    onShowSpinner();
    const path = `/api/playlists/${itemToDelete.id}`;
    await axios.delete(path)
      .then(() => {
        fetchData();
      }).catch(() => {
        dispatch(setAlert(t('common:deleteError'), 'danger'));
        onHideSpinner();
      });
  }, [dispatch, fetchData, itemToDelete, onHideSpinner, onShowSpinner, t]);

  const actionCallbacks = {
    delete: showDeleteConfirmation,
  };
  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <ConfirmPopup
          isOpen={isModalOpen}
          item={itemToDelete && itemToDelete.name}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('playlist:list')}</h3>
          </Col>
          <Col md={4}>
            <Can
              perform="playlists:create"
              yes={() => (
                <Link
                  className="btn btn-success float-right"
                  to={`${window.location.pathname}/create`}
                >
                  {t('playlist:create')}
                </Link>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Can
            perform="playlists:show"
            yes={() => { operations.push('show'); return null; }}
          />
          <Can
            perform="playlists:edit"
            yes={() => {
              operations.push('edit');
              return null;
            }}
          />
          <Can
            perform="playlists:delete"
            yes={() => {
              operations.push('delete');
              return null;
            }}
          />
          <Col><PlaylistFilterCard refreshCallback={fetchData} /></Col>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default PlaylistList;
