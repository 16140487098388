import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopbarNavLink from './TopbarNavLink';
import Can from '../../../shared/authorization/Can';

const OneTestIcon = `${process.env.PUBLIC_URL}/img/OneTestIcon.svg`;

const TopbarNavSettingsAndOptions = ({ defaultOpen, t }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Can
      perform="settingsDropdown"
      yes={() => (
        <Dropdown
          className="topbar__nav-dropdown"
          isOpen={isOpen}
          onClick={() => setIsOpen(true)}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          toggle={() => setIsOpen(!isOpen)}
        >
          <DropdownToggle className="topbar__nav-dropdown-toggle">
            {t('menu:options')}
            {' '}
            <DownIcon />
          </DropdownToggle>
          <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:users')} icon={OneTestIcon} route="/accounts" />
                </DropdownItem>
              )}
            />
            <Can
              perform="accounts"
              yes={() => (
                <DropdownItem>
                  <TopbarNavLink title={t('menu:settings')} icon={OneTestIcon} route="/app-settings" />
                </DropdownItem>
              )}
            />
          </DropdownMenu>
        </Dropdown>
      )}
    />
  );
};

TopbarNavSettingsAndOptions.propTypes = {
  defaultOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

TopbarNavSettingsAndOptions.defaultProps = {
  defaultOpen: false,
};

export default withTranslation()(TopbarNavSettingsAndOptions);
