import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';

import renderDropZoneMultipleField from './DropZoneMultiple';

const DocumentsForm = (props) => {
  const { actionCallbacks, label } = props;

  return (
    <div className="form-row">
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">{label}</span>
          <div className="form__form-group-field">
            <Field
              name="documents"
              component={renderDropZoneMultipleField}
              type="file"
              accept="
                image/jpeg,
                image/png,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/pdf,
                application/vnd.ms-powerpoint,
                application/vnd.openxmlformats-officedocument.presentationml.presentation,
                application/vnd.ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              actions={actionCallbacks}
            />
          </div>
        </div>
      </Col>
    </div>
  );
};

DocumentsForm.propTypes = {
  actionCallbacks: PropTypes.objectOf(PropTypes.func),
  label: PropTypes.string,
};

DocumentsForm.defaultProps = {
  actionCallbacks: {},
  label: '',
};

export default DocumentsForm;
