import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Field,
  reduxForm,
  FormSection,
  change,
} from 'redux-form';
import { Col } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import { Redirect, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { register } from '../../../../redux/actions/authActions';
import renderToggleButtonField from '../../../../shared/components/form/ToggleButton';
import RenderField from '../../../../shared/components/form/RenderField';
import RenderSelectField from '../../../../shared/components/form/RenderSelectField';
import RenderCleaveField from '../../../../shared/components/form/RenderCleaveField';
import LinkErrorCard from '../../../../shared/components/LinkErrorCard';
import Auxiliary from '../../../../shared/hoc/Auxiliary';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import { getRoleOptions } from '../../../../shared/utility/RoleHelper';

const RegisterForm = (props) => {
  const [withToken, setWithToken] = useState(false);
  const [error, setError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const except = ['ROLE_ADMIN', 'ROLE_INTERMEDIARY', 'ROLE_ADVERTISER'];
  const {
    handleSubmit, isAuthenticated, signUp,
  } = props;

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const form = useSelector((state) => state.form.register_form);

  useMemo(() => {
    if (form.submitFailed) {
      setTimeout(() => {
        onHideSpinner();
      }, 100);
    }
  }, [form, onHideSpinner]);

  const query = queryString.parse(window.location.search);

  useEffect(() => {
    if (!query.token) {
      return;
    }

    onShowSpinner();

    const path = `/api/signup/token-validate/${query.token}`;
    axios.get(path)
      .then((res) => {
        if (res.data !== null) {
          setWithToken(true);
          dispatch(change('register_form', 'email', res.data.email));
          dispatch(change('register_form', 'roles', res.data.role));
          dispatch(change('register_form', 'token', res.data.token));
        } else {
          setError(true);
        }
      }).catch(() => {
        setError(true);
      }).finally(() => {
        onHideSpinner();
      });
  }, [dispatch, onHideSpinner, onShowSpinner, query.token]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const showPassword = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Auxiliary>
      { error ? (<LinkErrorCard />) : (
        <form className="form" onSubmit={handleSubmit(signUp)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Email cím</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component={RenderField}
                type="text"
                placeholder="Email cím"
                disabled={withToken}
              />
            </div>
          </div>
          {
            withToken !== true && (
              <div className="form__form-group">
                <span className="form__form-group-label">Regisztráció típusa</span>
                <div className="form__form-group-field">
                  <Field
                    name="roles"
                    component={RenderSelectField}
                    options={getRoleOptions(except)}
                  />
                </div>
              </div>
            )
          }
          <div className="form__form-group">
            <span className="form__form-group-label">Jelszó</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={RenderField}
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Jelszó"
              />
              <button
                type="button"
                className={`form__form-group-button${passwordVisible ? ' active' : ''}`}
                onClick={(e) => showPassword(e)}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Jelszó újra</span>
            <div className="form__form-group-field">
              <Field
                name="retyped_password"
                component={RenderField}
                type="password"
                placeholder="Jelszó újra"
              />
            </div>
          </div>
          <h4 className="form__subtitle">Céges adatok</h4>
          <FormSection name="company" className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">Cégnév</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder="Cégnév"
                />
              </div>
            </div>
            <div className="form-row">
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Adószám</span>
                  <div className="form__form-group-field">
                    <Field
                      name="tax_number"
                      component={RenderCleaveField}
                      type="text"
                      placeholder="xxxxxxxx-x-xx"
                      options={{
                        blocks: [8, 1, 2],
                        delimiter: '-',
                        numericOnly: true,
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Irányítószám</span>
                  <div className="form__form-group-field">
                    <Field
                      name="zip_code"
                      component={RenderField}
                      type="text"
                      placeholder="Irányítószám"
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Város</span>
              <div className="form__form-group-field">
                <Field
                  name="city"
                  component={RenderField}
                  type="text"
                  placeholder="Város"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Utca, házszám</span>
              <div className="form__form-group-field">
                <Field
                  name="street"
                  component={RenderField}
                  type="text"
                  placeholder="Utca, házszám"
                />
              </div>
            </div>
          </FormSection>
          <h4 className="form__subtitle">Kapcsolattartó adatai</h4>
          <div className="form__form-group">
            <span className="form__form-group-label">Teljes név</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component={RenderField}
                type="text"
                placeholder="Teljes név"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Telefonszám</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                +36
              </div>
              <Field
                name="phone_number"
                component={RenderCleaveField}
                type="text"
                placeholder="Telefonszám"
                options={{
                  blocks: [9],
                  numericOnly: true,
                }}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Kapcsolattartó email címe</span>
            <div className="form__form-group-field">
              <Field
                name="contact_email"
                component={RenderField}
                type="text"
                placeholder="Kapcsolattartó email címe"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Elfogadom az
              <Link to="/files/terms-and-conditions.docx" target="_blank" download> adatkezelési nyilatkozatot</Link>
            </span>
            <div className="form__form-group-field">
              <Field
                name="terms_and_conditions"
                component={renderToggleButtonField}
                onChange={(e) => setDisabled(!e)}
              />
            </div>
          </div>
          <div className="account__btns">
            <button
              onClick={onShowSpinner}
              className="btn btn-success account__btn"
              type="submit"
              disabled={disabled}
            >
              Regisztráció
            </button>
          </div>
        </form>
      )}
    </Auxiliary>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { signUp: register })(reduxForm({
  form: 'register_form',
})(withTranslation('common')(RegisterForm)));
