import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import RenderField from '../../../shared/components/form/RenderField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import useAvailableMenuItemLangs from '../../../shared/utility/useAvailableMenuItemLangs';

const MenuItemLangForm = ({ form, path }) => {
  const { t } = useTranslation();
  const { menuItemId } = useParams();
  const availableLangs = useAvailableMenuItemLangs(menuItemId);
  const languageList = availableLangs?.length
    ? availableLangs.map((lang) => ({ value: lang.id, label: lang.name }))
    : [{ value: null, label: 'Loading languages...' }];

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          {form === 'create' && (
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:language')}</span>
              <div className="form__form-group-field">
                <Field
                  name="language"
                  component={RenderSelectField}
                  type="text"
                  options={languageList}
                />
              </div>
            </div>
          </Col>
          )}
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('cmsMenu:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('menuItemLang:name')}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

MenuItemLangForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

MenuItemLangForm.defaultProps = {
  form: '',
  path: '/menus',
};

export default MenuItemLangForm;
