import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import MenuMain from './components/MenuMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';
import { fetchMenuEntry } from '../../../redux/actions/cmsMenuActions';

const MenuPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useAuthCheck();

  useEffect(() => {
    if (isAuthenticated === true) {
      const path = `/api/menus/${id}`;
      dispatch(fetchMenuEntry(path));
    }
  }, [dispatch, id, isAuthenticated]);

  const menu = useSelector((state) => {
    const menuObject = state.cms.menu.menupoints.find((element) => element.id === +id);
    return menuObject || {};
  });

  const statusCode = useSelector((state) => (
    state.status.statusCode || null
  ));

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="menu-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('cmsMenu:view')}</h3>
            </Col>
          </Row>
          <Row>
            <MenuMain data={menu} />
          </Row>
        </div>
        <BackButton path="/menus" />
      </Container>
    </PageHandler>
  );
};

export default MenuPage;
