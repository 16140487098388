import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ArrowDownDropCircleOutlineIcon from 'mdi-react/ArrowDownDropCircleOutlineIcon';
import ArrowUpDropCircleOutlineIcon from 'mdi-react/ArrowUpDropCircleOutlineIcon';
import { Col, Collapse } from 'reactstrap';
import { Field } from 'redux-form';
import { useParams } from 'react-router-dom';
import RenderField from '../../../shared/components/form/RenderField';
import RenderRichTextareaField from '../../../shared/components/form/RenderRichTextareaField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import useAvailableContentLangs from '../../../shared/utility/useAvailableContentLangs';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import PhotosForm from '../../../shared/components/form/PhotosForm';
import DocumentsForm from '../../../shared/components/form/DocumentsForm';
import VideoForm from '../../../shared/components/form/VideoForm';

const ContentLangForm = ({ form, path }) => {
  const { t } = useTranslation();
  const [isImgUploadOpen, setIsImgUploadOpen] = useState();
  const [isDocUploadOpen, setIsDocUploadOpen] = useState();
  const [isVidUploadOpen, setIsVidUploadOpen] = useState();
  const { contentId } = useParams();
  const availableLangs = useAvailableContentLangs(contentId);
  const languageList = availableLangs?.length
    ? availableLangs.map((lang) => ({ value: lang.id, label: lang.name }))
    : [{ value: null, label: 'Loading languages...' }];

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          {form === 'create' && (
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:language')}</span>
              <div className="form__form-group-field">
                <Field
                  name="language"
                  component={RenderSelectField}
                  type="text"
                  options={languageList}
                />
              </div>
            </div>
          </Col>
          )}
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:title')}</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:title')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:subtitle')}</span>
              <div className="form__form-group-field">
                <Field
                  name="subtitle"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:subtitle')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:writerName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="writer_name"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:writerName')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:writerEmail')}</span>
              <div className="form__form-group-field">
                <Field
                  name="writer_email"
                  component={RenderField}
                  type="email"
                  placeholder={t('contentLang:writerEmail')}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:htmlContent')}</span>
              <div className="form__form-group-field">
                <Field
                  name="html_content"
                  component={RenderRichTextareaField}
                  type="text"
                  placeholder={t('contentLang:htmlContent')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:htmlTitle')}</span>
              <div className="form__form-group-field">
                <Field
                  name="html_title"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:htmlTitle')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:htmlKeywords')}</span>
              <div className="form__form-group-field">
                <Field
                  name="html_keywords"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:htmlKeywords')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:htmlDescription')}</span>
              <div className="form__form-group-field">
                <Field
                  name="html_description"
                  component={RenderField}
                  type="text"
                  placeholder={t('contentLang:htmlDescription')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:jumpToUrl')}</span>
              <div className="form__form-group-field">
                <Field
                  name="jump_to_url"
                  component={RenderField}
                  type="url"
                  placeholder={t('contentLang:jumpToUrl')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:friendlyUrl')}</span>
              <div className="form__form-group-field">
                <Field
                  name="friendly_url"
                  component={RenderField}
                  type="url"
                  placeholder={t('contentLang:friendlyUrl')}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('contentLang:openInNewWindow')}</span>
              <div className="form__form-group-field">
                <Field
                  name="open_in_new_window"
                  component={RenderField}
                  type="checkbox"
                  placeholder={t('contentLang:openInNewWindow')}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <button type="button" className="toggle-btn" onClick={() => { setIsImgUploadOpen(!isImgUploadOpen); }}>
              Toggle Image Upload
              {isImgUploadOpen
                ? <ArrowUpDropCircleOutlineIcon size={25} />
                : <ArrowDownDropCircleOutlineIcon size={25} />}
            </button>
            <Collapse isOpen={isImgUploadOpen}>
              <PhotosForm label={t('contentLang:pictures')} />
            </Collapse>
          </Col>
          <Col md={12}>
            <button type="button" className="toggle-btn" onClick={() => { setIsDocUploadOpen(!isDocUploadOpen); }}>
              Toggle Document Upload
              {isDocUploadOpen
                ? <ArrowUpDropCircleOutlineIcon size={25} />
                : <ArrowDownDropCircleOutlineIcon size={25} />}
            </button>
            <Collapse isOpen={isDocUploadOpen}>
              <DocumentsForm label={t('contentLang:documents')} />
            </Collapse>
          </Col>
          <Col md={12}>
            <button type="button" className="toggle-btn" onClick={() => { setIsVidUploadOpen(!isVidUploadOpen); }}>
              Toggle Video Upload
              {isVidUploadOpen
                ? <ArrowUpDropCircleOutlineIcon size={25} />
                : <ArrowDownDropCircleOutlineIcon size={25} />}
            </button>
            <Collapse isOpen={isVidUploadOpen}>
              <VideoForm label={t('contentLang:videos')} />
            </Collapse>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

ContentLangForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

ContentLangForm.defaultProps = {
  form: '',
  path: '/contents',
};

export default ContentLangForm;
