import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import RenderTextareaField from '../../../shared/components/form/RenderTextareaField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';

const EventForm = (props) => {
  const {
    form,
    path,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('event:description')}</span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={RenderTextareaField}
                  type="text"
                  placeholder={t('event:description')}
                />
              </div>
            </div>
          </Col>
        </div>
        <Col md={2}>
          <div className="form__form-group required">
            <span className="form__form-group-label">{t('event:date')}</span>
            <div className="form__form-group-field">
              <Field
                name="event_date"
                component={renderDatePickerField}
                type="text"
              />
              <div className="form__form-group-icon">
                <CalendarBlankIcon />
              </div>
            </div>
          </div>
        </Col>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('event:recurrence')}</span>
              <div className="form__form-group-field">
                <Field
                  name="recurrence"
                  component={RenderField}
                  type="text"
                  placeholder={t('event:recurrence')}
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

EventForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

EventForm.defaultProps = {
  form: '',
  path: '/events',
};

export default EventForm;
