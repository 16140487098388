import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ProductionMain from './components/ProductionMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const ProductionPage = () => {
  const { t } = useTranslation('production');
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [production, setProduction] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const loadProduction = useCallback(async () => {
    const path = `/api/productions/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setProduction({
          ...res.data,
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadProduction();
    }
  }, [isAuthenticated, loadProduction]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="production-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('view')}</h3>
            </Col>
          </Row>
          <Row>
            {Object.keys(production).length === 0 || <ProductionMain data={production} />}
          </Row>
        </div>
        <BackButton path="/productions" />
      </Container>
    </PageHandler>
  );
};

export default ProductionPage;
