import {
  AUTH_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from '../actions/authActions';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  account: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        account: {
          email: payload.email,
          firstName: payload.first_name,
          lastName: payload.last_name,
          roles: payload.roles,
          permissions: payload.permissions,
          icon: payload.icon,
        },
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        account: null,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        token: null,
        isAuthenticated: false,
        loading: false,
        account: null,
      };
    default:
      return state;
  }
};
