import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Nav, NavItem, NavLink,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import MenuItemLangEditForm from './MenuItemLangEditForm';
import ConfirmPopup from '../../../../shared/components/ConfirmPopup';
import { deleteMenuEntry, fetchMenuItemList } from '../../../../redux/actions/cmsMenuActions';
import useAvailableMenuItemLangs from '../../../../shared/utility/useAvailableMenuItemLangs';

const MenuItemLangEditContainer = ({ data, id }) => {
  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  const [deletePath, setDeletePath] = useState('');
  const availableLangs = useAvailableMenuItemLangs(id);
  const hasAvailableLangs = availableLangs?.length > 0;

  const getActiveMenuItemLang = () => {
    const activeMenuItemLang = data?.length
      && data.find((menuItemLang) => menuItemLang.id === activeTab);
    return activeMenuItemLang;
  };

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    setItemToDelete(item.name);
    setDeletePath(`/api/menu-item-langs/${item.id}`);
    setIsModalOpen(true);
  };

  const onDeleteCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteConfirm = () => {
    dispatch(deleteMenuEntry(deletePath));
    dispatch(fetchMenuItemList('/api/menu-items'));
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!activeTab && data?.length) setActiveTab(data[0].id);
  }, [activeTab, data]);

  const menuItemLangLinks = data?.length > 0 && data.map((menuItemLang) => (
    <NavItem key={menuItemLang.id}>
      <NavLink onClick={() => { setActiveTab(menuItemLang.id); }} className={`menu-item-edit-page__menu-link ${activeTab === menuItemLang.id ? 'active' : null}`}>
        {menuItemLang.language.name}
        <Tooltip title={t('common:actions:delete')} placement="bottom" arrow>
          <IconButton onClick={(e) => { handleDeleteClick(e, menuItemLang); }}>
            <DeleteIcon size={20} />
          </IconButton>
        </Tooltip>
      </NavLink>
    </NavItem>
  ));

  const createNewMenuItemLang = (
    <NavItem>
      <Tooltip title={t('common:actions:addNew')} placement="bottom" arrow>
        <NavLink tag={Link} to={`/menus/create-menu-item-lang/${id}`} className="menu-item-edit-page__menu-link menu-item-edit-page__menu-link--create">
          <PlusCircleOutlineIcon size={20} />
        </NavLink>
      </Tooltip>
    </NavItem>
  );

  return (
    <div className="menu-item-edit-page__edit-container">
      <ConfirmPopup
        isOpen={isModalOpen}
        item={itemToDelete}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
      <Nav tabs>
        {menuItemLangLinks}
        {hasAvailableLangs && createNewMenuItemLang}
      </Nav>
      {data?.length > 0 && (
      <MenuItemLangEditForm
        enableReinitialize
        initialValues={getActiveMenuItemLang()}
        onSubmit
      />
      )}
    </div>
  );
};

MenuItemLangEditContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  id: PropTypes.number,
};

MenuItemLangEditContainer.defaultProps = {
  id: null,
};

export default MenuItemLangEditContainer;
