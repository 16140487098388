import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import Gallery from '../../../../shared/components/Gallery';

const LocationMain = ({ data }) => {
  const { t } = useTranslation();
  const { location_references: images = [] } = data;
  const processedImages = images.map((image) => ({
    ...image,
    preview: image.src,
  }));

  return (
    <>
      <Col md={12} lg={6} xl={6}>
        <Card>
          <CardBody className="location__main">
            <h4>{data.name}</h4>
            <div className="location__description">
              <Editor value={data.description} disabled inline />
            </div>
            <p>
              {t('category')}
              {': '}
              {data.category}
            </p>
            <p>
              {t('capacity')}
              {': '}
              {data.capacity}
            </p>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={6} xl={6}>
        <Gallery images={processedImages} />
      </Col>
    </>
  );
};

LocationMain.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    capacity: PropTypes.number,
    location_references: PropTypes.array,
  }).isRequired,
};

export default LocationMain;
