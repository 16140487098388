import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { fetchLanguageList } from '../../../redux/actions/cmsContentActions';
import { fetchMenuEntry } from '../../../redux/actions/cmsMenuActions';
import MenuEditCard from './components/MenuEditCard';

const MenuEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const path = `/api/menus/${id}`;
    dispatch(fetchMenuEntry(path));
    dispatch(fetchLanguageList('/api/languages'));
  }, [dispatch, id]);

  return (
    <Container>
      <div>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('cmsMenu:edit')}</h3>
          </Col>
        </Row>
        <Row>
          <MenuEditCard />
        </Row>
      </div>
    </Container>
  );
};

export default MenuEdit;
