import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import PlaylistForm from '../../components/PlaylistForm';

const PlaylistEditForm = ({ handleSubmit }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const editPlaylist = (async (playlist) => {
    onShowSpinner();

    const clone = {};
    clone.devices = playlist.devices;
    clone.contents = playlist.contents;
    clone.playlist = {
      name: playlist.name,
      valid_from: playlist.valid_from,
      valid_to: playlist.valid_to,
    };

    const path = `/api/playlists/${id}`;
    const body = JSON.stringify({
      ...clone,
    });

    try {
      await axios.put(path, body);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace(`/playlists/show/${id}`);
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(editPlaylist)}>
      <PlaylistForm
        form="edit"
      />
    </form>
  );
};

PlaylistEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'playlist_edit_form',
  enableReinitialize: true,
})(PlaylistEditForm);

export default FormDecoratedComponent;
