import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { fetchLanguageList } from '../../../../redux/actions/cmsContentActions';
import { useAuthCheck } from '../../../../shared/utility/APIUtils';
import MenuLangCreateForm from './MenuLangCreateForm';
import useAvailableMenuLangs from '../../../../shared/utility/useAvailableMenuLangs';
import { fetchMenuEntry } from '../../../../redux/actions/cmsMenuActions';

const MenuLangCreateCard = () => {
  const isAuthenticated = useAuthCheck();
  const { menuId } = useParams();
  const availableLangs = useAvailableMenuLangs(menuId);
  const defaultLanguage = availableLangs?.length ? { lang: availableLangs[0].id } : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(fetchLanguageList('/api/languages'));
      dispatch(fetchMenuEntry(`/api/menus/${menuId}`));
    }
  }, [menuId, dispatch, isAuthenticated]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <MenuLangCreateForm initialValues={defaultLanguage} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

export default MenuLangCreateCard;
