import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import LiveFilterForm from './LiveFilterForm';

const LiveFilterCard = ({ refreshCallback }) => {
  const data = () => {
    const urlList = {};

    window.location.search
      .slice(1)
      .split('&')
      .forEach((item) => {
        const [key, val] = item.split('=');
        if (val !== '') {
          urlList[key] = decodeURIComponent(val);
        }
      });
    return urlList;
  };

  return (
    <Col md={12} lg={12}>
      <LiveFilterForm
        refreshCallback={refreshCallback}
        initialValues={data()}
      />
    </Col>
  );
};

LiveFilterCard.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
};

export default LiveFilterCard;
