import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import ContentLangCreateCard from './components/ContentLangCreateCard';

const ContentLangCreate = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('contentLang:create')}</h3>
          </Col>
        </Row>
        <Row>
          <ContentLangCreateCard />
        </Row>
      </div>
    </Container>
  );
};

export default ContentLangCreate;
