/* eslint-disable camelcase */
export default {
  en: {
    common: {
      saveSuccess: 'Item saved successfuly!',
      saveError: 'Error during saving item!',
      editSuccess: 'Item edited successfuly!',
      editError: 'Error during editing item!',
      deleteError: 'Error during deleting item!',
      deletePrompt: 'Are you sure to delete this item?',
      selectPrompt: 'Please select ...',
      selected: 'selected',
      error: 'An error has occurred!',
      accessDenied: 'You do not have permission to access this page!',
      notFound: 'Page not found!',
      success: 'Success!',
      description: 'Description',
      date: 'Date',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      rowsPerPage: 'Rows / page',
      rows: 'Rows',
      photos: 'Photos',
      filter: 'Filter',
      jsonPanel: 'JSON panel',
      viatorRequest: 'Viator request',
      viatorResponse: 'Viator response',
      details: 'Details',
      yes: 'Yes',
      no: 'No',
      invalidLinkError: 'Invalid link!',
      checkYourMail: 'Please check your mail!',
      registrationConfirmed: 'Your registration has been successfully confirmed!',
      actions: {
        label: 'Actions',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        accept: 'Accept',
        bookingView: 'Booking View',
        cancelBooking: 'Cancel booking',
        paymentView: 'Payment View',
        unlink: 'Unlink',
        addNew: 'Add New',
        permission: 'Permission',
        details: 'Details',
        print: 'Print',
        createNew: 'Create New',
        linkExisting: 'Link Existing',
        clearSelection: 'Clear Selection',
        add: 'Add',
        remove: 'Remove',
      },
      buttons: {
        cancel: 'Cancel',
        back: 'Back',
        next: 'Next',
        modify: 'Modify',
        save: 'Save',
      },
    },
    login: {
      email: 'Email',
      password: 'Password',
      login: 'Login',
      signup: 'Sign up',
      forgotPassword: 'Forgot password',
    },
    forgot: {
      email: 'Email',
      submit: 'Reset password',
      back: 'Back',
    },
    reset: {
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      submit: 'Save',
      login: 'Login',
    },
    menu: {
      productionsAndEvents: 'Productions and events',
      events: 'Events',
      productions: 'Productions',
      locations: 'Locations',
      ticketTypes: 'Ticket types',
      options: 'Options',
      users: 'Users',
      settings: 'Settings',
      profile: {
        approveUsers: 'Approve users',
        approveListItems: 'Approve list items',
        logout: 'Log out',
      },
      allpay: {
        title: 'Allpay',
        devices: 'Devices',
        deviceStatuses: 'Device Statuses',
        deviceStatusLogs: 'Device Status Logs',
        transactions: 'Transactions',
        commands: 'Commands',
        products: 'Products',
        playlists: 'Playlist',
        live: 'Live',
      },
      cms: {
        title: 'CMS',
        menupoints: 'Menu',
        contents: 'Contents',
      },
      bookings: {
        title: 'Bookings',
        bookings: 'Bookings',
        cancellations: 'Cancellations',
        payments: 'Payments',
      },
    },
    account: {
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phoneNumber: 'Phone number',
      birthDate: 'Date of birth',
      role: 'Role',
      confirmed: 'Confirmed',
      notConfirmed: 'Not confirmed',
      enabled: 'Enabled',
      notEnabled: 'Not enabled',
      list: 'User list',
      create: 'New user',
      view: 'View user',
      edit: 'Edit user',
      approveList: 'List of users waiting for approval',
    },
    event: {
      list: 'Event list',
      description: 'Description',
      date: 'Date',
      recurrence: 'Recurrence',
      create: 'New event',
      view: 'View event',
      edit: 'Edit event',
    },
    location: {
      self: 'Location',
      name: 'Name',
      description: 'Description',
      list: 'Location list',
      create: 'New location',
      view: 'View location',
      edit: 'Edit location',
      category: 'Category',
      capacity: 'Capacity',
      fetchFailed: 'Fetching locations failed!',
    },
    ticketType: {
      list: 'Ticket type list',
      create: 'New ticket type',
      name: 'Name',
      description: 'Description',
      discount: 'Discount',
      fromAge: 'Minimum age',
      toAge: 'Maximum age',
      age: 'Age',
      view: 'View ticket type',
      edit: 'Edit ticket type',
    },
    deviceStatus: {
      name: 'Status name',
      list: 'Device status list',
      create: 'New device status',
      statusName: 'Status name',
      severityName: 'Severity name',
      edit: 'Edit device status',
      view: 'View device status',
    },
    deviceStatusLog: {
      name: 'Device status log name',
      list: 'Device status log list',
      device: 'Device',
      deviceStatus: 'Device status',
      severity: 'Severity',
      note: 'Note',
      start: 'Start',
      end: 'End',
      duration: 'Duration',
    },
    severity: {
      fetchFailed: 'Fetching severities failed!',
    },
    cmsMenu: {
      name: 'Name',
      view: 'View menu',
      create: 'Create menu',
      edit: 'Edit menu',
      menuItemCreate: 'Create menu item',
      menuItemEdit: 'Edit menu item',
      fetchFailed: 'Fetching menus failed!',
    },
    cmsMenuItem: {
      name: 'Name',
      content: 'Content',
      view: 'View menu item',
      create: 'Create menu item',
      edit: 'Edit menu item',
      fetchFailed: 'Fetching menu items failed!',
      contentManager: 'Content Manager',
      addExistingContent: 'Add Existing Content',
    },
    menuLang: {
      name: 'Name',
      create: 'Create menu language',
    },
    content: {
      create: 'New content',
      list: 'Content list',
      view: 'View content',
      edit: 'Edit content',
      title: 'Title',
      menuItem: 'Menu item',
    },
    contentLang: {
      create: 'New content language',
      language: 'Language',
      title: 'Title',
      subtitle: 'Subtitle',
      writerName: 'Writer name',
      writerEmail: 'Writer email',
      htmlContent: 'HTML Content',
      htmlTitle: 'HTML Title',
      htmlKeywords: 'HTML Keywords',
      htmlDescription: 'HTML Description',
      jumpToUrl: 'Jump to URL',
      friendlyUrl: 'Friendly URL',
      openInNewWindow: 'Open in new window',
      pictures: 'Pictures',
      documents: 'Documents',
      videos: 'Videos',
    },
    production: {
      self: 'Production',
      name: 'Name',
      shortDescription: 'Short description',
      longDescription: 'Long description',
      location: 'Location',
      list: 'Production list',
      create: 'New production',
      view: 'View production',
      edit: 'Edit production',
      contributors: 'Contributors',
      category: 'Category',
    },
    listItems: {
      list: 'Approve list items',
      listName: 'List name',
      itemName: 'Item name',
      author: 'Author',
    },
    device: {
      list: 'Device list',
      create: 'New device',
      view: 'View device',
      edit: 'Edit device',
      name: 'Name',
      description: 'Description',
      vpnIp: 'VPN IP',
      apNumber: 'AP number',
      lastSignOfLife: 'Last sign of life',
    },
    permission: {
      title: 'Permission settings',
      group: {
        MANAGE_USERS: 'Manage users',
      },
      item: {
        ALLOW_USER_VIEW: 'Allow view',
        ALLOW_USER_EDIT: 'Allow edit',
        ALLOW_USER_PERMISSION: 'Allow permission',
      },
    },
    transaction: {
      changedue: 'Changedue',
      customerName: 'Customer name',
      customerEmail: 'Customer email',
      customerPhoneNumber: 'Customer phone number',
      date: 'Date',
      description: 'Description',
      details: 'Transaction details',
      device: 'Device',
      discount: 'Discount',
      list: 'Transaction list',
      paidAmount: 'Paid amount',
      paymentType: 'Payment type',
      price: 'Unit price',
      receipt: 'EJ data',
      quantity: 'Quantity',
      ticketType: 'Ticket type',
      transactionId: 'Transaction number',
      transactionType: 'Transaction type',
      value: 'Value',
      view: 'View transaction',
    },
    deviceCommand: {
      list: 'Command list',
      deviceStatus: 'Device status',
      command: 'Command',
      device: 'Device',
      parameter: 'Parameter',
      executedAt: 'Executed at',
      create: 'New command',
    },
    deviceProduct: {
      list: 'Product list',
      productCode: 'Product code',
      title: 'Title',
      numberOfQuestions: 'Number of questions',
      onKiosk: 'On kiosk',
    },
    playlist: {
      list: 'Playlist list',
      view: 'View playlist',
      name: 'Playlist name',
      validFrom: 'Valid from',
      validTo: 'Valid to',
      create: 'New playlist',
      devices: 'Devices',
      contents: 'Contents',
      edit: 'Edit playlist',
    },
    transferList: {
      allSelected: 'all items selected',
      selected: 'selected',
      choices: 'Choices',
      chosen: 'Chosen',
    },
    live: {
      deviceId: 'ID',
      deviceName: 'Name',
      deviceStatusName: 'Device status',
      severity: 'Severity',
      vpnIp: 'VPN IP',
      lastSignOfLife: 'Last sign of life',
      deviceStatuses: 'Device status',
      live: 'Live',
      reload: 'Automatic reload',
      note: 'Note',
    },
    booking: {
      list: 'Booking list',
      view: 'View booking',
      cancel: 'Cancel booking',
      reference: 'Reference',
      status: 'Status',
      productCode: 'Product code',
      productOptionCode: 'Option code',
      travelDate: 'Travel date',
      startTime: 'Start time',
      travellerName: 'Traveller name',
      channel: 'Channel',
    },
    cancellation: {
      list: 'Cancellation list',
      view: 'View cancellation',
      bookingReference: 'Booking reference',
      status: 'Status',
      cancelQuote: 'Cancel quote',
      cancel: 'Cancel',
      getQuote: 'Get quote',
      itemPrice: 'Item price',
      refundAmount: 'Refund amount',
      currencyCode: 'Currency code',
      refundPercentage: 'Refund percentage',
      why: 'What is your reason for cancelling?',
      reason: 'Reason',
      cancelSuccess: 'Cancellation successfuly!',
      cancelError: 'Error during cancellation!',
      cybersourceRequest: 'Cybersource request',
      cybersourceResponse: 'Cybersource response',
    },
    payment: {
      list: 'Payment list',
      view: 'View payment',
      bookingReference: 'Booking reference',
      transactionId: 'Transaction id',
      decision: 'Decision',
      cybersourceRequest: 'Cybersource request',
      cybersourceResponse: 'Cybersource response',
      cybersourceCaptureRequest: 'Cybersource capture request',
      cybersourceCaptureResponse: 'Cybersource capture response',
      captureStatus: 'Capture status',
    },
    settings: {
      create: 'New settings',
      edit: 'Edit settings',
      margin: 'Margin',
    },
  },
};
