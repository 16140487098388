import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import ConfirmPopup from '../../shared/components/ConfirmPopup';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import MaterialTable from '../../shared/components/table/MaterialTable';
import PageHandler from '../../shared/components/PageHandler';
import { setAlert } from '../../redux/actions/alertActions';

const ProductionList = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const operations = ['show', 'edit', 'delete'];
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/productions${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list);
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'name', label: t('production:name'),
    },
    {
      id: 'contributors', label: t('production:contributors'),
    },
    {
      id: 'category', label: t('production:category'),
    },
  ];

  const showDeleteConfirmation = (id) => {
    const item = rows.find((row) => row.id === id);
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onConfirmDelete = useCallback(async () => {
    setIsModalOpen(false);
    onShowSpinner();
    const path = `/api/productions/${itemToDelete.id}`;
    await axios.delete(path)
      .then(() => {
        fetchData();
      }).catch(() => {
        dispatch(setAlert(t('common:deleteError'), 'danger'));
        onHideSpinner();
      });
  }, [dispatch, fetchData, itemToDelete, onHideSpinner, onShowSpinner, t]);

  const actionCallbacks = {
    delete: showDeleteConfirmation,
  };

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <ConfirmPopup
          isOpen={isModalOpen}
          item={itemToDelete && itemToDelete.name}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('production:list')}</h3>
          </Col>
          <Col md={4}>
            <Link className="btn btn-success float-right" to={`${window.location.pathname}/create`}>Add New Production</Link>
          </Col>
        </Row>
        <Row>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            dependencyCells={['enabled']}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default ProductionList;
