import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PaymentMain from './components/PaymentMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const PaymentPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [payment, setPayment] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const loadPayment = useCallback(async () => {
    const path = `/api/payments/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setPayment({
          ...res.data,
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadPayment();
    }
  }, [isAuthenticated, loadPayment]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="payment-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('payment:view')}</h3>
            </Col>
          </Row>
          <Row>
            <PaymentMain data={payment} />
          </Row>
        </div>
        <BackButton path="/payments" />
      </Container>
    </PageHandler>
  );
};

export default PaymentPage;
