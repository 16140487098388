import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Container, Row, Col, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import Can from '../../shared/authorization/Can';
import TransactionFilterCard from './Filter/TransactionFilterCard';
import Transaction from './TransactionModal/index';

const TransactionList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `/api/transactions${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          created_at: format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const close = () => {
    setSelectedTransaction(null);
    setIsOpen(false);
    setActiveModal(null);
  };

  const showModal = (id) => {
    const item = rows.find((row) => row.id === id);
    setSelectedTransaction(item.id);
    setIsOpen(true);
    setActiveModal('details');
  };

  const showPrintPreview = (id) => {
    const item = rows.find((row) => row.id === id);
    setSelectedTransaction(item.id);
    setActiveModal('receipt');
    setIsOpen(true);
  };

  const actionCallbacks = {
    modalOpen: showModal,
    print: showPrintPreview,
  };

  const columns = [
    {
      id: 'transaction_type', label: t('transaction:transactionType'),
    },
    {
      id: 'device', label: t('transaction:device'),
    },
    {
      id: 'id', label: t('transaction:transactionId'),
    },
    {
      id: 'created_at', label: t('transaction:date'),
    },
    {
      id: 'paid_amount', label: t('transaction:paidAmount'),
    },
    {
      id: 'value', label: t('transaction:value'),
    },
    {
      id: 'changedue', label: t('transaction:changedue'),
    },
    {
      id: 'payment_type', label: t('transaction:paymentType'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        {(activeModal !== null) && (
          <Modal returnFocusAfterClose={false} isOpen={isOpen} className="transaction-table-modal">
            <ModalHeader toggle={close} className="modal__header">
              {t('transaction:'.concat(activeModal))}
            </ModalHeader>
            <ModalBody className="modal__body">
              <Transaction id={selectedTransaction} activeModal={activeModal} />
            </ModalBody>
          </Modal>
        ) }
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('transaction:list')}</h3>
          </Col>
        </Row>
        <Row>
          <Can
            perform="transactions:modal"
            yes={() => {
              operations.push('modal');
              return null;
            }}
          />
          <Can
            perform="transactions:print"
            yes={() => {
              operations.push('print');
              return null;
            }}
          />
          <Col><TransactionFilterCard refreshCallback={fetchData} /></Col>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default TransactionList;
