import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import TicketTypeForm from '../../components/TicketTypeForm';

const TicketTypeCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const { t } = useTranslation();

  const saveTicketType = (async (ticketType) => {
    onShowSpinner();
    const clone = {
      ...ticketType,
    };

    const body = JSON.stringify(clone);
    const path = '/api/ticket-types';

    try {
      await axios.post(path, body);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/ticket-types');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveTicketType)}>
      <TicketTypeForm
        form="create"
      />
    </form>
  );
};

TicketTypeCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'ticket_type_create_form' })(TicketTypeCreateForm);
