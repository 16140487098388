import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import DeviceCreateForm from './DeviceCreateForm';

const DeviceCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <DeviceCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default DeviceCreateCard;
