import i18next from 'i18next';
import { setAlert } from './alertActions';
import { setStatus } from './statusActions';
import { arrangeCards, fetchHelper } from '../helpers/cmsHelper';

export const SET_MENU_ITEM = 'SET_MENU_ITEM';
export const SET_MENUPOINTS = 'SET_MENUPOINTS';
export const SET_MENU = 'SET_MENU';
export const SET_MENU_ITEM_LIST = 'SET_MENU_ITEM_LIST';
export const UPDATE_MENUPOINTS = 'UPDATE_MENUPOINTS';

export const setMenupoints = (menupoints) => ({
  type: SET_MENUPOINTS,
  payload: menupoints,
});

export const setMenu = (menu) => ({
  type: SET_MENU,
  payload: menu,
});

export const setMenuItem = (menuItem) => ({
  type: SET_MENU_ITEM,
  payload: menuItem,
});

export const setMenuItemList = (payload) => ({
  type: SET_MENU_ITEM_LIST,
  payload,
});

export const fetchMenupoints = () => async (dispatch) => fetchHelper({
  dispatch,
  method: 'GET',
  path: '/api/menus',
  withSpinner: true,
}).then((result) => {
  const { data: fetchedMenupoints = [] } = result;
  dispatch(setMenupoints(fetchedMenupoints));
}).catch(() => {
  dispatch(setAlert(i18next.t('cmsMenu:fetchFailed'), 'danger'));
});

export const deleteMenuEntry = (path) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'DELETE',
  path,
  withSpinner: true,
});

export const createMenuEntry = (
  path, body, errorChaining = false,
) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'POST',
  path,
  body,
  withSpinner: true,
}).then(() => {
  dispatch(setAlert(i18next.t('common:saveSuccess'), 'success'));
  window.location.replace('/menus');
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:saveError'), 'danger'));
  if (errorChaining) throw err;
});

export const editMenuEntry = (
  path, body, errorChaining = false,
) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'PUT',
  path,
  body,
  withSpinner: true,
}).then(() => {
  dispatch(setAlert(i18next.t('common:editSuccess'), 'success'));
  window.location.replace('/menus');
}).catch((error) => {
  dispatch(setAlert(i18next.t('common:editError'), 'danger'));
  if (errorChaining) throw error;
});

export const syncMenuEntry = (path, body) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'PUT',
  path,
  body,
});

export const fetchMenuEntry = (path) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'GET',
  path,
  withSpinner: true,
}).then((response) => {
  dispatch(setMenu(response.data));
  dispatch(setStatus(response.status));
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:error'), 'danger'));
  dispatch(setStatus(err.response?.status));
});

export const fetchMenuItemEntry = (path) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'GET',
  path,
  withSpinner: true,
}).then((response) => {
  dispatch(setMenuItem(response.data));
  dispatch(setStatus(response.status));
}).catch((err) => {
  dispatch(setAlert(i18next.t('common:error'), 'danger'));
  dispatch(setStatus(err.response.status));
});

export const fetchMenuItemList = (path) => async (dispatch) => fetchHelper({
  dispatch,
  method: 'GET',
  path,
}).then((response) => {
  dispatch(setMenuItemList(response.data));
}).catch(() => {
  dispatch(setAlert(i18next.t('cmsMenuItem:fetchFailed'), 'danger'));
});

export const arrangeMenuCards = (hoverId, dropId) => async (dispatch, getState) => {
  const cloneMenupoints = [...getState().cms.menu.menupoints];

  const { display_order: origin } = cloneMenupoints.find((mp) => mp.id === hoverId);
  const { display_order: destination } = cloneMenupoints.find((mp) => mp.id === dropId);

  const arrangedMenuPoints = arrangeCards(cloneMenupoints, origin, destination, hoverId);

  dispatch(setMenupoints(arrangedMenuPoints));
  arrangedMenuPoints.forEach((mp) => {
    const original = cloneMenupoints.find((orig) => mp.id === orig.id);
    if (original.display_order !== mp.display_order) {
      const path = `api/menus/${mp.id}`;
      const body = {
        display_order: mp.display_order,
      };
      dispatch(syncMenuEntry(path, body));
    }
  });
};

export const arrangeMenuItemCards = (hoverId, dropId, menuId) => async (dispatch, getState) => {
  const cloneMenupoints = [...getState().cms.menu.menupoints];
  const containerMenuIndex = cloneMenupoints.findIndex((mp) => mp.id === menuId);
  const clonedMenuItems = cloneMenupoints[containerMenuIndex].menuItems;

  const { display_order: origin } = clonedMenuItems.find((mp) => mp.id === hoverId);
  const { display_order: destination } = clonedMenuItems.find((mp) => mp.id === dropId);

  const arrangedMenuItems = arrangeCards(clonedMenuItems, origin, destination, hoverId);

  cloneMenupoints[containerMenuIndex].menuItems = arrangedMenuItems;

  dispatch(setMenupoints(cloneMenupoints));
  arrangedMenuItems.forEach((mp) => {
    const original = clonedMenuItems.find((orig) => mp.id === orig.id);
    if (original.display_order !== mp.display_order) {
      const path = `api/menu-items/${mp.id}`;
      const body = {
        display_order: mp.display_order,
      };
      dispatch(syncMenuEntry(path, body));
    }
  });
};
