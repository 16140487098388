import { useSelector } from 'react-redux';

const useAvailableContentLangs = (contentId) => {
  const result = useSelector((state) => {
    const { languages = [] } = state.cms.content;
    const parentContent = state.cms.content.contents.find(
      (content) => content.id === +contentId,
    );
    const siblingContentLangs = parentContent?.content_langs;
    const usedLangs = siblingContentLangs?.map((cl) => cl.language);
    const availableLangs = languages.filter((lang) => {
      const hasMatch = usedLangs?.find((usedLang) => usedLang.id === lang.id);
      return !hasMatch;
    });
    return availableLangs;
  });
  return result;
};

export default useAvailableContentLangs;
