import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Col, Row, Spinner } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import RenderField from '../../../shared/components/form/RenderField';
import convertToURL from '../../../shared/utility/filter';
import { fetchContents } from '../../../redux/actions/cmsContentActions';

const ContentFilterForm = (props) => {
  const {
    handleSubmit,
  } = props;
  const [isChanging, setIsChanging] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const sendFilter = useCallback(async (filter) => {
    const copy = { ...filter };
    copy.page = '0';

    const filterString = convertToURL(copy);
    history.replace(`${window.location.pathname}?${filterString}`);
    setIsChanging(false);
    setIsRequesting(true);
    dispatch(fetchContents(`/api/contents?${filterString}`, false)).then(() => { setIsRequesting(false); });
  }, [dispatch, history]);

  const debouncedSubmit = useMemo(() => debounce(handleSubmit(sendFilter), 400),
    [handleSubmit, sendFilter]);

  const handleChange = () => {
    debouncedSubmit();
    setIsChanging(true);
  };

  useEffect(() => () => {
    debouncedSubmit.cancel();
  }, [debouncedSubmit]);

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={12}>
        <Row>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('content:title')}</span>
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component={RenderField}
                  type="text"
                  onChange={handleChange}
                />
                <Spinner
                  style={{ visibility: (isChanging || isRequesting) ? 'visible' : 'hidden' }}
                  type={isRequesting ? 'border' : 'grow'}
                  className={classNames('spinner', isRequesting && 'spinner--requesting')}
                  size="sm"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </form>
  );
};

ContentFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'content_filter',
  enableReinitialize: true,
})(ContentFilterForm);

export default FormDecoratedComponent;
