import React from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const Register = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <div className="account__logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <RegisterForm initialValues={{ company: {} }} onSubmit />
        <div className="account__have-account">
          <p>
            Már van fiókja?
            {' '}
            <Link to="/login">Bejelentkezés</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Register;
