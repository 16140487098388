import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import LinkVariantOffIcon from 'mdi-react/LinkVariantOffIcon';

const LinkErrorCard = () => {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="link-confirmation">
            <div className="link-confirmation__icon">
              <LinkVariantOffIcon className="link-confirmation__link" />
            </div>
            <h3 className="link-confirmation__title">{t('common:invalidLinkError')}</h3>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LinkErrorCard;
