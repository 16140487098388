import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PermissionForm from './components/PermissionForm';
import {
  getPermissionSlugList,
  getPermissionGroupSlugList,
} from './components/permissionHelper';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import { useAuthCheck } from '../../shared/utility/APIUtils';

const Permission = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [optimizeData, setOptimizeData] = useState([]);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();

    let actPermissionGroups = [];
    await axios.get('/api/permission-groups')
      .then((res) => {
        setPermissions(getPermissionSlugList(res.data));
        actPermissionGroups = res.data;
        setPermissionGroups(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      });

    await axios.get(`/api/user-permissions/list/${id}`)
      .then((res) => {
        setStatusCode(res.status);

        const initialValues = {};
        getPermissionGroupSlugList(res.data, actPermissionGroups)
          .forEach((item) => { initialValues[item] = true; });
        res.data.forEach((item) => { initialValues[item.slug] = true; });
        setOptimizeData(initialValues);
      }).catch((err) => {
        setStatusCode(err.response.status);
      });

    onHideSpinner();
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  return (
    <PageHandler statusCode={statusCode}>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <PermissionForm
              permissions={permissions}
              permissionGroups={permissionGroups}
              initialValues={optimizeData}
              onSubmit
            />
          </CardBody>
        </Card>
      </Col>
    </PageHandler>
  );
};

export default Permission;
