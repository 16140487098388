import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../shared/components/PrivateRoute';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Alert from '../../shared/components/Alert';
import Spinner from '../../shared/components/Spinner';
import EmailConfirmation from '../Account/EmailConfirmation/index';
import Forgot from '../Account/Forgot/index';
import LogIn from '../Account/Login/index';
import Register from '../Account/Register/index';
import Reset from '../Account/Reset/index';
import AccountList from '../Account/index';
import AccountPage from '../Account/AccountPage/index';
import AccountEdit from '../Account/AccountEdit/index';
import AccountCreate from '../Account/AccountCreate/index';
import AccountApproveList from '../Account/AccountApproveList/index';
import AppSettingsPage from '../AppSettings/index';
import BookingList from '../Booking/index';
import BookingPage from '../Booking/BookingPage/index';
import BookingCancelPage from '../Booking/BookingCancelPage/index';
import CancellationList from '../Cancellation/index';
import CancellationPage from '../Cancellation/CancellationPage/index';
import ContentList from '../Content/index';
import ContentPage from '../Content/ContentPage/index';
import ContentEdit from '../Content/ContentEdit/index';
import ContentCreate from '../Content/ContentCreate/index';
import ContentLangCreate from '../Content/ContentLangCreate/index';
import EventList from '../Event/index';
import EventPage from '../Event/EventPage/index';
import EventEdit from '../Event/EventEdit/index';
import EventCreate from '../Event/EventCreate/index';
import LocationList from '../Location/index';
import LocationPage from '../Location/LocationPage/index';
import LocationEdit from '../Location/LocationEdit/index';
import LocationCreate from '../Location/LocationCreate/index';
import MenuList from '../Menu';
import MenuPage from '../Menu/MenuPage';
import MenuCreate from '../Menu/MenuCreate';
import MenuEdit from '../Menu/MenuEdit';
import MenuItemCreate from '../Menu/MenuItemCreate';
import MenuItemEdit from '../Menu/MenuItemEdit';
import MenuItemPage from '../Menu/MenuItemPage';
import MenuLangCreate from '../Menu/MenuLangCreate/index';
import MenuItemLangCreate from '../Menu/MenuItemLangCreate/index';
import PaymentList from '../Payment/index';
import PaymentPage from '../Payment/PaymentPage/index';
import PlaylistList from '../Playlist/index';
import PlaylistEdit from '../Playlist/PlaylistEdit/index';
import PlaylistPage from '../Playlist/PlaylistPage';
import PlaylistCreate from '../Playlist/PlaylistCreate/index';
import ProfilePage from '../Profile/index';
import ProductionList from '../Production/index';
import ProductionEdit from '../Production/ProductionEdit/index';
import ProductionPage from '../Production/ProductionPage/index';
import ProductionCreate from '../Production/ProductionCreate/index';
import TicketTypeList from '../TicketType';
import TicketTypeCreate from '../TicketType/TicketTypeCreate';
import TicketTypeEdit from '../TicketType/TicketTypeEdit';
import TicketTypePage from '../TicketType/TicketTypePage';
import TransactionList from '../Transaction/index';
import DeviceList from '../Device/index';
import DevicePage from '../Device/DevicePage/index';
import DeviceEdit from '../Device/DeviceEdit/index';
import DeviceCreate from '../Device/DeviceCreate/index';
import DeviceStatusList from '../DeviceStatus/index';
import DeviceStatusPage from '../DeviceStatus/DeviceStatusPage/index';
import DeviceStatusEdit from '../DeviceStatus/DeviceStatusEdit/index';
import DeviceStatusCreate from '../DeviceStatus/DeviceStatusCreate/index';
import DeviceStatusLogList from '../DeviceStatusLog/index';
import DeviceCommandList from '../DeviceCommand/index';
import DeviceCommandCreate from '../DeviceCommand/DeviceCommandCreate/index';
import DeviceProductList from '../DeviceProduct/index';
import LiveList from '../Live/index';
import { useRole } from '../../shared/utility/APIUtils';
import { getHomePage } from '../../shared/utility/RoleHelper';
import PermissionSettings from '../Permission/index';

const Account = () => (
  <Switch>
    <Route exact path="/accounts" component={AccountList} />
    <Route path="/accounts/show/:id" component={AccountPage} />
    <Route path="/accounts/edit/:id" component={AccountEdit} />
    <Route path="/accounts/create" component={AccountCreate} />
    <Route path="/accounts/approve-list" component={AccountApproveList} />
    <Route path="/accounts/permission/:id" component={PermissionSettings} />
  </Switch>
);

const AppSettings = () => (
  <Switch>
    <Route exact path="/app-settings" component={AppSettingsPage} />
  </Switch>
);

const Booking = () => (
  <Switch>
    <Route exact path="/bookings" component={BookingList} />
    <Route path="/bookings/show/:id" component={BookingPage} />
    <Route path="/bookings/cancel/:id" component={BookingCancelPage} />
  </Switch>
);

const Cancellation = () => (
  <Switch>
    <Route exact path="/cancellations" component={CancellationList} />
    <Route path="/cancellations/show/:id" component={CancellationPage} />
  </Switch>
);

const Content = () => (
  <Switch>
    <Route exact path="/contents" component={ContentList} />
    <Route path="/contents/show/:id" component={ContentPage} />
    <Route path="/contents/edit/:id" component={ContentEdit} />
    <Route path="/contents/create/:menuItemId?" component={ContentCreate} />
    <Route path="/contents/create-content-lang/:contentId" component={ContentLangCreate} />
  </Switch>
);

const Device = () => (
  <Switch>
    <Route exact path="/devices" component={DeviceList} />
    <Route path="/devices/show/:id" component={DevicePage} />
    <Route path="/devices/edit/:id" component={DeviceEdit} />
    <Route path="/devices/create" component={DeviceCreate} />
  </Switch>
);

const DeviceStatus = () => (
  <Switch>
    <Route exact path="/device-statuses" component={DeviceStatusList} />
    <Route path="/device-statuses/show/:id" component={DeviceStatusPage} />
    <Route path="/device-statuses/edit/:id" component={DeviceStatusEdit} />
    <Route path="/device-statuses/create" component={DeviceStatusCreate} />
  </Switch>
);

const DeviceStatusLog = () => (
  <Switch>
    <Route exact path="/device-status-logs" component={DeviceStatusLogList} />
  </Switch>
);

const DeviceCommand = () => (
  <Switch>
    <Route exact path="/device-commands" component={DeviceCommandList} />
    <Route path="/device-commands/create" component={DeviceCommandCreate} />
  </Switch>
);

const DeviceProduct = () => (
  <Switch>
    <Route exact path="/device-products" component={DeviceProductList} />
  </Switch>
);

const Event = () => (
  <Switch>
    <Route exact path="/events" component={EventList} />
    <Route path="/events/show/:id" component={EventPage} />
    <Route path="/events/edit/:id" component={EventEdit} />
    <Route path="/events/create" component={EventCreate} />
  </Switch>
);

const Location = () => (
  <Switch>
    <Route exact path="/locations" component={LocationList} />
    <Route path="/locations/show/:id" component={LocationPage} />
    <Route path="/locations/edit/:id" component={LocationEdit} />
    <Route path="/locations/create" component={LocationCreate} />
  </Switch>
);

const Menu = () => (
  <Switch>
    <Route exact path="/menus" component={MenuList} />
    <Route exact path="/menus/create-menu" component={MenuCreate} />
    <Route exact path="/menus/create-menu-item/:id" component={MenuItemCreate} />
    <Route exact path="/menus/edit-menu/:id" component={MenuEdit} />
    <Route exact path="/menus/edit-menu-item/:menuId/:id" component={MenuItemEdit} />
    <Route exact path="/menus/view-menu/:id" component={MenuPage} />
    <Route exact path="/menus/view-menu-item/:menuId/:id" component={MenuItemPage} />
    <Route exact path="/menus/create-menu-lang/:menuId" component={MenuLangCreate} />
    <Route exact path="/menus/create-menu-item-lang/:menuItemId" component={MenuItemLangCreate} />
  </Switch>
);

const Payment = () => (
  <Switch>
    <Route exact path="/payments" component={PaymentList} />
    <Route path="/payments/show/:id" component={PaymentPage} />
  </Switch>
);

const Playlist = () => (
  <Switch>
    <Route exact path="/playlists" component={PlaylistList} />
    <Route path="/playlists/show/:id" component={PlaylistPage} />
    <Route exact path="/playlists/create" component={PlaylistCreate} />
    <Route path="/playlists/edit/:id" component={PlaylistEdit} />
  </Switch>
);

const Profile = () => (
  <Route exact path="/profile" component={ProfilePage} />
);

const Production = () => (
  <Switch>
    <Route exact path="/productions" component={ProductionList} />
    <Route path="/productions/show/:id" component={ProductionPage} />
    <Route path="/productions/edit/:id" component={ProductionEdit} />
    <Route exact path="/productions/create" component={ProductionCreate} />
  </Switch>
);

const TicketType = () => (
  <Switch>
    <Route exact path="/ticket-types" component={TicketTypeList} />
    <Route path="/ticket-types/create" component={TicketTypeCreate} />
    <Route path="/ticket-types/edit/:id" component={TicketTypeEdit} />
    <Route path="/ticket-types/show/:id" component={TicketTypePage} />
  </Switch>
);

const Transaction = () => (
  <Switch>
    <Route exact path="/transactions" component={TransactionList} />
  </Switch>
);

const Live = () => (
  <Switch>
    <Route exact path="/live" component={LiveList} />
  </Switch>
);

const WrappedRoutes = () => {
  const homepage = getHomePage(useRole());
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route
          exact
          path="/"
          render={() => <Redirect replace to={homepage} />}
        />
        <Route path="/accounts" component={Account} />
        <Route path="/app-settings" component={AppSettings} />
        <Route path="/bookings" component={Booking} />
        <Route path="/cancellations" component={Cancellation} />
        <Route path="/contents" component={Content} />
        <Route path="/devices" component={Device} />
        <Route path="/device-statuses" component={DeviceStatus} />
        <Route path="/device-status-logs" component={DeviceStatusLog} />
        <Route path="/device-commands" component={DeviceCommand} />
        <Route path="/device-products" component={DeviceProduct} />
        <Route path="/events" component={Event} />
        <Route path="/locations" component={Location} />
        <Route path="/menus" component={Menu} />
        <Route path="/payments" component={Payment} />
        <Route path="/playlists" component={Playlist} />
        <Route path="/profile" component={Profile} />
        <Route path="/productions" component={Production} />
        <Route path="/ticket-types" component={TicketType} />
        <Route path="/transactions" component={Transaction} />
        <Route path="/live" component={Live} />
      </div>
    </div>
  );
};

const Router = () => (
  <MainWrapper>
    <main>
      <div className="alertbar">
        <Alert />
      </div>
      <Spinner />
      <Switch>
        <Route exact path="/email_confirmation" component={EmailConfirmation} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/reset" component={Reset} />
        <PrivateRoute path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
