import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import AppSettingsCreateForm from './AppSettingsCreateForm';

const AppSettingsCreateCard = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col md={12}>
        <h3 className="page-title">{t('settings:create')}</h3>
      </Col>
      <Col md={6} lg={4}>
        <Card>
          <CardBody>
            <AppSettingsCreateForm onSubmit />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default AppSettingsCreateCard;
