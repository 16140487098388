import React, { useCallback } from 'react';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import AccountForm from '../../components/AccountForm';

const AccountCreateForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const saveAccount = (async (account) => {
    onShowSpinner();
    const clone = {
      ...account,
    };
    if (account.roles === '' || account.roles === null) {
      delete clone.roles;
    }

    const body = JSON.stringify(clone);
    const path = '/api/users?mail=1';

    try {
      await axios.post(path, body);
      dispatch(setAlert(t('common:saveSuccess'), 'success'));
      window.location.replace('/accounts');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert(t('common:saveError'), 'danger'));
          break;
      }
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveAccount)}>
      <AccountForm form="create" />
    </form>
  );
};

AccountCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'account_create_form',
  enableReinitialize: true,
})(AccountCreateForm);

export default FormDecoratedComponent;
