import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import TicketTypeCard from './components/TicketTypeCard';
import BackButton from '../../../shared/components/form/BackButton';

const TicketTypePage = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [ticketType, setTicketType] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadTicketType = useCallback(async () => {
    onShowSpinner();
    const path = `/api/ticket-types/${id}`;
    await axios.get(path)
      .then((res) => {
        setTicketType(res.data);
        setLoading(false);
      }).catch(() => {
        setError(true);
      }).finally(() => {
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadTicketType();
    }
  }, [isAuthenticated, loadTicketType]);

  return (
    <form className="form">
      <Container>
        <div className="ticket-type-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('ticketType:view')}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {!loading && (
                  <TicketTypeCard data={ticketType} />
                  )}
                  {error ? <div>{t('common:error')}</div> : ''}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <BackButton path="/ticket-types" />
      </Container>
    </form>
  );
};

const ticketTypePageForm = reduxForm({ form: 'ticket_type_page_form' })(TicketTypePage);
export default ticketTypePageForm;
