import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField from '../../../shared/components/form/RenderField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';

const AppSettingsForm = ({ form, path }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('settings:margin')}</span>
        <div className="form__form-group-field">
          <Field
            name="margin"
            component={RenderField}
            type="number"
            normalize={(value) => value.replace(/[^\d]/g, '')}
            placeholder={t('settings:margin')}
          />
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

AppSettingsForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

AppSettingsForm.defaultProps = {
  form: '',
  path: '/app-settings',
};

export default AppSettingsForm;
