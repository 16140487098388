import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';

const WizardFormOne = ({ data, handleSubmit }) => {
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);
  const [response, setResponse] = useState(null);
  const [viatorError, setViatorError] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const getCancelQuote = useCallback(async () => {
    onShowSpinner();
    const path = `/booking-api/cancel/${data.partner_booking_ref}/cancel-quote`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        if (res.data.type && res.data.type === 'Viator error') {
          const detail = JSON.parse(res.data.detail);
          setViatorError(detail.message);
        } else {
          setResponse(res.data);
        }
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [data, onHideSpinner, onShowSpinner]);

  return (
    <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
      <h3 className="wizard__title">{data.partner_booking_ref}</h3>
      {viatorError !== null && (
      <div className="wizard__content">
        <div className="error">{viatorError}</div>
      </div>
      )}
      {response !== null && (
      <div className="wizard__content">
        {response.status && (
        <div>
          {t('cancellation:status')}
          {': '}
          {response.status}
        </div>
        )}
        {response.refundDetails && (
        <>
          <div>
            {t('cancellation:itemPrice')}
            {': '}
            {response.refundDetails.itemPrice}
          </div>
          <div>
            {t('cancellation:refundAmount')}
            {': '}
            {response.refundDetails.refundAmount}
          </div>
          <div>
            {t('cancellation:currencyCode')}
            {': '}
            {response.refundDetails.currencyCode}
          </div>
          <div>
            {t('cancellation:refundPercentage')}
            {': '}
            {response.refundDetails.refundPercentage}
          </div>
        </>
        )}
      </div>
      )}
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" onClick={getCancelQuote}>{t('cancellation:getQuote')}</Button>
        {statusCode === 200 && response !== null && response.status === 'CANCELLABLE' && (
          <Button color="btn btn-success" type="submit" className="next">{t('common:buttons:next')}</Button>
        )}
      </ButtonToolbar>
    </form>
  );
};

WizardFormOne.propTypes = {
  data: PropTypes.shape({
    partner_booking_ref: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(WizardFormOne);
