const convertToURL = (list) => Object.entries(list)
  .filter((item) => {
    const [name, val] = item;

    if (name === '' || val === null || typeof val === 'undefined') {
      return false;
    }

    const isFloat = Number(val) === val && val % 1 !== 0;
    if (Number.isInteger(val) || isFloat) {
      return true;
    }
    return val.length > 0;
  }).map((item) => {
    const [key, val] = item;

    if (val instanceof Array) {
      return `${key}=${val.join(',')}`;
    }

    return `${key}=${val}`;
  }).join('&');

export default convertToURL;
