import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

const noPhoto = `${process.env.PUBLIC_URL}/img/no_photo.png`;

const CustomModalFooterCount = ({ currentIndex, views }) => {
  const activeView = currentIndex + 1;
  const totalViews = views.length;

  if (!activeView || !totalViews) return null;
  return (
    <span className="">
      {activeView}
      {' '}
      /
      {' '}
      {totalViews}
    </span>
  );
};

CustomModalFooterCount.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Gallery = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentImagePreview, setCurrentImagePreview] = useState(0);

  const changeImg = (i, e) => {
    e.preventDefault();
    setCurrentImagePreview(i);
    setCurrentImage(i);
  };

  const openModal = (index, event) => {
    event.preventDefault();
    setCurrentImage(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(currentImagePreview);
    setModalIsOpen(false);
  };

  return (
    <div className="gallery">
      {Object.entries(images).length !== 0 ? (
        <>
          <a
            className="gallery__current-img"
            onClick={(e) => openModal(currentImage, e)}
            href={images[currentImage].src}
          >
            <img src={images[currentImagePreview].src} alt="img" />
          </a>
          <div className="gallery__gallery">
            {images.map((img, i) => (
              <button type="button" key={img.id} onClick={(e) => changeImg(i, e)} className="gallery__img-preview">
                <img src={img.src} alt="img" />
              </button>
            ))}
          </div>
          <ModalGateway>
            {modalIsOpen ? (
              <Modal
                onClose={closeModal}
                allowFullscreen={false}
              >
                <Carousel
                  currentIndex={currentImage}
                  components={{ FooterCount: CustomModalFooterCount }}
                  views={images}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      ) : (
        <div className="gallery__no_photo">
          <img src={noPhoto} alt="no_photo" />
        </div>
      )}
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    src: PropTypes.string,
  })).isRequired,
};

export default Gallery;
