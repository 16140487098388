import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { fetchContents, fetchLanguageList } from '../../../redux/actions/cmsContentActions';
import { fetchMenuItemList } from '../../../redux/actions/cmsMenuActions';
import setUrlQuery from '../../../shared/utility/urlModify';
import ContentManager from './components/ContentManager';
import MenuItemEditCard from './components/MenuItemEditCard';

const MenuItemEdit = () => {
  const { menuId, id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menuItem = useSelector((state) => {
    const menuItemFromList = state.cms.menu.menuItemList.find((mi) => mi.id === +id);
    return menuItemFromList || {};
  });

  const contents = useSelector((state) => state.cms.content.contents || []);

  useEffect(() => {
    dispatch(fetchMenuItemList('/api/menu-items'));
    dispatch(fetchLanguageList('/api/languages', false));
    setUrlQuery('menuItemId', id);
    dispatch(fetchContents(`/api/contents${window.location.search}`));
  }, [dispatch, menuId, id]);

  return (
    <Container>
      <div>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('cmsMenuItem:edit')}</h3>
          </Col>
        </Row>
        <Row>
          {menuItem && Object.keys(menuItem).length !== 0 && <MenuItemEditCard data={menuItem} />}
        </Row>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('cmsMenuItem:contentManager')}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <ContentManager contents={contents} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default MenuItemEdit;
