import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ContentMain from './components/ContentMain';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';
import { fetchSingleContent } from '../../../redux/actions/cmsContentActions';

const ContentPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useAuthCheck();

  useEffect(() => {
    if (isAuthenticated === true) {
      const path = `/api/contents/${id}`;
      dispatch(fetchSingleContent(path));
    }
  }, [dispatch, id, isAuthenticated]);

  const statusCode = useSelector((state) => (
    state.status.statusCode || null
  ));

  const content = useSelector((state) => {
    const contentObject = state.cms.content.contents.find((element) => element.id === +id);
    return contentObject || {};
  });

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="content-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('content:view')}</h3>
            </Col>
          </Row>
          <Row>
            <ContentMain data={content} />
          </Row>
        </div>
        <BackButton path="/contents" />
      </Container>
    </PageHandler>
  );
};

export default ContentPage;
