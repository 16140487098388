import axios from 'axios';
import { hideSpinner, showSpinner } from '../actions/spinnerActions';

export const arrangeCards = (cardArray, origin, destination, hoverId) => {
  const arrangedCards = cardArray.map((card) => {
    const clone = { ...card };

    if (clone.id === hoverId) {
      clone.display_order = destination;
      return clone;
    }

    if (origin < destination
     && clone.display_order <= destination
     && clone.display_order > origin) {
      clone.display_order -= 1;
    } else if (origin > destination
      && clone.display_order < origin
      && clone.display_order >= destination) {
      clone.display_order += 1;
    }

    return clone;
  });
  return arrangedCards;
};

export const fetchHelper = async ({
  dispatch,
  method = 'GET',
  path,
  body = {},
  options = {},
  withSpinner = false,
}) => {
  if (withSpinner) dispatch(showSpinner());
  let result;
  try {
    switch (method) {
      case 'GET':
        result = await axios.get(path, options);
        break;
      case 'POST':
        result = await axios.post(path, body, options);
        break;
      case 'PUT':
        result = await axios.put(path, body, options);
        break;
      case 'DELETE':
        result = await axios.delete(path, options);
        break;
      default:
        break;
    }
  } catch (error) {
    if (withSpinner) dispatch(hideSpinner());
    throw error;
  }
  if (withSpinner) dispatch(hideSpinner());
  return result;
};
