import React from 'react';
import PropTypes from 'prop-types';
import FileExcelOutlineIcon from 'mdi-react/FileExcelOutlineIcon';
import FileOutlineIcon from 'mdi-react/FileOutlineIcon';
import FilePdfOutlineIcon from 'mdi-react/FilePdfOutlineIcon';
import FilePowerpointOutlineIcon from 'mdi-react/FilePowerpointOutlineIcon';
import FileWordOutlineIcon from 'mdi-react/FileWordOutlineIcon';

const DocumentIcon = ({ file, className }) => {
  let type = '';

  if ('type' in file) {
    type = file.type;
  } else if ('mime_type' in file) {
    type = file.mime_type;
  }

  switch (type) {
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelOutlineIcon className={className} />;
    case 'application/pdf':
      return <FilePdfOutlineIcon className={className} />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <FilePowerpointOutlineIcon className={className} />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FileWordOutlineIcon className={className} />;
    case 'application/octet-stream':
      return <FileOutlineIcon className={className} />;
    default:
      return <FileOutlineIcon className={className} />;
  }
};

DocumentIcon.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    mime_type: PropTypes.string,
  }).isRequired,
  className: PropTypes.string.isRequired,
};

export default DocumentIcon;
