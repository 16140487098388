import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { fetchMenuItemList } from '../../../redux/actions/cmsMenuActions';
import ContentCreateCard from './components/ContentCreateCard';

const ContentCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMenuItemList('/api/menu-items'));
  });

  return (
    <Container>
      <div>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('content:create')}</h3>
          </Col>
        </Row>
        <Row>
          <ContentCreateCard />
        </Row>
      </div>
    </Container>
  );
};

export default ContentCreate;
