import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import RenderField from '../../../shared/components/form/RenderField';
import RenderTextareaField from '../../../shared/components/form/RenderTextareaField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';

const DeviceForm = (props) => {
  const {
    form,
    path,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="form__full">
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('device:name')}</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t('device:name')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('device:description')}</span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component={RenderTextareaField}
                  type="text"
                  placeholder={t('device:description')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('device:vpnIp')}</span>
              <div className="form__form-group-field">
                <Field
                  name="vpn_ip"
                  component={RenderField}
                  type="text"
                  placeholder={t('device:vpnIp')}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('device:apNumber')}</span>
              <div className="form__form-group-field">
                <Field
                  name="ap_number"
                  component={RenderField}
                  type="text"
                  placeholder={t('device:apNumber')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="form-row">
          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('device:lastSignOfLife')}</span>
              <div className="form__form-group-field">
                <Field
                  name="last_sign_of_life"
                  component={RenderField}
                  type="text"
                  placeholder={t('device:lastSignOfLife')}
                  disabled
                />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <FormButtonToolbar form={form} path={path} />
    </>
  );
};

DeviceForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

DeviceForm.defaultProps = {
  form: '',
  path: '/devices',
};

export default DeviceForm;
