import axios from 'axios';
import { setAlert } from '../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';

const fetchCancelReasons = async (config) => {
  const { dispatch, setReasons, t } = config;
  dispatch(showSpinner());
  const path = '/booking-api/cancel/cancel-reasons';
  await axios.get(path)
    .then((res) => {
      const options = res.data.reasons.map((item) => ({
        value: item.cancellationReasonCode,
        label: item.cancellationReasonText,
      }));
      setReasons(options);
    }).catch(() => {
      dispatch(setAlert(t('common:error'), 'danger'));
    }).finally(() => {
      dispatch(hideSpinner());
    });
};

export default fetchCancelReasons;
