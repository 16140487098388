import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import PageHandler from '../../shared/components/PageHandler';
import ProfileCard from './components/ProfileCard';

const ProfilePage = () => {
  const user = useSelector((state) => state.auth);
  const isAuthenticated = useAuthCheck();
  const [account, setAccount] = useState({});
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadAccount = useCallback(async () => {
    onShowSpinner();
    const email = encodeURIComponent(user.account.email.replace(/\./g, '&#46;'));
    const path = `/api/users/${email}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setLoading(false);
        setAccount(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner, user]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadAccount();
    }
  }, [isAuthenticated, loadAccount]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="media-edit">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Felhasználói Profil módosítása</h3>
            </Col>
          </Row>
          <Row>
            {!loading && statusCode === 200 && (
              <ProfileCard data={account} />
            )}
          </Row>
        </div>
      </Container>
    </PageHandler>
  );
};

export default ProfilePage;
