const editorConfig = {
  DEFAULT: {
    menubar: false,
    plugins: 'lists fullscreen hr link table',
    toolbar: 'bold italic underline strikethrough | align | fontsizeselect forecolor backcolor removeformat | indent outdent | numlist bullist hr table link | fullscreen',
    toolbar_mode: 'sliding',
  },
};

export default editorConfig;
