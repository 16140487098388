import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import AccountEditForm from './AccountEditForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    delete clone.id;
    delete clone.created_at;
    delete clone.updated_at;
    return clone;
  }
  return object;
};

const AccountEditCard = ({ data }) => {
  const optimizeData = optimize(data);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <AccountEditForm initialValues={optimizeData} onSubmit />
        </CardBody>
      </Card>
    </Col>
  );
};

AccountEditCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default AccountEditCard;
