import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import ProfileForm from './ProfileForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    if (object.company?.company_reference) {
      clone.images = [{
        ...object.company.company_reference,
        preview: object.company.company_reference.src,
      }];
    }
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.leader_of.id;
    delete clone.company.company_reference;

    return clone;
  }

  return object;
};

const ProfileCard = ({ data }) => {
  const optimizeData = optimize(data);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ProfileForm
            initialValues={optimizeData}
            isLeader={optimizeData.leader_of !== null}
            onSubmit
          />
        </CardBody>
      </Card>
    </Col>
  );
};

ProfileCard.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ProfileCard;
