import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Container, Row } from 'reactstrap';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import AppSettingsCreateCard from './AppSettingsCreate/AppSettingsCreateCard';
import AppSettingsEditCard from './AppSettingsEdit/AppSettingsEditCard';

const AppSettingsPage = () => {
  const isAuthenticated = useAuthCheck();
  const [setting, setSetting] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadSetting = useCallback(async () => {
    onShowSpinner();
    const path = '/api/app-settings/default';
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setSetting(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadSetting();
    }
  }, [isAuthenticated, loadSetting]);

  return (
    <Container>
      <div className="settings-page">
        <Row>
          {statusCode === 200 && (
            <AppSettingsEditCard data={setting} />
          )}
          {statusCode === 404 && (
            <AppSettingsCreateCard />
          )}
        </Row>
      </div>
    </Container>
  );
};

export default AppSettingsPage;
