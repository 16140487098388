import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Nav, NavItem, NavLink,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ContentLangEditForm from './ContentLangEditForm';
import ConfirmPopup from '../../../../shared/components/ConfirmPopup';
import { deleteContentLang, fetchSingleContent } from '../../../../redux/actions/cmsContentActions';
import useAvailableContentLangs from '../../../../shared/utility/useAvailableContentLangs';
import { fetchMenuItemEntry } from '../../../../redux/actions/cmsMenuActions';

const ContentLangEditContainer = ({ data, id, menuItemId }) => {
  const [activeTab, setActiveTab] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  const [deletePath, setDeletePath] = useState('');
  const availableLangs = useAvailableContentLangs(id);
  const hasAvailableLangs = availableLangs?.length > 0;

  const getActiveContentLang = () => {
    const activeContentLang = data?.length && data.find((cl) => cl.id === activeTab);
    const clone = { ...activeContentLang };

    clone.images = clone.content_lang_image_references
      && clone.content_lang_image_references.map((ref) => ({ ...ref, preview: ref.src }));
    delete clone.content_lang_image_references;

    clone.documents = clone.content_lang_document_references
      && clone.content_lang_document_references.map((ref) => ({ ...ref, preview: ref.src }));
    delete clone.content_lang_document_references;

    clone.videos = clone.content_lang_video_references
      && clone.content_lang_video_references.map((ref) => ({ ...ref, preview: ref.src }));
    delete clone.content_lang_video_references;

    return clone;
  };

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    setItemToDelete(item.title);
    setDeletePath(`/api/content-langs/${item.id}`);
    setIsModalOpen(true);
  };

  const onDeleteCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteConfirm = () => {
    dispatch(deleteContentLang(deletePath));
    dispatch(fetchSingleContent(`/api/contents/${id}`));
    if (menuItemId) {
      dispatch(fetchMenuItemEntry(`/api/menu-items/${menuItemId}`));
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!activeTab && data?.length) setActiveTab(data[0].id);
  }, [activeTab, data]);

  const contentLangLinks = data?.length > 0 && data.map((cl) => (
    <NavItem key={cl.id}>
      <NavLink onClick={() => { setActiveTab(cl.id); }} className={`content-edit-page__content-link ${activeTab === cl.id ? 'active' : null}`}>
        {cl.language.name}
        <Tooltip title={t('common:actions:delete')} placement="bottom" arrow>
          <IconButton onClick={(e) => { handleDeleteClick(e, cl); }}>
            <DeleteIcon size={20} />
          </IconButton>
        </Tooltip>
      </NavLink>
    </NavItem>
  ));

  const createNewContentLang = (
    <NavItem>
      <Tooltip title={t('common:actions:addNew')} placement="bottom" arrow>
        <NavLink tag={Link} to={`/contents/create-content-lang/${id}`} className="content-edit-page__content-link content-edit-page__content-link--create">
          <PlusCircleOutlineIcon size={20} />
        </NavLink>
      </Tooltip>
    </NavItem>
  );

  return (
    <div className="content-edit-page__edit-container">
      <ConfirmPopup
        isOpen={isModalOpen}
        item={itemToDelete}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      />
      <Nav tabs>
        {contentLangLinks}
        {hasAvailableLangs && createNewContentLang}
      </Nav>
      {data?.length > 0 && (
      <ContentLangEditForm
        enableReinitialize
        initialValues={getActiveContentLang()}
        onSubmit
      />
      )}
    </div>
  );
};

ContentLangEditContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  id: PropTypes.number,
  menuItemId: PropTypes.number,
};

ContentLangEditContainer.defaultProps = {
  menuItemId: null,
  id: null,
};

export default ContentLangEditContainer;
