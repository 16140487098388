import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Nav, NavItem, TabContent, NavLink,
} from 'reactstrap';
import MenuLangTab from './MenuLangTab';

const MenuMain = ({ data }) => {
  const { menu_langs: menuLangs = [] } = data;
  const [activeTab, setActiveTab] = useState();

  const menuLangLinks = () => menuLangs.map((menuLang) => (
    <NavItem key={menuLang.id}>
      <NavLink onClick={() => { setActiveTab(menuLang.id); }} className={activeTab === menuLang.id ? 'active' : null}>{menuLang.lang.name}</NavLink>
    </NavItem>
  ));

  const menuLangTabs = () => menuLangs.map(
    (menuLang) => <MenuLangTab key={menuLang.id} menuLang={menuLang} />,
  );

  return (
    <Col md={12}>
      <Card>
        <CardBody className="menu-page__main">
          <div className="menu-page__menu-container">
            <h4>{data.name}</h4>
          </div>
          { menuLangs.length > 0 && (
            <div className="menu-page__nav-container">
              <Nav tabs>
                {menuLangLinks()}
              </Nav>
              <TabContent activeTab={activeTab}>
                {menuLangTabs()}
              </TabContent>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

MenuMain.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    menu_langs: PropTypes.array,
  }).isRequired,
};

export default MenuMain;
